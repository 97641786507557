import React, { Component } from "react";

import { HumphreySound4 } from "../../utils/fileManager";
import QuoteVideo from "../../components/Article/QuoteVideo";

import { getDelay } from "../../utils/utils";

import Replay from "../Video/Replay";

const Timings = [
  {
    start: 0.13,
    stop: 0.23,
    delay: 0.1,
    word: "I",
  },
  {
    start: 0.33,
    stop: 0.56,
    delay: 0.1,
    word: "think",
  },
  {
    start: 0.72,
    stop: 0.88,
    delay: 0.2,
    word: "it's",
  },
  {
    start: 1.01,
    stop: 1.31,
    delay: 0.1,
    word: "almost",
  },
  {
    start: 1.48,
    stop: 2.04,
    delay: 0.2,
    word: "impossible",
  },
  {
    start: 2.1,
    stop: 2.27,
    delay: 0.1,
    word: "to",
  },
  {
    start: 2.41,
    stop: 2.82,
    delay: 0.1,
    word: "quantify",
  },
  {
    start: 3.0,
    stop: 3.16,
    delay: 0.2,
    word: "how",
  },
  {
    start: 3.3,
    stop: 3.76,
    delay: 0.1,
    word: "important",
  },
  {
    start: 3.79,
    stop: 3.87,
    delay: 0,
    word: "it",
  },
  {
    start: 3.93,
    stop: 4.31,
    delay: 0.1,
    word: "was",
  },
  {
    start: 4.39,
    stop: 4.47,
    delay: 0.1,
    word: "to",
  },
  {
    start: 4.55,
    stop: 5.01,
    delay: 0.1,
    word: "find",
  },
  {
    start: 5.02,
    stop: 5.12,
    delay: 0,
    word: "a",
  },
  {
    start: 5.19,
    stop: 5.64,
    delay: 0.1,
    word: "community",
  },
  {
    start: 5.72,
    stop: 5.9,
    delay: 0.1,
    word: "like",
  },
  {
    start: 5.98,
    stop: 6.29,
    delay: 0.1,
    word: "that,",
  },
  {
    start: 6.58,
    stop: 7.04,
    delay: 0.3,
    word: "especially",
  },
  {
    start: 7.15,
    stop: 7.28,
    delay: 0.1,
    word: "as",
  },
  {
    start: 7.3,
    stop: 7.43,
    delay: 0,
    word: "a",
  },

  {
    start: 7.9,
    stop: 8.39,
    delay: 0.5,
    word: "teenager,",
  },
  {
    start: 9.75,
    stop: 9.88,
    delay: 1.4,
    word: "we're",
  },
  {
    start: 9.97,
    stop: 10.13,
    delay: 0.1,
    word: "all",
  },
  {
    start: 10.29,
    stop: 10.63,
    delay: 0.2,
    word: "searching",
  },
  {
    start: 10.71,
    stop: 10.75,
    delay: 0.1,
    word: "for",
  },
  {
    start: 10.83,
    stop: 10.89,
    delay: 0.1,
    word: "a",
  },
  {
    start: 10.94,
    stop: 11.16,
    delay: 0,
    word: "place",
  },
  {
    start: 11.22,
    stop: 11.3,
    delay: 0.1,
    word: "we",
  },
  {
    start: 11.34,
    stop: 11.38,
    delay: 0,
    word: "can",
  },
  {
    start: 11.44,
    stop: 12.1,
    delay: 0.1,
    word: "belong.",
  },
];

let delayItems = [];
let interval = null;
let video = null;

class HumphreyAudioFour extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: 0,
      progress: 0,
      muteState: false,
    };
  }
  componentDidMount() {
    video = document.getElementById(this.props.audioId);
    this.getVideoData();
    for (var i = 1; i < Timings.length; i++) {
      delayItems.push(getDelay(Timings[i - 1], Timings[i]));
    }
    this.setState({ muteState: this.props.muteState });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.muteState !== prevState.muteState) {
      return { muteState: nextProps.muteState };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.muteState !== this.props.muteState) {
      //Perform some operation here
      this.setState({ muteState: this.props.muteState });
      video.muted = this.props.muteState;
    }
  }

  componentWillUnmount() {
    clearInterval(interval);
    video.removeEventListener("loadedmetadata", this.getDuration);
    video.pause();
  }

  updateTime = () => {
    interval = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        // const timeline = this.timeline.getGSAP();
        // console.log(timeline._timeline);
      }
    }, 50);
  };

  getVideoData = () => {
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.getDuration);
      return video.duration;
    }
    return null;
  };

  getDuration = () => {
    this.setState({
      duration: parseFloat(video.duration.toFixed(1)),
    });
    this.updateTime();
  };

  replay = () => {
    if (video) {
      video.currentTime = 0;
      if (video.paused) {
        video.play();
      }
    }
  };

  render() {
    return (
      <div className="quotes">
        <audio src={HumphreySound4()} id={this.props.audioId} controls={true} />

        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.audioId)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#B6CAE1"
          endColor="#4179BD"
        />
        <div className="replay-btn" onClick={this.replay}>
          <Replay />
          Play
        </div>
      </div>
    );
  }
}

export default HumphreyAudioFour;
