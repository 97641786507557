import React from "react";

import "./TimelineCard.scss";

const timeline = [
  {
    date: "March 2007",
    description:
      "Justin.tv was launched in March 2007 as a website that broadcast the life of one of the website’s founders, Justin Kan, around the clock",
    dataVisible: "false",
  },
  {
    date: "July 2011",
    description:
      "In July 2011 - just one month after launch, Twitch.tv has 8 million unique viewers",
    dataVisible: "false",
  },
  {
    date: "October 2012",
    description: "In October 2012, Twitch has over 20 million monthly viewers",
    dataVisible: "true",
  },
  {
    date: "August 2014",
    description:
      "In August 2014, Twitch is acquired by Amazon for $970 million. At this point, Twitch has an average of over 55 million monthly viewers",
    dataVisible: "false",
  },
  {
    date: "2015",
    description:
      "In 2015, Twitch had grown to 100 million viewers per month and over total 1.5 million total streaming accounts [16].",
    dataVisible: "false",
  },
  {
    date: "Now",
    description:
      "Now More than one million viewers are watching Twitch at any given moment and over three million unique broadcasters go live every month. To put this in context, the biggest TV shows in the world attract around 18 million viewers.",
    dataVisible: "false",
  },
];

class TimelineCard extends React.Component {
  componentDidMount() {
    var curDown = false,
      curXPos = 0,
      timelineCircles = document.querySelectorAll(".timeline-circle"),
      posCirXMouDonw = ["1226", "1526", "1826", "2126", "2426", "2726", "3026"],
      pointerDelta = 0,
      factor = 1.002;

    var timer;

    var timlineItems = document.querySelectorAll(".timeline-item");

    document
      .querySelector(".timeline-svg")
      .addEventListener("mousemove", (m) => {
        if (curDown) {
          var pX = m.pageX;
          pointerDelta = curXPos - pX;

          clearTimeout(timer);

          timer = setTimeout(() => {
            curXPos = m.pageX;
          }, 50);

          // Function if drag right
          if (pointerDelta < 0) {
            window.requestAnimationFrame(function () {
              for (let index = 0; index < timelineCircles.length; index++) {
                if (posCirXMouDonw[0] >= 2126) {
                  timelineCircles[0].setAttribute(
                    "transform",
                    "matrix(1, 0, 0, 1," + 2126 + ", 43.005223)"
                  );
                  break;
                } else if (posCirXMouDonw[0] < 2126) {
                  posCirXMouDonw[index] =
                    parseFloat(posCirXMouDonw[index]) * factor;
                  timelineCircles[index].setAttribute(
                    "transform",
                    "matrix(1, 0, 0, 1," +
                      posCirXMouDonw[index] +
                      ", 43.005223)"
                  );
                }
              }
            });
          } else {
            for (let index = 0; index < timelineCircles.length; index++) {
              if (posCirXMouDonw[timelineCircles.length - 1] < 2126) {
                window.requestAnimationFrame(function () {
                  timelineCircles[timelineCircles.length - 1].setAttribute(
                    "transform",
                    "matrix(1, 0, 0, 1," + 2126 + ", 43.005223)"
                  );
                });
                return;
              } else {
                posCirXMouDonw[index] =
                  parseFloat(posCirXMouDonw[index]) / factor;
                window.requestAnimationFrame(function () {
                  timelineCircles[index].setAttribute(
                    "transform",
                    "matrix(1, 0, 0, 1," +
                      posCirXMouDonw[index] +
                      ", 43.005223)"
                  );
                });
              }
            }
          }
        }
      });

    document
      .querySelector(".timeline-svg")
      .addEventListener("mousedown", (m) => {
        curDown = true;
        curXPos = m.pageX;
        document
          .querySelector(".wrapper-timeline-svg")
          .classList.remove("hide-cir");
        const circles = document.querySelectorAll(".timeline-circle");

        circles.forEach(function (circle) {
          circle.classList.remove("hide");
        });
        for (let index = 0; index < timelineCircles.length; index++) {
          timelineCircles[index].setAttribute(
            "transform",
            "matrix(1, 0, 0, 1," + posCirXMouDonw[index] + ", 43.005223)"
          );
          timlineItems[index].setAttribute("data-visible", false);
        }
        if (posCirXMouDonw[0] >= 2126 && posCirXMouDonw[1] <= 2437) {
          let arrayTest = ["2126", "2426", "2726", "3026", "3326", "3626"];
          for (let index = 0; index < timelineCircles.length; index++) {
            posCirXMouDonw[index] = arrayTest[index];
            window.requestAnimationFrame(function () {
              timelineCircles[index].setAttribute(
                "transform",
                "matrix(1, 0, 0, 1," + posCirXMouDonw[index] + ", 43.005223)"
              );
            });
          }
        }
      });

    window.addEventListener("mouseup", () => {
      if (curDown) {
        // document.querySelector('.wrapper-timeline-svg').classList.add('hide-cir');
        curDown = false;
        let snap = 4252 / 2;
        let arrayPos = [];
        for (let index = 0; index < timlineItems.length; index++) {
          arrayPos.push(Math.abs(snap - parseFloat(posCirXMouDonw[index])));
          if (snap - parseFloat(posCirXMouDonw[index]) < -100) {
            window.requestAnimationFrame(function () {
              timelineCircles[index].setAttribute(
                "transform",
                "matrix(1, 0, 0, 1," +
                  parseFloat(posCirXMouDonw[index]) / 0.9 +
                  ", 43.005223)"
              );
            });
          } else if (snap - parseFloat(posCirXMouDonw[index]) > 100) {
            window.requestAnimationFrame(function () {
              timelineCircles[index].setAttribute(
                "transform",
                "matrix(1, 0, 0, 1," +
                  parseFloat(posCirXMouDonw[index]) * 0.9 +
                  ", 43.005223)"
              );
            });
          }
        }
        let minInNumbers = Math.min.apply(Math, arrayPos);
        let flag = false;
        for (let i = 0; i < arrayPos.length; i++) {
          if (minInNumbers === arrayPos[i] && flag === false) {
            window.requestAnimationFrame(function () {
              timelineCircles[i].setAttribute(
                "transform",
                "matrix(1, 0, 0, 1," + snap + ", 43.005223)"
              );
              timelineCircles[i].classList.add("hide");
            });
            timlineItems[i].setAttribute("data-visible", true);
            flag = true;
          }
        }
      }
    });
  }
  render() {
    return timeline.map((item, index) => {
      return (
        <div
          className="timeline-item timeline-card"
          key={index}
          data-visible={item.dataVisible}
        >
          <div className="timeline-card__content">
            <h4 className="timeline-card__date">{item.date}</h4>
            <div className="timeline-card__description">{item.description}</div>
          </div>
        </div>
      );
    });
  }
}

export default TimelineCard;
