import React, { Component } from "react";

import ImageScroll from "./ImageScroll";

import "./ImageLayout.scss";

class ImageFull extends Component {
  render() {
    return (
      <div className="image-full">
        <ImageScroll
          size={this.props.size}
          src={this.props.src}
          imgId={this.props.imgId}
        />
      </div>
    );
  }
}

export default ImageFull;
