import React, { Component } from "react";
import ReactGA from "react-ga";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import Facebook from "../App/Facebook.jsx";
import Twitter from "../App/Twitter.jsx";

// import videoSrc from "../../assets/GameFace/part_1_anim_v1.mp4";
import { gameFaceMain } from "../../utils/fileManager";
import { screenSize } from "../../utils/utils";
import {
  generalAssets,
  leonAssets,
  ducksAssets,
  makeupAssets,
} from "../../utils/gameFaceInteractions";
import LeonGame from "./LeonGame";
import DuckGame from "./DuckGame";
import MakeupGame from "./MakeupGame";

import PlayPause from "../Video/PlayPause";
import Mute from "../Video/Mute";

import silence from "../../assets/1-second-of-silence.mp3";

import "./DayInTheLife.scss";

const PIXI = window.PIXI;
const Spine = PIXI.spine.Spine;
const Application = PIXI.Application;

var video = null;
let nav = null;

let app,
  positionX,
  positionY,
  windowHeight,
  canvasHeight,
  getCurrentTime,
  videoWidth,
  videoHeight,
  scale;

const startLeon = 41.6;
const endPartOne = 44.1;
const startDucks = 95.1;
const endPartTwo = 99.5;
const startMakeup = 148.1;
const endPartThree = 151.1;
const startPartFour = 151.1;

const leonAudio = {};
const duckAudio = {};
const makeupAudio = {};

class DayInTheLife extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: 0,
      videoControls: true,
      playState: false,
      muteState: false,
      playPrompt: false,
      videoWidth: null,
      showEnd: false,

      baby: null,
      spoon: null,
      texture: null,
      bg: null,
      controller: null,
      leonFinished: null,

      forestBack: null,
      forestFront: null,
      ducksTexture: null,
      ducksBg: null,
      hotspot1: "idle",
      hotspot2: "idle",
      hotspot3: "idle",
      hotspot4: "idle",
      hotspot5: "idle",
      ducksController: null,
      ducksTransition: null,
      animalVisible: null,
      duckVisible: null,
      duckCount: 0,
      randomHotspot: null,
      ducksFinished: null,

      makeupBg: null,
      lipstick: null,
      buttonActive: false,
      makeupFinished: null,
    };

    // Audio refs
    this.cry1 = null;
    this.babyAppear = null;
  }

  componentDidMount() {
    // this.props.updateNav("gameFaceGame");
    ReactGA.pageview(window.location.pathname + window.location.search);

    windowHeight = window.innerHeight;

    video = document.getElementById("video");
    video.addEventListener("loadeddata", this.videoLoader(), false);

    nav = document.getElementById("nav-bar");
    if (nav) {
      nav.classList.add("light");
      nav.classList.add("gf-game");
    }

    leonAudio.cry1 = this.cry1;
    leonAudio.cry2 = new Audio(silence);
    leonAudio.loopBed = new Audio(silence);
    leonAudio.babyAppear = this.babyAppear;
    leonAudio.coo1 = new Audio(silence);
    leonAudio.coo2 = new Audio(silence);
    leonAudio.mouthSound = new Audio(silence);
    leonAudio.spoonAppear = new Audio(silence);
    leonAudio.leonResultPositive = new Audio(silence);
    leonAudio.leonResultNegative = new Audio(silence);

    duckAudio.buttonPress = new Audio(silence);
    duckAudio.controllerEnter = new Audio(silence);
    duckAudio.UI1 = new Audio(silence);
    duckAudio.UI2 = new Audio(silence);
    duckAudio.UI3 = new Audio(silence);
    duckAudio.inBush = new Audio(silence);
    duckAudio.inPond = new Audio(silence);
    duckAudio.crocGrowl = new Audio(silence);
    duckAudio.ducksTrackLoop = new Audio(silence);
    duckAudio.duckQuack = new Audio(silence);
    duckAudio.leaveHeads = new Audio(silence);
    duckAudio.showHeads = new Audio(silence);
    duckAudio.frogRibbet = new Audio(silence);
    duckAudio.ducksResultNegative = new Audio(silence);
    duckAudio.ducksResultPositive = new Audio(silence);

    makeupAudio.buttonPress = new Audio(silence);
    makeupAudio.controllerEnter = new Audio(silence);
    makeupAudio.winSound = new Audio(silence);
    makeupAudio.uhOh1 = new Audio(silence);
    makeupAudio.uhOh2 = new Audio(silence);
    makeupAudio.onSwish = new Audio(silence);
    makeupAudio.cuteLaugh1 = new Audio(silence);
    makeupAudio.cuteLaugh2 = new Audio(silence);
    makeupAudio.makeupTrackLoop = new Audio(silence);
    makeupAudio.makeupResultNegative = new Audio(silence);
  }

  componentWillUnmount() {
    clearInterval(getCurrentTime);
    video.removeEventListener("loadeddata", this.videoLoader(), false);

    if (nav) {
      // nav.classList.remove("light");
      nav.classList.remove("gf-game");
    }
  }

  resize = (renderer) => {
    windowHeight = window.innerHeight;
    canvasHeight = windowHeight;
    console.log(
      videoWidth,
      video.offsetWidth,
      video.getBoundingClientRect().width
    );

    if (screenSize().width < 800) {
      videoWidth = screenSize().width;
      videoHeight = videoWidth * 0.75;
      scale = videoWidth / (1440 * 2);
      this.setState({ videoWidth, videoHeight });
      // canvasHeight = windowHeight * 0.8;
    } else {
      videoHeight = video.offsetHeight;
      videoWidth = videoHeight * 1.3333333;
      // scale = videoHeight / 2160;
      scale = videoHeight / (1080 * 2);
      this.setState({ videoWidth, videoHeight });
    }

    renderer.resize(videoWidth, canvasHeight);
  };

  videoLoader = () => {
    // video = document.getElementById("video");
    video.classList.add("loaded");
    this.controlPlayState();

    const that = this;
    video.onended = function (e) {
      console.log("ended", e);
      that.setState({
        showEnd: true,
      });
    };

    const canvasWrap = document.getElementById("canvas-wrap");
    canvasWrap.classList.add("disabled");

    app = new Application({
      width: videoWidth,
      height: canvasHeight,
      transparent: true,
    });

    this.resize(app.renderer);
    window.addEventListener("resize", () => this.resize(app.renderer));

    console.log(screenSize().width);
    if (screenSize().width < 800) {
      this.resize(app.renderer);
    }

    var leonScene = new PIXI.Container(),
      ducksScene = new PIXI.Container(),
      makeupScene = new PIXI.Container();

    // Hide scenes initially
    ducksScene.visible = false;
    makeupScene.visible = false;
    leonScene.visible = false;

    canvasWrap.appendChild(app.view);
    app.loader
      .add(generalAssets)
      .add(leonAssets)
      .add(ducksAssets)
      .add(makeupAssets)
      // Leon Game:
      .load((loader, resources) => {
        LeonGame(
          this,
          app,
          Spine,
          leonScene,
          { resources },
          videoWidth,
          videoHeight,
          scale,
          video,
          getCurrentTime,
          canvasWrap,
          startLeon,
          endPartOne,
          positionX,
          positionY,
          screenSize().width,
          leonAudio
        );

        app.start();
      })
      // Ducks Game:
      .load((loader, resources) => {
        DuckGame(
          this,
          app,
          Spine,
          ducksScene,
          { resources },
          videoWidth,
          videoHeight,
          scale,
          video,
          getCurrentTime,
          canvasWrap,
          startDucks,
          endPartTwo,
          positionX,
          positionY,
          screenSize().width,
          duckAudio
        );
        // <DuckGame
        //   parent={this}
        //   app={app}
        //   Spine={Spine}
        //   ducksScene={ducksScene}
        //   resources={resources}
        //   videoWidth={videoWidth}
        //   videoHeight={videoHeight}
        //   scale={scale}
        //   video={video}
        //   getCurrentTime={getCurrentTime}
        //   canvasWrap={canvasWrap}
        //   startGame={startDucks}
        //   videoPartEnd={endPartTwo}
        //   positionX={positionX}
        //   positionY={positionY}
        //   screenWidth={screenSize().width}
        //   muteState={this.state.muteState}
        // />
        app.start();
      })
      // Makeup Game:
      .load((loader, resources) => {
        MakeupGame(
          this,
          app,
          Spine,
          makeupScene,
          { resources },
          videoWidth,
          videoHeight,
          scale,
          video,
          getCurrentTime,
          canvasWrap,
          startMakeup,
          endPartThree,
          startPartFour,
          positionX,
          positionY,
          screenSize().width,
          makeupAudio
        );
        app.start();
      });

    app.loader.onProgress.add((loader, resource) => {
      // can add a progress bar with this if required.
      // console.log("one asset loaded", loader.progress, resource.name);
    });
  };

  playEmptyAudio = () => {
    Object.keys(leonAudio).map((key, i) => {
      this.controlAudioPlay(leonAudio[key]);
    });
    Object.keys(duckAudio).map((key, i) => {
      this.controlAudioPlay(duckAudio[key]);
    });
    Object.keys(makeupAudio).map((key, i) => {
      this.controlAudioPlay(makeupAudio[key]);
    });
  };

  controlAudioPlay = (audio) => {
    audio.volume = 0;
    if (audio.paused) {
      var promise = audio.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            console.log("autoplay", audio.muted);
          })
          .catch((error) => {
            // Show a button on the video saying pres play to start
            console.log("can't play audio");
          });
      }
    }
    audio.pause();
    audio.volume = 1;
  };

  controlPlayState = () => {
    if (video.paused) {
      var promise = video.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            this.setState({ playState: true, playPrompt: false });
            console.log("autoplay", video.muted);
            this.playEmptyAudio();
          })
          .catch((error) => {
            // Show a button on the video saying pres play to start
            this.setState({ playPrompt: true });
          });
      }
    } else {
      video.pause();
      this.setState({ playState: false });
    }
  };

  startVideo = () => {
    this.setState({ playPrompt: false });
    this.controlPlayState();
  };

  controlMuteState = () => {
    if (video.muted) {
      video.muted = false;
      this.setState({ muteState: false });
    } else {
      video.muted = true;
      this.setState({ muteState: true });
    }
  };

  replay = () => {
    video.currentTime = 0;
    if (video.paused) {
      video.play();
    }
  };

  gameLinkOne = () => {
    video.currentTime = endPartOne - 3;
  };

  gameLinkTwo = () => {
    video.currentTime = endPartTwo - 10;
  };

  gameLinkThree = () => {
    video.currentTime = endPartThree - 5;
  };

  render() {
    return (
      <div className="day-in-the-life">
        <div id="audio-wrapper">
          <audio ref={(ref) => (this.cry1 = ref)} src={silence} />
          <audio ref={(ref) => (this.babyAppear = ref)} src={silence} />
        </div>
        <a href="/gameface#the-article">
          <div className="menu-close">Close</div>
        </a>
        <div className="video-wrap">
          <div
            className={`prompt-wrap${
              this.state.playPrompt ? " show" : " hide"
            }`}
            onClick={this.startVideo}
          >
            <div className="play-prompt">Start</div>
          </div>
          <video
            preload="auto"
            id="video"
            // autoPlay={true}
            // controls={true}
            onClick={this.controlPlayState}
            playsInline
            src={gameFaceMain()}
          />
        </div>
        <div className="controls-wrap" style={{ width: this.state.videoWidth }}>
          <div className={`controls ${this.state.videoControls}`}>
            <div className="control play-pause" onClick={this.controlPlayState}>
              <PlayPause
                mode={this.state.playState === true ? "pause" : "play"}
              />
            </div>
            <div className="control mute" onClick={this.controlMuteState}>
              <Mute mode={this.state.muteState ? "mute-off" : "mute-on"} />
            </div>
            {/* <div className="control replay" onClick={this.replay}>
              Replay
            </div> */}
          </div>
          {/* <div className={`game-links-wrap ${this.state.videoControls}`}>
            <div className="label">Skip to Game:</div>
            <div className="game-links">
              <div className="leon" onClick={this.gameLinkOne}>
                One
              </div>
              <div className="ducks" onClick={this.gameLinkTwo}>
                Two
              </div>
              <div className="makeup" onClick={this.gameLinkThree}>
                Three
              </div>
            </div>
          </div> */}
        </div>
        <div id="canvas-wrap" />
        <div
          id="endplate"
          className={this.state.showEnd ? "show" : ""}
          style={{ height: this.state.videoHeight }}
        >
          <h2>Share:</h2>
          <div className="share-widgets">
            <FacebookShareButton
              url={`https://gamechange.rnz.co.nz`}
              quote={`Game Change: Play three interactive stories exploring NZ's gaming industry`}
            >
              <Facebook />
            </FacebookShareButton>
            <TwitterShareButton
              url={`https://gamechange.rnz.co.nz/`}
              title={`Game Change: Play three interactive stories exploring NZ's gaming industry`}
            >
              <Twitter />
            </TwitterShareButton>
          </div>
          <div className="continue-prompt">
            <a href="/gameface#the-article">Continue to the article</a>
          </div>
        </div>
      </div>
    );
  }
}

export default DayInTheLife;
