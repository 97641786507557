import React, { Component } from "react";

import rnzLogo from "../../assets/rnz_logo.jpg";

class RNZLogo extends Component {
  render() {
    return (
      <div className="rnz-logo">
        <img src={rnzLogo} alt="" />
      </div>
    );
  }
}

export default RNZLogo;
