import React, { Component } from "react";

import "./VideoControls.scss";

class PlayPause extends Component {
  render() {
    return (
      <React.Fragment>
        <svg
          width="19px"
          height="21px"
          viewBox="0 0 19 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={`play-pause ${this.props.mode}`}
        >
          <g
            id="exports"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="play-wrap"
              transform="translate(-70.000000, -715.000000)"
              fill="#FFFFFF"
            >
              <polygon id="play" points="85 725.5 70 736 70 715"></polygon>
            </g>
            <g
              id="pause-wrap"
              transform="translate(-68.000000, -668.000000)"
              fill="#FFFFFF"
            >
              <g id="pause" transform="translate(68.000000, 668.000000)">
                <rect
                  id="Rectangle-16"
                  x="0"
                  y="0"
                  width="7"
                  height="21"
                ></rect>
                <rect
                  id="Rectangle-16-Copy"
                  x="12"
                  y="0"
                  width="7"
                  height="21"
                ></rect>
              </g>
            </g>
          </g>
        </svg>
      </React.Fragment>
    );
  }
}

export default PlayPause;
