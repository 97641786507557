const DuckGame = (
  parent,
  app,
  Spine,
  ducksScene,
  resources,
  videoWidth,
  videoHeight,
  scale,
  video,
  getCurrentTime,
  canvasWrap,
  startGame,
  videoPartEnd,
  positionX,
  positionY,
  screenWidth,
  audio
) => {
  resources = resources.resources;

  const bg = new Spine(resources.bgDuck.spineData);
  const forestBack = new Spine(resources.forestBack.spineData);
  const forestFront = new Spine(resources.forestFront.spineData);
  const hotspot1 = new Spine(resources.hotspot1.spineData);
  const hotspot2 = new Spine(resources.hotspot2.spineData);
  const hotspot3 = new Spine(resources.hotspot3.spineData);
  const hotspot4 = new Spine(resources.hotspot4.spineData);
  const hotspot5 = new Spine(resources.hotspot5.spineData);
  const texture = new Spine(resources.textureDuck.spineData);
  const controller = new Spine(resources.controllerDuck.spineData);
  const transition = new Spine(resources.transitionDuck.spineData);
  const duckCount1 = new Spine(resources.duckCount1.spineData);
  const duckCount2 = new Spine(resources.duckCount2.spineData);
  const duckCount3 = new Spine(resources.duckCount3.spineData);

  // const gameLoadInOut = new Audio(resources.gameLoadInOut.url);
  // const gameLoadIn = new Audio(resources.gameLoadIn.url);
  audio.buttonPress.src = resources.buttonPress.url;
  audio.controllerEnter.src = resources.controllerEnter.url;
  audio.UI1.src = resources.UI1.url;
  audio.UI2.src = resources.UI2.url;
  audio.UI3.src = resources.UI3.url;
  audio.inBush.src = resources.inBush.url;
  audio.inPond.src = resources.inPond.url;
  audio.crocGrowl.src = resources.crocGrowl.url;
  audio.ducksTrackLoop.src = resources.ducksTrackLoop.url;
  audio.duckQuack.src = resources.duckQuack.url;
  audio.leaveHeads.src = resources.leaveHeads.url;
  audio.showHeads.src = resources.showHeads.url;
  audio.frogRibbet.src = resources.frogRibbet.url;
  audio.ducksResultNegative.src = resources.ducksResultNegative.url;
  audio.ducksResultPositive.src = resources.ducksResultPositive.url;

  let audioList = new Array();
  audioList.push(audio);

  console.log(parent.state.muteState);
  if (parent.state.muteState === true) {
    console.log("muted");
    audioList.map((audio) => {
      audio.muted = true;
      audio.pause();
    });
  }

  // Position
  bg.position.set(videoWidth / 2, videoHeight / 2);
  bg.scale.set(scale, scale);
  forestBack.position.set(videoWidth / 2, videoHeight / 2);
  forestBack.scale.set(scale, scale);
  forestFront.position.set(videoWidth / 2, videoHeight / 2);
  forestFront.scale.set(scale, scale);
  hotspot1.position.set(videoWidth / 2, videoHeight / 2);
  hotspot1.scale.set(scale, scale);
  hotspot2.position.set(videoWidth / 2, videoHeight / 2);
  hotspot2.scale.set(scale, scale);
  hotspot3.position.set(videoWidth / 2, videoHeight / 2);
  hotspot3.scale.set(scale, scale);
  hotspot4.position.set(videoWidth / 2, videoHeight / 2);
  hotspot4.scale.set(scale, scale);
  hotspot5.position.set(videoWidth / 2, videoHeight / 2);
  hotspot5.scale.set(scale, scale);
  texture.position.set(videoWidth / 2, videoHeight / 2);
  texture.scale.set(scale, scale);
  controller.position.set(videoWidth / 2, videoHeight / 2);
  controller.scale.set(scale, scale);
  transition.position.set(videoWidth / 2, videoHeight / 2);
  transition.scale.set(scale, scale);
  duckCount1.position.set(videoWidth / 2, videoHeight / 2);
  duckCount1.scale.set(scale, scale);
  duckCount2.position.set(videoWidth / 2, videoHeight / 2);
  duckCount2.scale.set(scale, scale);
  duckCount3.position.set(videoWidth / 2, videoHeight / 2);
  duckCount3.scale.set(scale, scale);
  if (screenWidth < 800) {
    controller.scale.set(scale * 3.8, scale * 3.8);
    controller.position.set(videoWidth / 2, -Math.abs(videoHeight / 1.15));
  }

  // Add elements to the Scene
  ducksScene.addChild(bg);
  ducksScene.addChild(forestBack);
  ducksScene.addChild(forestFront);
  ducksScene.addChild(hotspot1);
  ducksScene.addChild(hotspot2);
  ducksScene.addChild(hotspot3);
  ducksScene.addChild(hotspot4);
  ducksScene.addChild(hotspot5);
  ducksScene.addChild(texture);
  ducksScene.addChild(controller);
  ducksScene.addChild(transition);
  ducksScene.addChild(duckCount1);
  ducksScene.addChild(duckCount2);
  ducksScene.addChild(duckCount3);

  // Add the scene to the stage
  app.stage.addChild(ducksScene);

  // Reposition and scale on resize
  window.addEventListener("resize", function () {
    videoHeight = video.offsetHeight;
    videoWidth = videoHeight * 1.3333333;
    positionX = videoWidth / 2;
    positionY = videoHeight / 2;
    scale = videoHeight / 2160;

    bg.position.set(positionX, positionY);
    bg.scale.set(scale, scale);
    forestBack.position.set(positionX, positionY);
    forestBack.scale.set(scale, scale);
    forestFront.position.set(positionX, positionY);
    forestFront.scale.set(scale, scale);
    hotspot1.position.set(positionX, positionY);
    hotspot1.scale.set(scale, scale);
    hotspot2.position.set(positionX, positionY);
    hotspot2.scale.set(scale, scale);
    hotspot3.position.set(positionX, positionY);
    hotspot3.scale.set(scale, scale);
    hotspot4.position.set(positionX, positionY);
    hotspot4.scale.set(scale, scale);
    hotspot5.position.set(positionX, positionY);
    hotspot5.scale.set(scale, scale);
    texture.position.set(positionX, positionY);
    texture.scale.set(scale, scale);
    controller.position.set(positionX, positionY);
    controller.scale.set(scale, scale);
    transition.position.set(positionX, positionY);
    transition.scale.set(scale, scale);
    duckCount1.position.set(positionX, positionY);
    duckCount1.scale.set(scale, scale);
    duckCount2.position.set(positionX, positionY);
    duckCount2.scale.set(scale, scale);
    duckCount3.position.set(positionX, positionY);
    duckCount3.scale.set(scale, scale);
    if (screenWidth < 800) {
      controller.scale.set(scale * 3.8, scale * 3.8);
      controller.position.set(videoWidth / 2, -Math.abs(videoHeight / 1.15));
    }
  });

  getCurrentTime = setInterval(() => {
    if (parent.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
      parent.setState({
        currentTime: parseFloat(video.currentTime.toFixed(1)),
        duration: parseFloat(video.duration.toFixed(1)),
      });
    }
    if (parent.state.currentTime === startGame) {
      parent.setState({ videoControls: false });

      // Start soundtrack loop
      audio.ducksTrackLoop.addEventListener(
        "ended",
        function () {
          this.currentTime = 0;
          this.play();
        },
        false
      );
      if (parent.state.ducksFinished !== true) audio.ducksTrackLoop.play();

      // Show Scene
      ducksScene.visible = true;
      // Set inital animations
      bg.state.setAnimation(0, "appear", false);
      forestBack.state.setAnimation(0, "appear", false);
      forestFront.state.setAnimation(0, "appear", false);
      hotspot1.state.setAnimation(0, "appear", false);
      hotspot2.state.setAnimation(0, "appear", false);
      hotspot3.state.setAnimation(0, "appear", false);
      hotspot4.state.setAnimation(0, "appear", false);
      hotspot5.state.setAnimation(0, "appear", false);
      texture.state.setAnimation(0, "appear", false);
      controller.state.setAnimation(0, "appear", false);
      duckCount1.state.setAnimation(0, "appear", false);
      duckCount2.state.setAnimation(0, "appear", false);
      duckCount3.state.setAnimation(0, "appear", false);

      canvasWrap.classList.remove("disabled");
    } else if (parent.state.currentTime === videoPartEnd) {
      video.pause();

      clearInterval(getCurrentTime);
    }
  }, 50);

  const onButtonDown = () => {
    console.log("clicked");
    controller.state.setAnimation(0, "tap", false);
    let hotspot = null;
    if (parent.state.randomHotspot === "hotspot1") {
      hotspot = hotspot1;
    } else if (parent.state.randomHotspot === "hotspot2") {
      hotspot = hotspot2;
    } else if (parent.state.randomHotspot === "hotspot3") {
      hotspot = hotspot3;
    } else if (parent.state.randomHotspot === "hotspot4") {
      hotspot = hotspot4;
    } else if (parent.state.randomHotspot === "hotspot5") {
      hotspot = hotspot5;
    }

    let duckCount = null;

    if (parent.state.duckVisible) {
      hotspot.state.setAnimation(0, "duck_success", true);
      if (parent.state.duckCount < 3) {
        switch (parent.state.duckCount) {
          case 0:
            duckCount = duckCount1;
            break;
          case 1:
            duckCount = duckCount2;
            break;
          case 2:
            duckCount = duckCount3;
            break;
          default:
            duckCount = duckCount1;
            break;
        }
        duckCount.state.setAnimation(0, "success", false);
        parent.setState({
          duckCount: parent.state.duckCount + 1,
        });
        if (parent.state.duckCount === 3) {
          // Now call the exit animations
          bg.state.setAnimation(0, "successAndExit", false);
          texture.state.setAnimation(0, "successAndExit", false);
          controller.state.setAnimation(0, "successAndExit", false);
          transition.state.setAnimation(0, "successAndExit", false);
          duckCount1.state.setAnimation(0, "successAndExit", false);
          duckCount2.state.setAnimation(0, "successAndExit", false);
          duckCount3.state.setAnimation(0, "successAndExit", false);
        }
      }
    } else {
      if (parent.state.duckCount > 0) {
        switch (parent.state.duckCount) {
          case 1:
            duckCount = duckCount1;
            break;
          case 2:
            duckCount = duckCount2;
            break;
          case 3:
            duckCount = duckCount3;
            break;
          default:
            duckCount = duckCount1;
            break;
        }
        if (parent.state.animalVisible === "frog") {
          hotspot.state.setAnimation(0, "frog_fail", true);
          duckCount.state.setAnimation(0, "fail", false);
          parent.setState({
            duckCount: parent.state.duckCount - 1,
          });
        } else if (parent.state.animalVisible === "croc") {
          hotspot.state.setAnimation(0, "croc_fail", true);
          duckCount.state.setAnimation(0, "fail", false);
          parent.setState({
            duckCount: parent.state.duckCount - 1,
          });
        }
      }
    }
  };

  controller.interactive = true;
  controller.on("mousedown", onButtonDown).on("touchstart", onButtonDown);

  const that = parent;

  const randomTrack = () => {
    const track = Math.floor(Math.random() * Math.floor(15));
    if (track > 0) {
      // console.log("Is duck visible? ", that.state.duckVisible);
      if (that.state.duckVisible === true) {
        // If a duck is present, return only croc or frog track.
        // console.log("Random track is: ", track);
        if (track > 4) {
          // console.log("A Duck present, so a croc/frog will be shown");
          // Ducks are on tracks 1, 2, 3 and 4
          return track;
        }
      } else {
        // If no duck is present - return random track, including duck tracks.
        return track;
      }
    }
  };

  const randomProperty = (obj) => {
    var keys = Object.keys(obj);
    return keys[Math.floor(Math.random() * keys.length)];
  };

  const findAvailableHotspot = () => {
    const hotspots = {
      hotspot1: parent.state.hotspot1,
      hotspot2: parent.state.hotspot2,
      hotspot3: parent.state.hotspot3,
      hotspot4: parent.state.hotspot4,
      hotspot5: parent.state.hotspot5,
    };
    for (var key in hotspots) {
      var value = hotspots[key];
      if (value === "busy") {
        delete hotspots[key];
      }
    }
    return randomProperty(hotspots);
  };

  const randomHotspot = () => {
    const number = Math.floor(Math.random() * 4);
    let hotspot = null;
    let hotspotState = null;
    let forcedHotspot = null;

    // Check that the hotspot is "available"

    if (number === 0) {
      if (parent.state.hotspot1 === "busy") {
        forcedHotspot = findAvailableHotspot();
      } else {
        hotspot = hotspot1;
        hotspotState = "hotspot1";
      }
    } else if (number === 1) {
      if (parent.state.hotspot2 === "busy") {
        forcedHotspot = findAvailableHotspot();
      } else {
        hotspot = hotspot2;
        hotspotState = "hotspot2";
      }
    } else if (number === 2) {
      if (parent.state.hotspot3 === "busy") {
        forcedHotspot = findAvailableHotspot();
      } else {
        hotspot = hotspot3;
        hotspotState = "hotspot3";
      }
    } else if (number === 3) {
      hotspot = hotspot4;
      hotspotState = "hotspot4";
      if (parent.state.hotspot4 === "busy") {
        forcedHotspot = findAvailableHotspot();
      } else {
        hotspot = hotspot4;
        hotspotState = "hotspot4";
      }
    } else if (number === 4) {
      if (parent.state.hotspot5 === "busy") {
        forcedHotspot = findAvailableHotspot();
      } else {
        hotspot = hotspot5;
        hotspotState = "hotspot5";
      }
    }
    console.log(forcedHotspot);
    if (forcedHotspot === "hotspot1") {
      hotspot = hotspot1;
      hotspotState = "hotspot1";
    } else if (forcedHotspot === "hotspot2") {
      hotspot = hotspot2;
      hotspotState = "hotspot2";
    } else if (forcedHotspot === "hotspot3") {
      hotspot = hotspot3;
      hotspotState = "hotspot3";
    } else if (forcedHotspot === "hotspot4") {
      hotspot = hotspot4;
      hotspotState = "hotspot4";
    } else if (forcedHotspot === "hotspot5") {
      hotspot = hotspot5;
      hotspotState = "hotspot5";
    }
    parent.setState({
      randomHotspot: hotspotState,
    });
    return hotspot;
  };

  const animalLoop = setInterval(() => {
    if (parent.state.ducksController === "idle") {
      const hotspot = randomHotspot();
      const track = randomTrack();
      if (track <= 10) {
        // If hotspot is free
        hotspot.state.setAnimation(0, track, false);
      }
    }
  }, 500);

  const animalLogic = (animal) => {
    console.log("animalLogic", that.state.duckVisible);
    that.setState({ animalVisible: animal });
    if (animal === "duck") {
      that.setState({ duckVisible: true });
      audio.duckQuack.play();
    } else if (animal === "frog") {
      audio.frogRibbet.play();
    } else if (animal === "croc") {
      audio.crocGrowl.play();
    }
  };

  const noDuck = () => {
    that.setState({ duckVisible: false });
  };

  // To get details of the event
  //console.log("SKELETON", event, entry, event.data.name);

  bg.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        bg.state.setAnimation(0, "idle", true);
        that.setState({ ducksBg: "idle" });
      }
    },
  });
  forestBack.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        forestBack.state.setAnimation(0, "idle", true);
        that.setState({ forestBack: "idle" });
      } else if (event.data.name === "AppearInBush") {
        audio.inBush.play();
      } else if (event.data.name === "AppearInPond") {
        audio.inPond.play();
      }
    },
  });
  forestFront.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        forestFront.state.setAnimation(0, "idle", true);
        that.setState({ forestFront: "idle" });
      }
    },
  });

  hotspot1.state.addListener({
    event: function (entry, event) {
      console.log("hs1", event, entry, event.data.name);
      if (event.data.name === "playIdle") {
        hotspot1.state.setAnimation(0, "idle", true);
        that.setState({ hotspot1: "idle" });
      }
      if (event.data.name === "pauseGame") {
        // hotspot1.state.setAnimation(0, "idle", true);
        // that.setState({ hotspot1: "idle" });
      } else if (event.data.name === "animalVisible") {
        animalLogic(event.stringValue);
      } else if (event.data.name === "duckExit") {
        noDuck();
      } else if (event.data.name === "DucksShowHeadsAtStart") {
        audio.showHeads.play();
      } else if (event.data.name === "DucksHeadsLeaveAtStart") {
        audio.leaveHeads.play();
      } else if (event.data.name === "busy") {
        const busyValue = event.stringValue === "true" ? "busy" : "available";
        that.setState({ hotspot1: busyValue });
      }
    },
  });
  hotspot2.state.addListener({
    event: function (entry, event) {
      console.log("hs2", event, entry, event.data.name);
      if (event.data.name === "playIdle") {
        hotspot2.state.setAnimation(0, "idle", true);
        that.setState({ hotspot2: "idle" });
      } else if (event.data.name === "animalVisible") {
        animalLogic(event.stringValue);
      } else if (event.data.name === "duckExit") {
        noDuck();
      } else if (event.data.name === "busy") {
        const busyValue = event.stringValue === "true" ? "busy" : "available";
        that.setState({ hotspot2: busyValue });
      }
    },
  });
  hotspot3.state.addListener({
    event: function (entry, event) {
      console.log("hs3", event, entry, event.data.name);
      if (event.data.name === "playIdle") {
        hotspot3.state.setAnimation(0, "idle", true);
        that.setState({ hotspot3: "idle" });
      } else if (event.data.name === "animalVisible") {
        animalLogic(event.stringValue);
      } else if (event.data.name === "duckExit") {
        noDuck();
      } else if (event.data.name === "busy") {
        const busyValue = event.stringValue === "true" ? "busy" : "available";
        that.setState({ hotspot3: busyValue });
      }
    },
  });
  hotspot4.state.addListener({
    event: function (entry, event) {
      console.log("hs4", event, entry, event.data.name);
      if (event.data.name === "playIdle") {
        hotspot4.state.setAnimation(0, "idle", true);
        that.setState({ hotspot4: "idle" });
      } else if (event.data.name === "animalVisible") {
        animalLogic(event.stringValue);
      } else if (event.data.name === "duckExit") {
        noDuck();
      } else if (event.data.name === "busy") {
        const busyValue = event.stringValue === "true" ? "busy" : "available";
        that.setState({ hotspot4: busyValue });
      }
    },
  });
  hotspot5.state.addListener({
    event: function (entry, event) {
      console.log("hs5", event, entry, event.data.name);
      if (event.data.name === "playIdle") {
        hotspot5.state.setAnimation(0, "idle", true);
        that.setState({ hotspot5: "idle" });
      } else if (event.data.name === "animalVisible") {
        animalLogic(event.stringValue);
      } else if (event.data.name === "duckExit") {
        noDuck();
      } else if (event.data.name === "busy") {
        const busyValue = event.stringValue === "true" ? "busy" : "available";
        that.setState({ hotspot5: busyValue });
      }
    },
  });

  duckCount1.state.addListener({
    event: function (entry, event) {
      // console.log("duckCount1", event, entry, event.data.name);
      if (event.data.name === "enter") {
        audio.UI1.play();
      } else if (event.data.name === "playIdleEmpty") {
        duckCount1.state.setAnimation(0, "idle_empty", true);
      } else if (event.data.name === "playIdleFull") {
        duckCount1.state.setAnimation(0, "idle_full", true);
      } else if (event.data.name === "NegativeResult") {
        audio.ducksResultNegative.play();
      } else if (event.data.name === "PositiveResult") {
        audio.ducksResultPositive.play();
      }
    },
  });

  duckCount2.state.addListener({
    event: function (entry, event) {
      // console.log("duckCount2", event, entry, event.data.name);
      if (event.data.name === "enter") {
        audio.UI2.play();
      } else if (event.data.name === "playIdleEmpty") {
        duckCount1.state.setAnimation(0, "idle_empty", true);
      }
      if (event.data.name === "playIdleFull") {
        duckCount1.state.setAnimation(0, "idle_full", true);
      }
    },
  });

  duckCount3.state.addListener({
    event: function (entry, event) {
      // console.log("duckCount3", event, entry, event.data.name);
      if (event.data.name === "enter") {
        audio.UI3.play();
      } else if (event.data.name === "playIdleEmpty") {
        duckCount1.state.setAnimation(0, "idle_empty", true);
      }
      if (event.data.name === "playIdleFull") {
        duckCount1.state.setAnimation(0, "idle_full", true);
      }
    },
  });

  texture.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        texture.state.setAnimation(0, "idle", true);
        that.setState({ ducksTexture: "idle" });
      }
    },
  });

  controller.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        controller.state.setAnimation(0, "idle", true);
        that.setState({ ducksController: "idle" });
      } else if (event.data.name === "ControllerSlideIn") {
        audio.controllerEnter.play();
      } else if (event.data.name === "MainButtonPress") {
        audio.buttonPress.play();
      }
    },
  });

  transition.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        transition.state.setAnimation(0, "idle", true);
        that.setState({ ducksTransition: "idle" });
      }
      if (event.data.name === "disappear") {
        forestBack.state.setAnimation(0, "disappear", false);
        forestFront.state.setAnimation(0, "disappear", false);
        hotspot1.state.setAnimation(0, "disappear", false);
        hotspot2.state.setAnimation(0, "disappear", false);
        hotspot3.state.setAnimation(0, "disappear", false);
        hotspot4.state.setAnimation(0, "disappear", false);
        hotspot5.state.setAnimation(0, "disappear", false);
        clearInterval(animalLoop);
      } else if (event.data.name === "playVideo") {
        video.play();
        parent.setState({ videoControls: true, ducksFinished: true });
        canvasWrap.classList.add("disabled");
        audio.ducksTrackLoop.pause();
      } else if (event.data.name === "unloadAssets") {
        ducksScene.visible = false;
      }
    },
  });
};

export default DuckGame;
