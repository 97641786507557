import React, { Component } from "react"
import * as THREE from "three"
import DragControls from "three-dragcontrols"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { easeOutExpo } from "js-easing-functions"
import OrientationHelper from "./OrientationHelper"

export default class FootballThreeContainer extends Component {
  componentDidMount() {
    const clearColor = 0xb1e1ff // light blue

    const hemisphereLightSkyColor = 0xffffff // white
    const hemisphereLightGroundColor = 0xb97a20 // brownish orange
    const hemisphereLightIntensity = 0.9

    const directionalLightColor = 0xffffff
    const directionalLightIntensity = 0.6
    const directionalLightPosition = new THREE.Vector3(10, 10, 10)

    const groundHeight = 0.2 // height of the ground of the model
    const initialSpeed = 1 // ball speed multiplicator
    const totalGravityTime = 3000 // time until gravity is at 100%
    const gravityBounceDecay = 500 // gravityTime is decreased by this on every bounce
    const speedBounceDecay = 0.1 // speed multiplicator is decreased by this on every bounce
    const speedRollDecay = 0.00005 // speed multiplicator is decreased by this every frame when ball is rolling
    const gravityMultiplicator = 2 // strength of gravity
    const totalResetTime = 500 // animation time when resetting the ball
    const minimumBallSpeed = 0.005 // minimum speed the ball must have for a valid shot
    const idleMovementTotalTime = 500 // time until idle ball movement is at full strength
    const maxNumberOfMissedShots = 3 // how often should the goal be missed before ending the game

    let missedShots = 0

    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    this.scene = new THREE.Scene()

    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    this.camera.position.y = 3
    this.camera.position.z = 5
    this.camera.basePosition = this.camera.position.clone()
    this.house = null

    this.orientation = new OrientationHelper()
    this.cursorCoordinates = new THREE.Vector3()

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.mount,
    })
    this.renderer.setSize(
      width * window.devicePixelRatio,
      height * window.devicePixelRatio,
      false
    )
    this.renderer.setClearColor(clearColor)

    const hemisphereLight = new THREE.HemisphereLight(
      hemisphereLightSkyColor,
      hemisphereLightGroundColor,
      hemisphereLightIntensity
    )
    this.scene.add(hemisphereLight)

    const directionalLight = new THREE.DirectionalLight(
      directionalLightColor,
      directionalLightIntensity
    )
    directionalLight.position.copy(directionalLightPosition)
    this.scene.add(directionalLight)

    let wasResultCalculated, speed, gravityTime

    window.addEventListener("resize", (event) => {
      const width = this.mount.clientWidth
      const height = this.mount.clientHeight
      this.camera.aspect = width / height
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(
        width * window.devicePixelRatio,
        height * window.devicePixelRatio,
        false
      )
    })

    const resetGame = (_) => {
      speed = initialSpeed
      gravityTime = totalGravityTime
      this.ball.position.set(0, 3, 0)

      this.ball.originalPosition = this.ball.position.clone()
      this.ball.wasShot = false
      this.ball.positionLast = this.ball.position.clone()
      this.ball.positionDelta = new THREE.Vector3()
      this.ball.elapsedTimeSinceDragend = 0
      this.ball.hasBounced = false
      this.ball.isRolling = false
      this.ball.isBeingReset = false
      this.ball.idleMovementFactor = 0
      this.ball.isBeingDragged = false

      wasResultCalculated = false

      this.controls.activate()
    }

    const resetGameAnimated = (_) => {
      this.ball.finalPosition = this.ball.position.clone()
      this.ball.resetAnimationInterpolationTime = 0
      this.ball.isBeingReset = true
    }

    let hasFootballFieldLoaded = false,
      hasFootballLoaded = false

    const gltfLoader = new GLTFLoader()
    gltfLoader.load(
      process.env.PUBLIC_URL +
      "/assets/GamePlan/VP_GamePlan_FootballScene_Delivery_Delivery_V012.gltf",
      (gltf) => {
        this.house = gltf.scene.children[3]
        this.house.scale.x = 0.2
        this.house.scale.y = 0.2
        this.house.scale.z = 0.2
        this.house.position.x = -15
        this.house.basePosition = this.house.position.clone()

        this.scene.add(this.house)

        hasFootballFieldLoaded = true

        if (hasFootballFieldLoaded && hasFootballLoaded) {
          resetGame()
          this.start()
        }
      }
    )

    const gameOver = (hasPlayerWon) => {
      if (hasPlayerWon) {
        this.props.updateMessage("success")
      } else {
        this.props.updateMessage("fail")
      }
    }

    const updateFootball = (elapsedTime) => {
      if (this.ball.lastUpdateTimeStamp) {
        const tslf = elapsedTime - this.ball.lastUpdateTimeStamp // tslf = time since last frame

        if (this.ball.isBeingReset) {
          // if the ball is being reset, gradually move it back to its original position
          this.ball.resetAnimationInterpolationTime += tslf
          const interpolator = Math.min(
            1,
            this.ball.resetAnimationInterpolationTime / totalResetTime
          )
          this.ball.position.lerpVectors(
            this.ball.finalPosition,
            this.ball.originalPosition,
            easeOutExpo(interpolator, 0, 1, 1)
          )
          if (interpolator === 1) resetGame()
        } else if (this.ball.wasShot) {
          this.ball.elapsedTimeSinceDragend += tslf

          // ball was flicked towards camera
          if (this.ball.positionDelta.z > 0) speed = 0

          // update ball position after it was flicked
          this.ball.position.x +=
            (tslf / this.ball.positionDeltaTime) *
            speed *
            this.ball.positionDelta.x
          this.ball.position.y +=
            (tslf / this.ball.positionDeltaTime) *
            speed *
            this.ball.positionDelta.y -
            (this.ball.elapsedTimeSinceDragend / gravityTime) *
            gravityMultiplicator // apply gravity
          this.ball.position.z +=
            (tslf / this.ball.positionDeltaTime) *
            speed *
            this.ball.positionDelta.z

          this.ball.rotation.x +=
            (tslf / this.ball.positionDeltaTime) *
            speed *
            this.ball.positionDelta.z

          if (
            !this.ball.isRolling &&
            this.ball.position.y < groundHeight + this.ball.radius
          ) {
            // ball touches ground
            this.ball.elapsedTimeSinceDragend = 0
            gravityTime = Math.max(0, gravityTime - gravityBounceDecay)
            speed = Math.max(0, speed - speedBounceDecay)

            this.ball.position.y = groundHeight + this.ball.radius

            if (this.ball.hasBounced)
              // ball bounced two frames in a row -> is rolling
              this.ball.isRolling = true

            this.ball.hasBounced = true
          } else this.ball.hasBounced = false

          if (this.ball.isRolling) {
            speed = Math.max(0, speed - tslf * speedRollDecay)
            this.ball.position.y = groundHeight + this.ball.radius
          }

          if (!wasResultCalculated) {
            // ball will be reset if...
            if (
              (!this.ball.willHitGoalXZ && speed < 0.3) || // ... it's not moving in the direction of the goal and is below a speed threshold
              (!this.ball.willHitGoalXZ &&
                this.ball.position.distanceTo(this.ball.originalPosition) >
                15) || // ... it's not moving in the direction of the goal and is above a distance threshold from it's original position
              speed === 0
            ) {
              // ... it's not moving anymore at all
              // ball is still
              resetGameAnimated()
              missedShots++
              this.props.shotCounter()
              if (missedShots === maxNumberOfMissedShots) {
                gameOver(false)
              }
            } else if (this.ball.position.z < goalCoords.left.bottom.z) {
              // check if ball is within goal bounds
              if (
                this.ball.position.x > goalCoords.left.bottom.x &&
                this.ball.position.x < goalCoords.right.bottom.x &&
                this.ball.position.y > goalCoords.left.bottom.y &&
                this.ball.position.y < goalCoords.left.top.y
              ) {
                // ball is in goal
                speed = 0
                wasResultCalculated = true
                gameOver(true)
                // SOUND: ball hit the goal
              } else {
                // ball is past goal line but not in goal bounds
                resetGameAnimated()
                missedShots++
                this.props.shotCounter()
                if (missedShots === maxNumberOfMissedShots) {
                  gameOver(false)
                  // SOUND: game lost
                }
                // SOUND: ball missed the goal
              }
            }
          }
        } else {
          // the ball hovers up and down when idle
          if (this.ball.isBeingDragged)
            // but not when it's being dragged -> idleMovementFactor is gradually decreased to 0
            this.ball.idleMovementFactor = Math.max(
              this.ball.idleMovementFactor - tslf,
              0
            )
          // ... otherwise it's gradually increased to 1
          else
            this.ball.idleMovementFactor = Math.min(
              this.ball.idleMovementFactor + tslf,
              idleMovementTotalTime
            )
          this.ball.position.y +=
            (Math.sin(elapsedTime * 0.001) * 0.005 * this.ball.idleMovementFactor) / idleMovementTotalTime
        }
      }
      this.ball.lastUpdateTimeStamp = elapsedTime
    }

    const initializeFootballControls = (_) => {
      this.controls = new DragControls(
        [this.ball],
        this.camera,
        this.renderer.domElement
      )

      this.controls.addEventListener("dragstart", (_) => {
        this.ball.positionLastTimestamp = Date.now()
        this.ball.positionDeltaTime = 0
        this.ball.isBeingDragged = true
      })
      this.controls.addEventListener("dragend", (_) => {
        this.controls.deactivate()
        if (this.ball.positionDeltaTime === 0) {
          // ball was clicked without dragging
          resetGame()
        } else {
          // ball was dragged
          const speed =
            this.ball.positionDelta.length() / this.ball.positionDeltaTime
          if (speed < minimumBallSpeed) {
            // ball wasn't dragged fast enough
            resetGameAnimated()
          } else {
            const isBallWithinBounds = (boundary) => {
              const ballGoalDistanceZ =
                this.ball.originalPosition.z - goalCoords[boundary].bottom.z
              let factor = ballGoalDistanceZ / this.ball.positionDelta.z
              let ballPositionOnGoalLine = this.ball.positionDelta
                .clone()
                .multiplyScalar(factor)
                .add(this.ball.originalPosition)
              switch (boundary) {
                case "left":
                  return (
                    ballPositionOnGoalLine.x > goalCoords[boundary].bottom.x
                  )
                case "right":
                  return (
                    ballPositionOnGoalLine.x < goalCoords[boundary].bottom.x
                  )
                default:
                  return null
              }
            }

            this.ball.willHitGoalXZ =
              isBallWithinBounds("left") && isBallWithinBounds("right")
            console.log(`will ball hit: ${this.ball.willHitGoalXZ}`)
            this.ball.wasShot = true

            // SOUND: ball was shot successfully
          }
        }
      })
      this.controls.addEventListener("drag", (_) => {
        let time = Date.now()
        this.ball.positionDeltaTime = time - this.ball.positionLastTimestamp
        this.ball.positionLastTimestamp = time

        this.ball.position.z =
          this.ball.originalPosition.z - 2 * (this.ball.position.y - this.ball.originalPosition.y)
        this.ball.positionDelta.set(
          this.ball.position.x - this.ball.positionLast.x,
          this.ball.position.y - this.ball.positionLast.y,
          this.ball.position.z - this.ball.positionLast.z
        )
        this.ball.positionLast.copy(this.ball.position)
      })
    }

    const setUpFootball = (_) => {
      this.ball.scale.set(0.2, 0.2, 0.2)
      this.ball.rotation.set(Math.random(), Math.random(), Math.random())
      this.ball.radius = 0.5
      this.ball.update = updateFootball
    }

    gltfLoader.load(
      process.env.PUBLIC_URL +
      "/assets/GamePlan/VP_GamePlan_Football_Delivery_V01.gltf",
      (gltf) => {
        this.ball = gltf.scene.children[3]

        setUpFootball()
        initializeFootballControls()

        this.scene.add(this.ball)

        hasFootballLoaded = true

        if (hasFootballFieldLoaded && hasFootballLoaded) {
          resetGame()
          this.start()
        }
      }
    )

    // These coordinates match the goal exactly
    // const goalCoords = {
    //     left: {
    //         bottom: new THREE.Vector3(-2, 0.2, -21),
    //         top: new THREE.Vector3(-2, 3, -21)
    //     },
    //     right: {
    //         bottom: new THREE.Vector3(2.8, 0.2, -21),
    //         top: new THREE.Vector3(2.8, 3, -21)
    //     }
    // }

    // These coordinates match the goal with some padding
    const goalCoords = {
      left: {
        bottom: new THREE.Vector3(-3, 0.2, -21),
        top: new THREE.Vector3(-3, 4, -21),
      },
      right: {
        bottom: new THREE.Vector3(3.8, 0.2, -21),
        top: new THREE.Vector3(3.8, 4, -21),
      },
    }

    const leftBottomCube = new THREE.Mesh(
      new THREE.BoxGeometry(0.5, 0.5, 0.5),
      new THREE.MeshBasicMaterial({ color: 0xff0000 })
    )
    leftBottomCube.position.copy(goalCoords.left.bottom)
    // this.scene.add(leftBottomCube)
    const rightBottomCube = new THREE.Mesh(
      new THREE.BoxGeometry(0.5, 0.5, 0.5),
      new THREE.MeshBasicMaterial({ color: 0xff0000 })
    )
    rightBottomCube.position.copy(goalCoords.right.bottom)
    // this.scene.add(rightBottomCube)
    const leftTopCube = new THREE.Mesh(
      new THREE.BoxGeometry(0.5, 0.5, 0.5),
      new THREE.MeshBasicMaterial({ color: 0xff0000 })
    )
    leftTopCube.position.copy(goalCoords.left.top)
    // this.scene.add(leftTopCube)
    const rightTopCube = new THREE.Mesh(
      new THREE.BoxGeometry(0.5, 0.5, 0.5),
      new THREE.MeshBasicMaterial({ color: 0xff0000 })
    )
    rightTopCube.position.copy(goalCoords.right.top)
    // this.scene.add(rightTopCube)
  }

  componentWillUnmount() {
    this.stop()
  }

  onHover = (event) => {
    this.cursorCoordinates.set(
      ((event.clientX / this.mount.clientWidth) * 2 - 1) * 0.5,
      ((event.clientY / this.mount.clientHeight) * -2 + 1) * 0.2,
      0
    )
  };

  start = () => {
    this.cursorCoordinates = new THREE.Vector3()

    document.addEventListener("mousemove", this.onHover)

    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  };
  stop = () => {
    cancelAnimationFrame(this.frameId)
    document.removeEventListener("mousemove", this.onHover)
  };
  animate = (elapsedTime) => {
    this.lastElapsedTime = elapsedTime

    if (this.orientation.isEnabled) {
      this.orientation.update()
      this.camera.position.x += this.orientation.delta.y
      // this.camera.position.y += this.orientation.delta.x
    } else
      this.camera.position
        .copy(this.camera.basePosition)
        .add(this.cursorCoordinates)

    this.ball.update(elapsedTime)
    this.renderer.render(this.scene, this.camera)
    this.frameId = window.requestAnimationFrame(this.animate)
  };
  render() {
    return (
      <canvas
        ref={(mount) => {
          this.mount = mount
        }}
        style={{ width: "100%", height: "100vh" }}
      ></canvas>
    )
  }
}
