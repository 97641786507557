import React, { Component } from "react";
import MenuToggle from "./MenuToggle";
import Logo from "./Logo.jsx";
import Menu from "../Menu/Menu";
import Share from "./Share";

import "./NavBar.scss";

let story = "";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
  }

  menuToggle = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
    document.body.classList.toggle("noscroll");
  };

  render() {
    if (
      this.props.section === "gameFace" ||
      this.props.section === "gameFaceGame"
    )
      story = "Game Face";
    if (this.props.section === "gamePlan") story = "Game Plan";
    if (this.props.section === "gameOn") story = "Game On";
    if (this.props.section === "null") story = "";
    return (
      <React.Fragment>
        <div id="nav-bar" className={`nav-bar`}>
          <div className="toggle-wrap">
            <MenuToggle menuToggle={this.menuToggle} />
          </div>
          <Logo />
          {/* <div className="characters"></div> */}
          <Share />
        </div>
        <Menu active={this.state.menuOpen} menuToggle={this.menuToggle} />
      </React.Fragment>
    );
  }
}

export default NavBar;
