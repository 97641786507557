import React, { Component } from "react";

class StartArrow extends Component {
  render() {
    return (
      <svg
        width="44px"
        height="43px"
        viewBox="0 0 44 43"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="exports"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Desktop-HD"
            transform="translate(-318.000000, -491.000000)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <polygon
              id="Combined-Shape"
              points="341.187333 493.701829 344.298171 491.187333 359.504 510 359.525662 510 359.525 510.026 361.733439 512.757585 344.262215 533.816246 341.183754 531.262215 355.504 514 318.611193 514 318.611193 510 354.361 510"
            ></polygon>
          </g>
        </g>
      </svg>
    );
  }
}

export default StartArrow;
