import * as imageCache from './image-cache';

const loaders = {};

const EMPTY_LOADER_VALUE = null;

const emptyFn = () => {
};

export const getLoader = (imageUrl) => {
  if (typeof loaders[imageUrl] !== 'undefined' && loaders[imageUrl] !== EMPTY_LOADER_VALUE) {
    return loaders[imageUrl];
  }

  if (imageCache.hasImage(imageUrl)) {
    return createLoaderForAlreadyLoadedImage();
  }

  const loader = createLoader(imageUrl);
  loaders[imageUrl] = loader;

  const unsubscribe = [
    loader.onLoad(onLoadedResult),
    loader.onError(onLoadedResult),
  ];

  function onLoadedResult() {
    loaders[imageUrl] = EMPTY_LOADER_VALUE;
    unsubscribe.forEach(fn => fn());
  }

  return loader;
};

function createLoader(imageUrl) {
  let isLoaded = false;
  let isError = false;

  let onLoadListeners = [];
  let onErrorListeners = [];

  const image = new Image();
  image.addEventListener('load', onImageLoadingSucceed, false);
  image.addEventListener('error', onImageLoadingFailed, false);
  image.src = imageUrl;

  return {
    onLoad,
    onError
  };

  function onError(cb) {
    if (isError) {
      cb();
      return emptyFn;
    }

    onErrorListeners.push(cb);

    return () => {
      onErrorListeners = onErrorListeners.filter(fn => fn !== cb);
    };
  }

  function notifyOnErrorListeners() {
    onErrorListeners.forEach(fn => fn());
  }

  function onLoad(cb) {
    if (isLoaded) {
      cb();
      return emptyFn;
    }

    onLoadListeners.push(cb);

    return () => {
      onLoadListeners = onLoadListeners.filter(fn => fn !== cb);
    };
  }

  function notifyOnLoadListeners() {
    onLoadListeners.forEach(fn => fn());
  }

  function onImageLoadingSucceed() {
    imageCache.addImage(imageUrl, image);
    removeImageListeners();
    isLoaded = true;
    notifyOnLoadListeners();
  }

  function onImageLoadingFailed() {
    removeImageListeners();
    isError = true;
    notifyOnErrorListeners();
  }

  function removeImageListeners() {
    image.removeEventListener('load', onImageLoadingSucceed, false);
    image.removeEventListener('error', onImageLoadingFailed, false);
  }
}

function createLoaderForAlreadyLoadedImage() {
  return {
    onLoad,
    onError
  };

  function onLoad(cb) {
    cb();
    return emptyFn;
  }

  function onError() {
    return emptyFn;
  }
}
