import React from "react";
import Video from "./Video";
import ReactGA from "react-ga";

import { videoPaths } from "../../utils/mediaManager";

import "./video.scss";

const listVideo = [
  {
    src: videoPaths("A44-WETA.mp4"),
    title:
      "How New Zealand gaming studios are striving to be the best in the world.",
    subTitle: "Michael Vermeulen",
    youTube: "https://www.youtube.com/embed/01a51Dlsq14",
  },
  {
    src: videoPaths("LORIIPOPS.mp4"),
    title: "The dual lives of one of our most successful video game streamers.",
    subTitle: "Lorien Gugich",
    youTube: "https://www.youtube.com/embed/o48YG-290XI",
  },
  {
    src: videoPaths("HUMPHREY.mp4"),
    title:
      "Humphrey doesn't have hands, or excuses. How gaming has become more accessible.",
    subTitle: "Humphrey Hanley",
    youTube: "https://www.youtube.com/embed/haerMj8I6Ns",
  },
  {
    src: videoPaths("TENFORWARD.mp4"),
    title:
      "The after school video game programme giving a kids a space to play.",
    subTitle: "Ten Forward",
    youTube: "https://www.youtube.com/embed/NcHLIXbtIeM",
  },
];

class VideoPage extends React.Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    document.body.classList.add("no-bounce");

    // Wheel Mouse
    const listVideo = document.getElementById("listVideo");
    var witdhScroll = listVideo.scrollWidth - listVideo.offsetWidth;
    window.addEventListener("resize", () => {
      witdhScroll = listVideo.scrollWidth - listVideo.offsetWidth;
    });
    var xPos = 0;
    var i = document.querySelector('input[type="range"]');

    window.addEventListener("wheel", (event) => {
      xPos += event.deltaY;
      if (xPos < 0) xPos = 0;
      if (xPos > witdhScroll) xPos = witdhScroll;
      let posScroll = ((xPos / witdhScroll) * 100).toFixed();
      i.value = posScroll;
      let percentWitdhScroll = (witdhScroll * i.value) / 100;
      listVideo.scrollLeft = percentWitdhScroll;
    });
    i.addEventListener(
      "input",
      function () {
        i.value = i.value;
        let percentWitdhScroll = (witdhScroll * i.value) / 100;
        xPos = (i.value * witdhScroll) / 100;
        // listVideo.scrollTo(percentWitdhScroll, 0);
        listVideo.scrollLeft = percentWitdhScroll;
      },
      false
    );

    // Touch Mouse
    // document.addEventListener(
    //   "touchmove",
    //   event => {
    //     let witdhScroll = listVideo.scrollLeft;
    //     let posScroll =
    //       (witdhScroll / (listVideo.scrollWidth - listVideo.offsetWidth)) * 100;
    //     scrollBarBtn.style.left = posScroll + "%";
    //   },
    //   false
    // );
  }

  render() {
    return (
      <div className="video-page">
        {/* <h1 className="video-page__title">Videos</h1> */}
        <div className="video-page__content">
          <div id="listVideo">
            <div className="section-list-video">
              <div className="item-first"></div>
              {listVideo.map((item, index) => {
                let i;
                if (index + 1 < 10) {
                  i = "0" + (index + 1);
                }
                return (
                  <div className="video-item" key={i}>
                    <div className="video-item__sequence-number">{i}</div>
                    <Video
                      videoSrc={item.src}
                      key={index}
                      title={item.subTitle}
                      youTube={item.youTube}
                    />
                    <div className="video-item__inner">
                      {/* <h6>{item.subTitle}</h6> */}
                      <h3>{item.title}</h3>
                    </div>
                  </div>
                );
              })}
              <div className="item-last"></div>
            </div>
          </div>
        </div>
        <div className="video-scroll-more">
          <div className="video-scroll-more__wrapper">
            <div>SCROLL FOR MORE</div>
            <input type="range" defaultValue="0" min="0" max="100" step="1" />
          </div>
        </div>
        <div className="modal-video" id="modalVideo"></div>
      </div>
    );
  }
}

export default VideoPage;
