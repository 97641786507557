import React from "react";
import { connect } from "react-redux";
import { Controller, Scene } from "react-scrollmagic";
import LazyLoad from "react-lazyload";

import { fetchPosts } from "../../state/actions";
import { getArticleData } from "../../state/selectors";
import { GP_ARTICLE_PAGEID } from "../../api/api";
import Loading from "../Story/Loading";

import { MikeQuote1, MikeQuote2, ministerQuote } from "../../utils/fileManager";
// import { gamePlanLoop } from "../../utils/fileManager";

import ImageColumns from "../Article/ImageColumns";
import ImageFull from "../Article/ImageFull";

import MikesQuoteVideoOne from "./MikesQuoteVideoOne";
import MikesQuoteVideoTwo from "./MikesQuoteVideoTwo";
// import A44sQuoteVideo from "./A44sQuoteVideo";
// import A44sQuoteVideoTwo from "./A44sQuoteVideoTwo";
import A44sQuoteVideoThree from "./A44sQuoteVideoThree";

import { imagePaths } from "../../utils/mediaManager";

import "../App/Base.scss";
import "../App/Mixins.scss";
import "../App/Variables.scss";
import "../App/Article.scss";

const quoteOneVideoID = "mike-quote-one";
const quoteTwoVideoID = "mike-quote-two";
// const quoteThreeVideoID = "a44-quote-two";
// const quoteFourVideoID = "a44-quote-one";
const quoteFiveVideoID = "a44-quote-three";
const quoteOneVideoSrc = MikeQuote1();
const quoteTwoVideoSrc = MikeQuote2();
// const quoteThreeVideoSrc = Cassandra1();
// const quoteFourVideoSrc = Cassandra2();
const quoteFiveVideoSrc = ministerQuote();

const ashen1 = imagePaths("gameplan", "ashen_1.jpg");
const ashen2 = imagePaths("gameplan", "ashen_2.jpg");
const ashen3 = imagePaths("gameplan", "ashen_3.jpg");
const cassandra = imagePaths("gameplan", "cassandra.jpg");
const gameshop1 = imagePaths("gameplan", "gameshop_1.jpg");
const gameshop2 = imagePaths("gameplan", "gameshop_2.jpg");
const intro1 = imagePaths("gameplan", "intro_1.jpg");
const intro2 = imagePaths("gameplan", "intro_2.jpg");
const intro3 = imagePaths("gameplan", "intro_3.jpg");
const spaces1 = imagePaths("gameplan", "spaces_1.jpg");
const spaces2 = imagePaths("gameplan", "spaces_2.jpg");
const spaces3 = imagePaths("gameplan", "spaces_3.jpg");

class GamePlanArticle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video1muted: false,
      video2muted: false,
      video3muted: false,
      video4muted: false,
      video5muted: false,
    };

    this.article = null;
  }

  componentDidMount() {
    this.props.fetchData();
  }

  video1MuteToggle = () => {
    const video1 = document.getElementById(quoteOneVideoID);
    video1.muted = !this.state.video1muted;
    this.setState({ video1muted: !this.state.video1muted });
  };

  video2MuteToggle = () => {
    const video2 = document.getElementById(quoteTwoVideoID);
    video2.muted = !this.state.video2muted;
    this.setState({ video2muted: !this.state.video2muted });
  };

  video5MuteToggle = () => {
    const video5 = document.getElementById(quoteFiveVideoID);
    video5.muted = !this.state.video5muted;
    this.setState({ video5muted: !this.state.video5muted });
  };

  render() {
    const { article } = this.props;
    let layouts = "";

    if (article) {
      layouts = article.acf.article_components;
    }

    let rightOutput = "";
    if (layouts) {
      // Loop through acf and display all layouts.
      rightOutput = layouts.map((layout, i) => {
        if (layout.acf_fc_layout === "text_block") {
          let text = layout.text_block;
          const blockClass = layout.blockClass;
          if (blockClass === "intro") {
            return (
              <React.Fragment key={i}>
                <div className={`block-container ${blockClass}`} key={i}>
                  <div
                    className="text-block"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </div>
              </React.Fragment>
            );
          } else if (blockClass) {
            return (
              <div className={`block-container ${blockClass}`} key={i}>
                <div
                  className="text-block"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
            );
          } else {
            return (
              <div className={`block-container`} key={i}>
                <div
                  className="text-block"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
            );
          }
        } else if (layout.acf_fc_layout === "image_block") {
          const imageGroup = layout.image_group;
          if (imageGroup === "ashen") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageColumns
                  leftCol={[
                    {
                      src: ashen1,
                      size: "square",
                      imgId: "ashen1",
                    },
                  ]}
                  leftClasses=" align-end"
                  rightCol={[
                    {
                      src: ashen2,
                      size: "tall",
                      imgId: "ashen2",
                    },
                  ]}
                  rightClasses=""
                />
                <ImageFull src={ashen3} size="wide" imgId="ashen3" />
              </div>
            );
          } else if (imageGroup === "spaces") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageColumns
                  leftCol={[
                    {
                      src: spaces1,
                      size: "square",
                      imgId: "spaces1",
                    },
                  ]}
                  leftClasses=""
                  rightCol={[
                    {
                      src: spaces3,
                      size: "square",
                      imgId: "space3",
                    },
                  ]}
                  rightClasses=""
                />
                <ImageFull src={spaces2} size="wide" imgId="spaces2" />
              </div>
            );
          } else if (imageGroup === "intro") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageFull src={intro1} size="wide" imgId="intro1" />
                <ImageColumns
                  leftCol={[
                    {
                      src: intro3,
                      size: "tall",
                      imgId: "intro3",
                    },
                  ]}
                  leftClasses=""
                  rightCol={[
                    {
                      src: intro2,
                      size: "square",
                      imgId: "intro2",
                    },
                  ]}
                  rightClasses=""
                />
              </div>
            );
          } else if (imageGroup === "gameshop") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageColumns
                  leftCol={[
                    {
                      src: gameshop1,
                      size: "tall",
                      imgId: "gameshop1",
                    },
                  ]}
                  leftClasses=""
                  rightCol={[
                    {
                      src: gameshop2,
                      size: "tall",
                      imgId: "gameshop2",
                    },
                  ]}
                  rightClasses=" start-space"
                />
              </div>
            );
          } else if (imageGroup === "cassandra") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageFull src={cassandra} size="wide" imgId="cassandra" />
              </div>
            );
          }
        } else if (layout.acf_fc_layout === "section_heading") {
          let text = layout.heading;
          return (
            <div className="section-heading" key={i}>
              <h2 dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          );
        } else if (layout.acf_fc_layout === "video") {
          const videoID = layout.videoID;
          if (videoID === "mikeOne") {
            return (
              <div className="quote-wrap" key={i}>
                <Controller>
                  <Scene
                    duration={"125%"}
                    triggerHook="0.75"
                    // indicators={true}
                  >
                    {(progress, event) => {
                      // console.log(event, progress);

                      const video1 = document.getElementById(quoteOneVideoID);
                      const wrap1 = document.getElementById("wrap1");
                      if (event.type === "enter") {
                        this.props.controlLoopTrack();
                        if (wrap1) wrap1.classList.add("active");
                        if (video1) {
                          var promise = video1.play();
                          if (promise !== undefined) {
                            promise
                              .then((_) => {
                                // Autoplay started!
                              })
                              .catch((error) => {
                                video1.muted = true;
                                video1.play();
                                this.setState({ video1muted: true });
                              });
                          }
                        }
                      } else if (event.type === "leave") {
                        this.props.controlLoopTrack();
                        if (wrap1) {
                          wrap1.classList.remove("active");
                          wrap1.classList.add("current");
                        }
                        if (video1) {
                          video1.pause();
                        }
                      }
                      return (
                        <div className="quote-wrap" key={i}>
                          <div className="inner">
                            <LazyLoad
                              offset={500}
                              placeholder={<div className="placeholder"></div>}
                            >
                              <div className="video-wrap current" id="wrap1">
                                <video
                                  id={quoteOneVideoID}
                                  className="video-quote"
                                  src={quoteOneVideoSrc}
                                  videoid={quoteOneVideoID}
                                  controls={false}
                                  playsInline
                                />
                                <div
                                  className={`muteToggle ${this.state.video1muted}`}
                                  onClick={this.video1MuteToggle}
                                >
                                  {this.state.video1muted &&
                                  this.state.video1muted
                                    ? "Unmute"
                                    : "Mute"}
                                </div>
                              </div>

                              <MikesQuoteVideoOne videoid={quoteOneVideoID} />
                            </LazyLoad>
                          </div>
                        </div>
                      );
                    }}
                  </Scene>
                </Controller>
              </div>
            );
          } else if (videoID === "mikeTwo") {
            return (
              <div className="quote-wrap" key={i}>
                <Controller key={i}>
                  <Scene
                    duration={"125%"}
                    triggerHook="0.75"
                    // indicators={true}
                  >
                    {(progress, event) => {
                      // console.log(event, progress);

                      const video2 = document.getElementById(quoteTwoVideoID);
                      const wrap2 = document.getElementById("wrap2");
                      if (event.type === "enter") {
                        this.props.controlLoopTrack();
                        if (wrap2) wrap2.classList.add("active");
                        if (video2) {
                          var promise = video2.play();
                          if (promise !== undefined) {
                            promise
                              .then((_) => {
                                // Autoplay started!
                              })
                              .catch((error) => {
                                video2.muted = true;
                                video2.play();
                                this.setState({ video2muted: true });
                              });
                          }
                        }
                      } else if (event.type === "leave") {
                        this.props.controlLoopTrack();
                        if (wrap2) {
                          wrap2.classList.remove("active");
                          wrap2.classList.add("current");
                        }
                        if (video2) {
                          video2.pause();
                        }
                      }
                      return (
                        <div className="quote-wrap" key={i}>
                          <div className="inner">
                            <LazyLoad
                              offset={500}
                              placeholder={<div className="placeholder"></div>}
                            >
                              <div className="video-wrap" id="wrap2">
                                <video
                                  id={quoteTwoVideoID}
                                  className="video-quote"
                                  src={quoteTwoVideoSrc}
                                  videoid={quoteTwoVideoID}
                                  controls={false}
                                  playsInline
                                />
                                <div
                                  className={`muteToggle ${this.state.video2muted}`}
                                  onClick={this.video2MuteToggle}
                                >
                                  {this.state.video2muted &&
                                  this.state.video2muted
                                    ? "Unmute"
                                    : "Mute"}
                                </div>
                              </div>
                              <MikesQuoteVideoTwo videoid={quoteTwoVideoID} />
                            </LazyLoad>
                          </div>
                        </div>
                      );
                    }}
                  </Scene>
                </Controller>
              </div>
            );
          } else if (videoID === "phillTwyford") {
            return (
              <div className="quote-wrap" key={i}>
                <Controller>
                  <Scene
                    duration={"125%"}
                    triggerHook="0.75"
                    // indicators={true}
                  >
                    {(progress, event) => {
                      // console.log(event, progress);

                      const video5 = document.getElementById(quoteFiveVideoID);
                      const wrap5 = document.getElementById("wrap5");
                      if (event.type === "enter") {
                        this.props.controlLoopTrack();
                        if (wrap5) wrap5.classList.add("active");
                        if (video5) {
                          var promise = video5.play();
                          if (promise !== undefined) {
                            promise
                              .then((_) => {
                                // Autoplay started!
                              })
                              .catch((error) => {
                                video5.muted = true;
                                video5.play();
                                this.setState({ video5muted: true });
                              });
                          }
                        }
                      } else if (event.type === "leave") {
                        this.props.controlLoopTrack();
                        if (wrap5) {
                          wrap5.classList.remove("active");
                          wrap5.classList.add("current");
                        }
                        if (video5) {
                          video5.pause();
                        }
                      }
                      return (
                        <div className="quote-wrap" key={i}>
                          <div className="inner">
                            <LazyLoad
                              offset={500}
                              placeholder={<div className="placeholder"></div>}
                            >
                              <div className="video-wrap" id="wrap5">
                                <video
                                  id={quoteFiveVideoID}
                                  className="video-quote"
                                  src={quoteFiveVideoSrc}
                                  videoid={quoteFiveVideoID}
                                  controls={false}
                                  playsInline
                                />
                                <div
                                  className={`muteToggle ${this.state.video5muted}`}
                                  onClick={this.video5MuteToggle}
                                >
                                  {this.state.video5muted &&
                                  this.state.video5muted
                                    ? "Unmute"
                                    : "Mute"}
                                </div>
                              </div>
                              <A44sQuoteVideoThree videoid={quoteFiveVideoID} />
                            </LazyLoad>
                          </div>
                        </div>
                      );
                    }}
                  </Scene>
                </Controller>
              </div>
            );
          }
        }
        return null;
      });

      return (
        <div
          className="game-plan"
          id="game-plan-article"
          ref={(ref) => {
            this.article = ref;
          }}
        >
          <div className="article-main">
            {/* <StandFirst /> */}
            <div className="article-wrap" id="article">
              {/* <div className="title">
                <h2>The Gaming company</h2>
                <div className="read-duration">5 MIN READ</div>
              </div> */}
              {/* <div id="game-intro">
              <GameIntro
                loop={gamePlanLoop}
                prompt="See if you can make it in the gaming industry"
                story="gamePlan"
              />
            </div> */}

              {rightOutput}
              <div className="block-container youtube-video">
                <div className="text-block">
                  <div className="video-wrap">
                    <iframe
                      src="https://www.youtube.com/embed/01a51Dlsq14"
                      frameBorder="0"
                      allow="accelerometer; encrypted-media; gyroscope;"
                      // playsInline
                      allowFullScreen
                      title="https://www.youtube.com/embed/01a51Dlsq14"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="error">
        <Loading />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  article: getArticleData(state, GP_ARTICLE_PAGEID),
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => {
    dispatch(fetchPosts());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GamePlanArticle);
