import React, { Component } from "react";

import { HumphreySound2 } from "../../utils/fileManager";
import QuoteVideo from "../../components/Article/QuoteVideo";

import { getDelay } from "../../utils/utils";

import Replay from "../Video/Replay";

const Timings = [
  {
    start: 0.16,
    stop: 0.42,
    delay: 0.1,
    word: "Life",
  },
  {
    start: 0.47,
    stop: 0.59,
    delay: 0,
    word: "back",
  },
  {
    start: 0.71,
    stop: 1.02,
    delay: 0.1,
    word: "then",
  },
  {
    start: 1.32,
    stop: 1.55,
    delay: 0.3,
    word: "was",
  },
  {
    start: 1.86,
    stop: 2.05,
    delay: 0.3,
    word: "obviously",
  },
  {
    start: 2.16,
    stop: 2.3,
    delay: 0.1,
    word: "very",
  },
  {
    start: 2.46,
    stop: 2.83,
    delay: 0.2,
    word: "different - ",
  },
  {
    start: 3.18,
    stop: 3.26,
    delay: 0.4,
    word: "there",
  },
  {
    start: 3.28,
    stop: 3.36,
    delay: 0,
    word: "was",
  },
  {
    start: 3.44,
    stop: 3.56,
    delay: 0.1,
    word: "a lot",
  },
  {
    start: 3.71,
    stop: 3.82,
    delay: 0.1,
    word: "less",
  },
  {
    start: 3.87,
    stop: 4.35,
    delay: 0.1,
    word: "emphasis",
  },
  {
    start: 4.52,
    stop: 4.6,
    delay: 0.2,
    word: "on",
  },
  {
    start: 4.78,
    stop: 5.6,
    delay: 0.2,
    word: "inclusivity.",
  },
  {
    start: 6.6,
    stop: 6.88,
    delay: 1,
    word: "So,",
  },
  {
    start: 7.45,
    stop: 7.78,
    delay: 0.6,
    word: "being",
  },
  {
    start: 8.04,
    stop: 8.16,
    delay: 0.3,
    word: "a",
  },
  {
    start: 8.29,
    stop: 8.51,
    delay: 0.1,
    word: "kid",
  },
  {
    start: 8.59,
    stop: 8.65,
    delay: 0.1,
    word: "with",
  },
  {
    start: 8.65,
    stop: 8.75,
    delay: 0,
    word: "a",
  },
  {
    start: 8.78,
    stop: 9.35,
    delay: 0,
    word: "disability",
  },
  {
    start: 9.43,
    stop: 9.58,
    delay: 0.1,
    word: "like",
  },
  {
    start: 9.65,
    stop: 9.97,
    delay: 0.1,
    word: "mine",
  },
  {
    start: 10.0,
    stop: 10.08,
    delay: 0.03,
    word: "that",
  },
  {
    start: 10.08,
    stop: 10.31,
    delay: 0,
    word: "was",
  },
  {
    start: 10.43,
    stop: 10.53,
    delay: 0.1,
    word: "so",
  },
  {
    start: 10.61,
    stop: 10.82,
    delay: 0.1,
    word: "very",
  },
  {
    start: 11.0,
    stop: 11.48,
    delay: 0.2,
    word: "physical",
  },
  {
    start: 11.6,
    stop: 11.78,
    delay: 0.1,
    word: "and",
  },
  {
    start: 11.79,
    stop: 12.02,
    delay: 0,
    word: "very",
  },
  {
    start: 12.14,
    stop: 12.6,
    delay: 0.1,
    word: "obvious,",
  },
  {
    start: 13.04,
    stop: 13.27,
    delay: 0.4,
    word: "and,",
  },
  {
    start: 13.44,
    stop: 13.49,
    delay: 0.2,
    word: "for",
  },
  {
    start: 13.62,
    stop: 13.78,
    delay: 0.1,
    word: "a lot",
  },
  {
    start: 13.75,
    stop: 14.06,
    delay: -0,
    word: "of young",
  },
  {
    start: 14.14,
    stop: 14.41,
    delay: 0.1,
    word: "people,",
  },
  {
    start: 14.49,
    stop: 14.67,
    delay: 0.1,
    word: "very",
  },
  {
    start: 14.77,
    stop: 15.25,
    delay: 0.1,
    word: "disturbing,",
  },
  {
    start: 16.24,
    stop: 16.39,
    delay: 0.9,
    word: "seeing",
  },
  {
    start: 16.51,
    stop: 16.79,
    delay: 0.1,
    word: "someone",
  },
  {
    start: 16.91,
    stop: 17.08,
    delay: 0.1,
    word: "who",
  },
  {
    start: 17.24,
    stop: 17.44,
    delay: 0.2,
    word: "might",
  },
  {
    start: 17.56,
    stop: 17.73,
    delay: 0.1,
    word: "have",
  },
  {
    start: 18.71,
    stop: 18.87,
    delay: 1,
    word: "some",
  },
  {
    start: 18.92,
    stop: 19.03,
    delay: 0.1,
    word: "blood",
  },
  {
    start: 19.13,
    stop: 19.22,
    delay: 0.1,
    word: "on",
  },
  {
    start: 19.32,
    stop: 19.4,
    delay: 0.1,
    word: "their",
  },
  {
    start: 19.53,
    stop: 19.89,
    delay: 0.1,
    word: "t-shirt",
  },
  {
    start: 20.14,
    stop: 20.23,
    delay: 0.3,
    word: "because",
  },
  {
    start: 20.29,
    stop: 20.36,
    delay: 0.1,
    word: "of",
  },
  {
    start: 20.39,
    stop: 20.46,
    delay: 0,
    word: "a",
  },
  {
    start: 20.49,
    stop: 20.88,
    delay: 0,
    word: "wound,",
  },
  {
    start: 21.13,
    stop: 21.27,
    delay: 0.3,
    word: "or",
  },
  {
    start: 22.61,
    stop: 22.76,
    delay: 1.3,
    word: "have",
  },
  {
    start: 23.51,
    stop: 23.88,
    delay: 0.8,
    word: "physical",
  },
  {
    start: 24.14,
    stop: 24.7,
    delay: 0.3,
    word: "scarring",
  },
  {
    start: 24.79,
    stop: 24.87,
    delay: 0.1,
    word: "of",
  },
  {
    start: 24.93,
    stop: 25.43,
    delay: 0.1,
    word: "hands,",
  },
  {
    start: 25.73,
    stop: 25.85,
    delay: 0.3,
    word: "was",
  },
  {
    start: 26.78,
    stop: 26.95,
    delay: 0.9,
    word: "tough",
  },
  {
    start: 27.12,
    stop: 27.17,
    delay: 0.2,
    word: "for",
  },
  {
    start: 27.24,
    stop: 27.32,
    delay: 0.1,
    word: "a lot",
  },
  {
    start: 27.4,
    stop: 27.46,
    delay: 0.1,
    word: "of",
  },
  {
    start: 27.52,
    stop: 27.66,
    delay: 0.1,
    word: "kids,",
  },
  {
    start: 27.74,
    stop: 27.82,
    delay: 0.1,
    word: "I",
  },
  {
    start: 27.86,
    stop: 28.04,
    delay: 0,
    word: "think.",
  },
  {
    start: 28.26,
    stop: 28.4,
    delay: 0.2,
    word: "And",
  },
  {
    start: 28.41,
    stop: 28.49,
    delay: 0,
    word: "it",
  },
  {
    start: 28.62,
    stop: 28.8,
    delay: 0.1,
    word: "made",
  },
  {
    start: 28.9,
    stop: 29.01,
    delay: 0.1,
    word: "them",
  },
  {
    start: 29.09,
    stop: 29.21,
    delay: 0.1,
    word: "very",
  },
  {
    start: 29.35,
    stop: 30.0,
    delay: 0.1,
    word: "mean.",
  },
];

let delayItems = [];
let interval = null;
let video = null;

class HumphreyAudioTwo extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: 0,
      progress: 0,
      muteState: false,
    };
  }
  componentDidMount() {
    video = document.getElementById(this.props.audioId);
    this.getVideoData();
    for (var i = 1; i < Timings.length; i++) {
      delayItems.push(getDelay(Timings[i - 1], Timings[i]));
    }
    this.setState({ muteState: this.props.muteState });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.muteState !== prevState.muteState) {
      return { muteState: nextProps.muteState };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.muteState !== this.props.muteState) {
      //Perform some operation here
      this.setState({ muteState: this.props.muteState });
      video.muted = this.props.muteState;
    }
  }

  componentWillUnmount() {
    clearInterval(interval);
    video.removeEventListener("loadedmetadata", this.getDuration);
    video.pause();
  }

  updateTime = () => {
    interval = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        // const timeline = this.timeline.getGSAP();
        // console.log(timeline._timeline);
      }
    }, 50);
  };

  getVideoData = () => {
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.getDuration);
      return video.duration;
    }
    return null;
  };

  getDuration = () => {
    this.setState({
      duration: parseFloat(video.duration.toFixed(1)),
    });
    this.updateTime();
  };

  replay = () => {
    if (video) {
      video.currentTime = 0;
      if (video.paused) {
        video.play();
      }
    }
  };

  render() {
    return (
      <div className="quotes">
        <audio src={HumphreySound2()} id={this.props.audioId} controls={true} />

        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.audioId)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#B6CAE1"
          endColor="#4179BD"
        />
        <div className="replay-btn" onClick={this.replay}>
          <Replay />
          Play
        </div>
      </div>
    );
  }
}

export default HumphreyAudioTwo;
