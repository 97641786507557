import React, { Component } from "react";

import QuoteVideo from "../Article/QuoteVideo";

const Timings = [
  {
    start: 0.6,
    stop: 0.65,
    delay: 0.1,
    word: "It",
  },
  {
    start: 0.68,
    stop: 0.76,
    delay: 0,
    word: "was",
  },
  {
    start: 0.85,
    stop: 0.96,
    delay: 0.1,
    word: "hard",
  },
  {
    start: 0.97,
    stop: 1.1,
    delay: 0,
    word: "to",
  },
  {
    start: 1.22,
    stop: 1.27,
    delay: 0.1,
    word: "leave",
  },
  {
    start: 1.41,
    stop: 1.7,
    delay: 0.1,
    word: "Ubisoft,",
  },
  {
    start: 1.82,
    stop: 1.85,
    delay: 0.1,
    word: "it",
  },
  {
    start: 1.99,
    stop: 2.13,
    delay: 0.1,
    word: "was",
  },
  {
    start: 2.31,
    stop: 2.42,
    delay: 0,
    word: "a",
  },
  {
    start: 2.92,
    stop: 2.96,
    delay: 0.5,
    word: "dream",
  },
  {
    start: 3.07,
    stop: 3.27,
    delay: 0.1,
    word: "company",
  },
  {
    start: 3.38,
    stop: 3.48,
    delay: 0.1,
    word: "to",
  },
  {
    start: 3.55,
    stop: 3.65,
    delay: 0.1,
    word: "work",
  },
  {
    start: 3.69,
    stop: 3.92,
    delay: 0,
    word: "at.",
  },
  {
    start: 4.5,
    stop: 5.27,
    delay: 0.8,
    word: "I",
  },
  {
    start: 5.44,
    stop: 5.55,
    delay: 0.2,
    word: "was",
  },
  {
    start: 5.27,
    stop: 6.1,
    delay: -0.3,
    word: "excited",
  },
  {
    start: 6.13,
    stop: 6.25,
    delay: 0,
    word: "for",
  },
  {
    start: 6.51,
    stop: 6.82,
    delay: 0.3,
    word: "projects",
  },
  {
    start: 6.91,
    stop: 7.02,
    delay: 0.1,
    word: "that",
  },
  {
    start: 7.05,
    stop: 7.16,
    delay: 0,
    word: "have",
  },
  {
    start: 7.48,
    stop: 7.63,
    delay: 0.3,
    word: "since",
  },

  {
    start: 7.73,
    stop: 7.85,
    delay: 0.1,
    word: "been",
  },
  {
    start: 7.98,
    stop: 8.32,
    delay: 0.1,
    word: "announced",
  },
  {
    start: 8.42,
    stop: 8.65,
    delay: 0.1,
    word: "and are",
  },
  {
    start: 8.93,
    stop: 9.22,
    delay: 0.3,
    word: "out",
  },
  {
    start: 9.25,
    stop: 9.36,
    delay: 0,
    word: "that",
  },
  {
    start: 9.44,
    stop: 9.5,
    delay: 0.1,
    word: "I",
  },
  {
    start: 9.9,
    stop: 10.02,
    delay: 0.4,
    word: "would've",
  },
  {
    start: 10.07,
    stop: 10.29,
    delay: 0.1,
    word: "loved",
  },
  {
    start: 10.3,
    stop: 10.38,
    delay: 0,
    word: "to",
  },
  {
    start: 10.48,
    stop: 10.64,
    delay: 0.1,
    word: "work",
  },
  {
    start: 10.71,
    stop: 10.79,
    delay: 0.1,
    word: "on",
  },

  {
    start: 12.01,
    stop: 12.06,
    delay: 0.4,
    word: "but,",
  },
  {
    start: 12.12,
    stop: 12.22,
    delay: 0.1,
    word: "at",
  },
  {
    start: 12.26,
    stop: 12.38,
    delay: 0,
    word: "that",
  },
  {
    start: 12.43,
    stop: 12.52,
    delay: 0,
    word: "time,",
  },
  {
    start: 12.6,
    stop: 12.69,
    delay: 0.1,
    word: "I",
  },
  {
    start: 12.7,
    stop: 12.76,
    delay: 0,
    word: "was",
  },
  {
    start: 12.78,
    stop: 12.86,
    delay: 0,
    word: "at",
  },
  {
    start: 12.9,
    stop: 13.17,
    delay: 0.1,
    word: "Ubisoft",
  },
  {
    start: 13.26,
    stop: 13.36,
    delay: 0.1,
    word: "for",
  },
  {
    start: 14.09,
    stop: 14.17,
    delay: 0.7,
    word: "close",
  },
  {
    start: 14.22,
    stop: 14.31,
    delay: 0.1,
    word: "to",
  },
  {
    start: 14.4,
    stop: 14.56,
    delay: 0.1,
    word: "five",
  },
  {
    start: 14.66,
    stop: 14.94,
    delay: 0.1,
    word: "years,",
  },
  {
    start: 14.96,
    stop: 15.09,
    delay: 0,
    word: "I was",
  },
  {
    start: 15.16,
    stop: 15.35,
    delay: 0.1,
    word: "looking",
  },
  {
    start: 15.4,
    stop: 15.49,
    delay: 0.1,
    word: "for",
  },
  {
    start: 15.55,
    stop: 15.79,
    delay: 0.1,
    word: "something",
  },
  {
    start: 15.98,
    stop: 16.12,
    delay: 0.2,
    word: "a little",
  },
  {
    start: 16.25,
    stop: 16.4,
    delay: 0.1,
    word: "bit",
  },
  {
    start: 16.83,
    stop: 17.17,
    delay: 0.4,
    word: "different",
  },
  {
    start: 17.79,
    stop: 17.9,
    delay: 0.6,
    word: "and",
  },
  {
    start: 18.5,
    stop: 18.79,
    delay: 0.6,
    word: "New Zealand",
  },
  {
    start: 18.82,
    stop: 18.92,
    delay: 0,
    word: "was",
  },
  {
    start: 18.94,
    stop: 18.97,
    delay: 0,
    word: "the",
  },
  {
    start: 19.05,
    stop: 19.42,
    delay: 0.1,
    word: "furthest",
  },
  {
    start: 19.53,
    stop: 19.73,
    delay: 0.1,
    word: "away",
  },
  {
    start: 19.84,
    stop: 20.1,
    delay: 0.1,
    word: "place",
  },
  {
    start: 20.14,
    stop: 20.18,
    delay: 0,
    word: "I",
  },
  {
    start: 20.27,
    stop: 20.38,
    delay: 0.1,
    word: "could",
  },
  {
    start: 20.47,
    stop: 20.57,
    delay: 0.1,
    word: "come",
  },
  {
    start: 20.62,
    stop: 20.68,
    delay: 0.1,
    word: "up",
  },
  {
    start: 20.88,
    stop: 21.2,
    delay: 0.2,
    word: "with.",
  },
];

let getCurrentTime = null;

class MikesQuoteVideoOne extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: "initial",
      progress: 0,
    };
  }
  componentDidMount() {
    this.getVideoData();
  }

  componentWillUnmount() {
    clearInterval(getCurrentTime);
    const video = document.getElementById(this.props.videoid);
    video.removeEventListener("loadedmetadata", this.videoLoaded);
  }

  updateTime = () => {
    const video = document.getElementById(this.props.videoid);
    getCurrentTime = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        if (this.state.currentTime === this.state.duration) {
          clearInterval(getCurrentTime);
        }
      }
    }, 100);
  };

  getVideoData = () => {
    const video = document.getElementById(this.props.videoid);
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.videoLoaded);
      return video.duration;
    }
    return null;
  };

  videoLoaded = () => {
    const video = document.getElementById(this.props.videoid);
    if (this.state.duration === "initial") {
      this.setState({
        duration: parseFloat(video.duration.toFixed(1)),
      });
      this.updateTime();
    }
  };

  render() {
    return (
      <div className="quotes" id="mike-quote-one">
        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.videoid)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#B6CAE1"
          endColor="#4179BD"
        />
      </div>
    );
  }
}

export default MikesQuoteVideoOne;
