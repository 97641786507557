import React from "react";
import { withRouter } from "react-router-dom";

var cancel, totalTime, timeoutCurrent, getTotalTime;

class Video extends React.Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      pause: false,
      muted: false,
    };
  }

  componentDidMount() {
    // const video = document.getElementById("video");
    // // console.log(video);
    // let checkVideo = setInterval(() => {
    //   if (video.readyState === 4) {
    //     totalTime = Math.floor(video.duration);
    //     clearInterval(checkVideo);
    //     this.checkTime();
    //   }
    // }, 500);
  }

  componentWillUnmount() {
    clearInterval(getTotalTime);
  }

  checkTime = (val) => {
    const video = document.getElementById("video");
    const nextKey = this.props.nextPath;
    getTotalTime = setInterval(() => {
      totalTime = Math.floor(video.duration);
      if (totalTime) clearInterval(getTotalTime);
    }, 100);

    if (val) video.currentTime = val;

    timeoutCurrent = setInterval(() => {
      this.props.sendTime && this.props.sendTime(video.currentTime);
      this.setState({ currentTime: Math.floor(video.currentTime) });

      let that = this;
      video.onended = function (e) {
        if (!cancel && nextKey) {
          that.autoLoadNext(nextKey);
        }
        console.log("video ended", e);
      };

      if (video.currentTime === video.duration || video.paused) {
        clearInterval(timeoutCurrent);
        this.setState({ pause: true });
      }
    }, 1000);
  };

  autoLoadNext = (newPath) => {
    this.props.history.push(newPath);
  };

  cancelAutoPlay() {
    cancel = true;
  }

  playVideo() {
    // const modal = document.getElementById("modalVideo");
    // modal.style.display = "block";
    // // var title = this.getAttribute("data-title");
    // // var src = this.getAttribute("data-src");
    // var title = this.props.title;
    // var src = this.props.videoSrc;
    // // Modal video wrapper
    // var modalVideoWrapper = document.createElement("div");
    // modalVideoWrapper.classList.add("modal-video__wrapper");
    // modal.appendChild(modalVideoWrapper);
    // // Wapper button && Button close
    // var wrapperBtn = document.createElement("div");
    // wrapperBtn.classList.add("modal-video__wrapper-btn");
    // modalVideoWrapper.appendChild(wrapperBtn);
    // var btnClose = document.createElement("button");
    // btnClose.classList.add("modal-video__btn-close");
    // wrapperBtn.appendChild(btnClose);
    // // Title video
    // // var titleVideo = document.createElement("h4");
    // // var txtTitleVideo = document.createTextNode(title);
    // // titleVideo.appendChild(txtTitleVideo);
    // // titleVideo.classList.add("modal-video__header");
    // // modalVideoWrapper.appendChild(titleVideo);
    // // Modal video content
    // var modalVideoContent = document.createElement("div");
    // modalVideoContent.classList.add("modal-video__content-video");
    // modalVideoWrapper.appendChild(modalVideoContent);
    // // Video
    // var video = document.createElement("video");
    // video.setAttribute("preload", "auto");
    // video.setAttribute("autoplay", "");
    // var srcVideo = document.createElement("source");
    // srcVideo.setAttribute("src", src);
    // srcVideo.setAttribute("type", "video/mp4");
    // var txtVideo = document.createTextNode(
    //   "Your browser does not support HTML5 video."
    // );
    // video.appendChild(srcVideo);
    // video.appendChild(txtVideo);
    // modalVideoContent.appendChild(video);
    // // Video control
    // var videoControl = document.createElement("div");
    // videoControl.classList.add("modal-video__control");
    // // Pause Wrapper
    // var pauseVideoWrapper = document.createElement("div");
    // pauseVideoWrapper.classList.add("control-wrapper");
    // videoControl.appendChild(pauseVideoWrapper);
    // var pauseVideoBtn = document.createElement("button");
    // pauseVideoBtn.classList.add("control-wrapper__btn");
    // pauseVideoBtn.classList.add("control-wrapper__btn--pause");
    // pauseVideoWrapper.appendChild(pauseVideoBtn);
    // // Range Wrapper
    // var rangeVideo = document.createElement("div");
    // rangeVideo.classList.add("range-time");
    // videoControl.appendChild(rangeVideo);
    // var rangeTimeBtn = document.createElement("span");
    // rangeTimeBtn.classList.add("range-time__btn");
    // rangeVideo.appendChild(rangeTimeBtn);
    // // Pause Wrapper
    // var muteVideoWrapper = document.createElement("div");
    // muteVideoWrapper.classList.add("control-wrapper");
    // videoControl.appendChild(muteVideoWrapper);
    // var muteVideoBtn = document.createElement("button");
    // muteVideoBtn.classList.add("control-wrapper__btn");
    // muteVideoBtn.classList.add("control-wrapper__btn--mute");
    // muteVideoWrapper.appendChild(muteVideoBtn);
    // modalVideoContent.appendChild(videoControl);
    // // Close Modal
    // btnClose.addEventListener(
    //   "click",
    //   () => {
    //     modal.style.display = "none";
    //     modal.removeChild(modalVideoWrapper);
    //   },
    //   false
    // );
    // // Pause/Play Video
    // pauseVideoBtn.addEventListener("click", () => {
    //   if (video.paused) {
    //     video.play();
    //     pauseVideoBtn.classList.remove("control-wrapper__btn--play");
    //     pauseVideoBtn.classList.add("control-wrapper__btn--pause");
    //   } else {
    //     video.pause();
    //     pauseVideoBtn.classList.remove("control-wrapper__btn--pause");
    //     pauseVideoBtn.classList.add("control-wrapper__btn--play");
    //   }
    // });
    // // Mute Video
    // muteVideoBtn.addEventListener("click", () => {
    //   if (video.muted) {
    //     video.muted = false;
    //     muteVideoBtn.classList.remove("control-wrapper__btn--muted");
    //     muteVideoBtn.classList.add("control-wrapper__btn--mute");
    //   } else {
    //     video.muted = true;
    //     muteVideoBtn.classList.remove("control-wrapper__btn--mute");
    //     muteVideoBtn.classList.add("control-wrapper__btn--muted");
    //   }
    // });
    // video.addEventListener(
    //   "timeupdate",
    //   () => {
    //     if (video.currentTime <= video.duration) {
    //       let xPos = (video.currentTime / video.duration) * 100;
    //       rangeTimeBtn.style.left = xPos + "%";
    //     }
    //   },
    //   false
    // );
    // rangeVideo.addEventListener(
    //   "click",
    //   (evt) => {
    //     if (evt.offsetX >= 0 && evt.offsetX < evt.target.offsetWidth) {
    //       let xPosClick = (
    //         (evt.offsetX / evt.target.offsetWidth) *
    //         100
    //       ).toFixed();
    //       let currentTime = (xPosClick / 100) * video.duration;
    //       video.currentTime = currentTime;
    //     }
    //   },
    //   false
    // );
  }

  render() {
    return (
      <div className="video-wrap">
        {/* <div className="video-wrap__overlay">
          <button
            className="video-wrap__btn"
            id="videoPlay"
            onClick={(e) => this.playVideo(e)}
          ></button>
        </div> */}
        {/* <video preload="auto" id="video" autoPlay={false} controls={false}>
          <source src={this.props.videoSrc} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video> */}
        <iframe
          src={this.props.youTube}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope;"
          // playsInline
          allowFullScreen
          title={this.props.youTube}
        ></iframe>
      </div>
    );
  }
}

export default withRouter(Video);
