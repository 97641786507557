import React, { Component } from "react";

import QuoteVideo from "../../components/Article/QuoteVideo";

const Timings = [
  {
    start: 0,
    stop: 0.22,
    delay: 0,
    word: "I",
  },
  {
    start: 0.22,
    stop: 0.47,
    delay: 0,
    word: "love",
  },
  // {
  //   start: 0.64,
  //   stop: 0.84,
  //   delay: 0.2,
  //   word: "um"
  // },
  {
    start: 1.59,
    stop: 1.79,
    delay: 1.1,
    word: "being",
  },
  {
    start: 2.17,
    stop: 2.25,
    delay: 0.4,
    word: "a",
  },
  {
    start: 2.36,
    stop: 2.73,
    delay: 0.1,
    word: "real",
  },
  {
    start: 2.97,
    stop: 3.54,
    delay: 0.2,
    word: "person,",
  },
  {
    start: 3.61,
    stop: 3.72,
    delay: 0.1,
    word: "I",
  },
  {
    start: 3.85,
    stop: 4.2,
    delay: 0.1,
    word: "think",
  },
  // {
  //   start: 4.68,
  //   stop: 4.95,
  //   delay: 0.5,
  //   word: "in"
  // },
  {
    start: 5.04,
    stop: 5.43,
    delay: 0.8,
    word: "everyone",
  },
  {
    start: 5.54,
    stop: 5.77,
    delay: 0.1,
    word: "can",
  },
  {
    start: 6.01,
    stop: 6.31,
    delay: 0.2,
    word: "be",
  },
  // {
  //   start: 6.56,
  //   stop: 6.7,
  //   delay: 0.3,
  //   word: "you-know"
  // },
  // {
  //   start: 7.02,
  //   stop: 7.1,
  //   delay: 0.3,
  //   word: "be"
  // },
  {
    start: 7.17,
    stop: 7.76,
    delay: 0.9,
    word: "themselves",
  },
  {
    start: 7.93,
    stop: 8.02,
    delay: 0.2,
    word: "or",
  },
  {
    start: 8.31,
    stop: 8.38,
    delay: 0.3,
    word: "put",
  },
  {
    start: 8.41,
    stop: 8.52,
    delay: 0,
    word: "on",
  },
  {
    start: 8.61,
    stop: 8.8,
    delay: 0.1,
    word: "a",
  },
  {
    start: 9.18,
    stop: 9.5,
    delay: 0.4,
    word: "facade",
  },
  {
    start: 9.59,
    stop: 9.68,
    delay: 0.1,
    word: "or",
  },
  {
    start: 9.78,
    stop: 10.28,
    delay: 0.1,
    word: "character",
  },

  {
    start: 10.56,
    stop: 10.94,
    delay: 0.3,
    word: "online",
  },
  {
    start: 10.94,
    stop: 11.11,
    delay: 0,
    word: "and",
  },
  {
    start: 11.11,
    stop: 11.2,
    delay: 0,
    word: "that's",
  },
  {
    start: 11.29,
    stop: 11.52,
    delay: 0.1,
    word: "fine",
  },
  {
    start: 11.62,
    stop: 11.71,
    delay: 0.1,
    word: "they",
  },

  {
    start: 11.75,
    stop: 11.86,
    delay: 0,
    word: "can",
  },
  {
    start: 11.89,
    stop: 12.04,
    delay: 0,
    word: "do",
  },
  {
    start: 12.14,
    stop: 12.31,
    delay: 0.1,
    word: "them",
  },
  {
    start: 12.33,
    stop: 12.37,
    delay: 0,
    word: "but",
  },
  {
    start: 12.39,
    stop: 12.46,
    delay: 0,
    word: "I",
  },
  {
    start: 12.57,
    stop: 12.96,
    delay: 0.1,
    word: "prefer",
  },
  {
    start: 13.01,
    stop: 13.09,
    delay: 0,
    word: "to",
  },
  {
    start: 13.15,
    stop: 13.45,
    delay: 0.1,
    word: "be",
  },
  {
    start: 13.7,
    stop: 14.24,
    delay: 0.3,
    word: "authentic",
  },
  // {
  //   start: 14.96,
  //   stop: 15.23,
  //   delay: 0.7,
  //   word: "um"
  // },
  {
    start: 15.76,
    stop: 15.86,
    delay: 1.4,
    word: "and",
  },
  {
    start: 15.95,
    stop: 16.27,
    delay: 0.1,
    word: "not",
  },
  {
    start: 16.46,
    stop: 16.64,
    delay: 0.2,
    word: "veer",
  },
  {
    start: 16.65,
    stop: 16.93,
    delay: 0,
    word: "away",
  },
  {
    start: 17.64,
    stop: 17.73,
    delay: 0.7,
    word: "from",
  },
  {
    start: 18.07,
    stop: 18.24,
    delay: 0.3,
    word: "the",
  },
  {
    start: 18.26,
    stop: 18.57,
    delay: 0,
    word: "image",
  },
  {
    start: 18.6,
    stop: 18.91,
    delay: 0,
    word: "of",
  },
  {
    start: 19.12,
    stop: 19.46,
    delay: 0.2,
    word: "someone",
  },
  {
    start: 19.84,
    stop: 20.2,
    delay: 0.4,
    word: "that",
  },
  // {
  //   start: 20.32,
  //   stop: 20.52,
  //   delay: 0.1,
  //   word: "is"
  // },
  // {
  //   start: 21.05,
  //   stop: 21.16,
  //   delay: 0.5,
  //   word: "that"
  // },
  {
    start: 21.22,
    stop: 21.38,
    delay: 1,
    word: "they",
  },
  {
    start: 21.41,
    stop: 21.47,
    delay: 0,
    word: "can",
  },
  {
    start: 21.61,
    stop: 22.01,
    delay: 0.1,
    word: "relate",
  },
  {
    start: 22.13,
    stop: 22.31,
    delay: 0.1,
    word: "to,",
  },
  {
    start: 22.93,
    stop: 23.49,
    delay: 0.6,
    word: "whereas",
  },
  {
    start: 23.69,
    stop: 23.87,
    delay: 0.2,
    word: "someone",
  },
  {
    start: 24.0,
    stop: 24.32,
    delay: 0.1,
    word: "who is",
  },
  {
    start: 25.01,
    stop: 25.38,
    delay: 0.7,
    word: "positive",
  },
  {
    start: 25.45,
    stop: 25.56,
    delay: 0.1,
    word: "all",
  },
  {
    start: 25.61,
    stop: 25.68,
    delay: 0.1,
    word: "the",
  },
  {
    start: 25.71,
    stop: 26.02,
    delay: 0,
    word: "time",
  },
  {
    start: 26.4,
    stop: 26.49,
    delay: 0.4,
    word: "on",
  },
  {
    start: 26.59,
    stop: 26.66,
    delay: 0.1,
    word: "their",
  },
  {
    start: 26.8,
    stop: 27.41,
    delay: 0.1,
    word: "social media",
  },
  {
    start: 27.65,
    stop: 27.81,
    delay: 0.2,
    word: "or",
  },
  {
    start: 27.89,
    stop: 28.07,
    delay: 0.1,
    word: "on",
  },
  {
    start: 28.19,
    stop: 28.44,
    delay: 0.1,
    word: "Twitch",
  },
  {
    start: 28.48,
    stop: 28.58,
    delay: 0,
    word: "or",
  },
  {
    start: 28.83,
    stop: 29.28,
    delay: 0.3,
    word: "YouTube,",
  },
  // {
  //   start: 29.8,
  //   stop: 30.04,
  //   delay: 0.5,
  //   word: "um"
  // },
  {
    start: 30.43,
    stop: 30.47,
    delay: 0.8,
    word: "it",
  },
  {
    start: 30.65,
    stop: 30.66,
    delay: 0.2,
    word: "can",
  },
  {
    start: 30.88,
    stop: 31.04,
    delay: 0.2,
    word: "kind",
  },
  {
    start: 31.11,
    stop: 31.23,
    delay: 0.1,
    word: "of,",
  },
  {
    start: 31.97,
    stop: 32.21,
    delay: 0.7,
    word: "especially",
  },
  {
    start: 32.32,
    stop: 32.36,
    delay: 0.1,
    word: "to",
  },
  {
    start: 32.53,
    stop: 32.59,
    delay: 0.2,
    word: "some",
  },
  {
    start: 32.78,
    stop: 33.11,
    delay: 0.2,
    word: "vulnerable",
  },
  {
    start: 33.19,
    stop: 33.47,
    delay: 0.1,
    word: "people,",
  },
  {
    start: 33.55,
    stop: 33.66,
    delay: 0.1,
    word: "can",
  },
  {
    start: 33.74,
    stop: 33.82,
    delay: 0.1,
    word: "give",
  },
  {
    start: 33.86,
    stop: 34.03,
    delay: 0,
    word: "off",
  },
  {
    start: 34.18,
    stop: 34.27,
    delay: 0.1,
    word: "like",
  },
  {
    start: 34.33,
    stop: 34.4,
    delay: 0.1,
    word: "a",
  },
  {
    start: 34.52,
    stop: 34.77,
    delay: 0.1,
    word: "fake",
  },
  {
    start: 34.84,
    stop: 35.08,
    delay: 0.1,
    word: "image",
  },
  {
    start: 35.31,
    stop: 35.38,
    delay: 0.2,
    word: "of",
  },
  {
    start: 36.07,
    stop: 36.42,
    delay: 0.7,
    word: "life",
  },
  {
    start: 36.63,
    stop: 36.71,
    delay: 0.2,
    word: "in",
  },
  {
    start: 36.77,
    stop: 37.2,
    delay: 0.06,
    word: "itself,",
  },
  {
    start: 37.35,
    stop: 37.56,
    delay: 0.15,
    word: "so",
  },
  {
    start: 37.74,
    stop: 37.84,
    delay: 0.2,
    word: "I",
  },
  {
    start: 37.94,
    stop: 37.99,
    delay: 0.1,
    word: "like",
  },
  {
    start: 38.04,
    stop: 38.1,
    delay: 0,
    word: "to",
  },
  {
    start: 38.24,
    stop: 38.42,
    delay: 0.1,
    word: "share",
  },
  {
    start: 38.5,
    stop: 38.71,
    delay: 0.1,
    word: "my",
  },
  {
    start: 39.35,
    stop: 39.74,
    delay: 0.6,
    word: "sorrows",
  },
  {
    start: 39.86,
    stop: 39.96,
    delay: 0.1,
    word: "with",
  },
  {
    start: 40.07,
    stop: 40.33,
    delay: 0.1,
    word: "them,",
  },
  // {
  //   start: 40.65,
  //   stop: 40.88,
  //   delay: 0.3,
  //   word: "um"
  // },
  {
    start: 41.26,
    stop: 41.35,
    delay: 1,
    word: "not",
  },
  {
    start: 41.54,
    stop: 41.75,
    delay: 0.2,
    word: "to",
  },
  {
    start: 42.02,
    stop: 42.32,
    delay: 0.3,
    word: "dive",
  },
  {
    start: 42.64,
    stop: 42.9,
    delay: 0.3,
    word: "too",
  },
  {
    start: 43.33,
    stop: 43.56,
    delay: 0.4,
    word: "deep",
  },
  {
    start: 43.74,
    stop: 43.81,
    delay: 0.2,
    word: "inside",
  },
  {
    start: 43.93,
    stop: 44.04,
    delay: 0.1,
    word: "the",
  },
  {
    start: 44.18,
    stop: 44.42,
    delay: 0.1,
    word: "negative",
  },
  {
    start: 44.62,
    stop: 44.91,
    delay: 0.2,
    word: "stuff",
  },
  {
    start: 45.04,
    stop: 45.24,
    delay: 0.1,
    word: "but",
  },
  {
    start: 45.73,
    stop: 45.79,
    delay: 0.5,
    word: "I",
  },
  {
    start: 45.92,
    stop: 45.99,
    delay: 0.1,
    word: "do",
  },
  {
    start: 46.03,
    stop: 46.19,
    delay: 0,
    word: "like",
  },
  {
    start: 46.22,
    stop: 46.32,
    delay: 0,
    word: "to",
  },
  {
    start: 46.38,
    stop: 46.48,
    delay: 0.1,
    word: "just",
  },
  {
    start: 46.6,
    stop: 46.77,
    delay: 0.1,
    word: "say",
  },
  {
    start: 46.85,
    stop: 46.97,
    delay: 0.1,
    word: " 'Hey",
  },
  {
    start: 47.08,
    stop: 47.16,
    delay: 0.1,
    word: "I",
  },
  {
    start: 47.27,
    stop: 47.36,
    delay: 0.1,
    word: "go",
  },
  {
    start: 47.48,
    stop: 47.76,
    delay: 0.1,
    word: "through",
  },
  {
    start: 47.9,
    stop: 48.04,
    delay: 0.1,
    word: "stuff",
  },
  {
    start: 48.27,
    stop: 48.54,
    delay: 0.2,
    word: "too'.",
  },
  {
    start: 49.0,
    stop: 49.1,
    delay: 0.5,
    word: " ",
  },
];

let getCurrentTime = null;

class LoriiQuoteVideoTwo extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: 0,
      progress: 0,
    };
  }
  componentDidMount() {
    this.getVideoData();
  }

  componentWillUnmount() {
    clearInterval(getCurrentTime);
    const video = document.getElementById(this.props.videoId);
    if (video) {
      video.removeEventListener("loadedmetadata", this.getDuration);
    }
  }

  updateTime = () => {
    const video = document.getElementById(this.props.videoId);
    getCurrentTime = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        // const timeline = this.timeline.getGSAP();
        // console.log(timeline._timeline);
      }
    }, 50);
  };

  getVideoData = () => {
    const video = document.getElementById(this.props.videoId);
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.getDuration);
      return video.duration;
    }
    return null;
  };

  getDuration = () => {
    const video = document.getElementById(this.props.videoId);
    if (video) {
      this.setState({
        duration: parseFloat(video.duration.toFixed(1)),
      });
      this.updateTime();
    }
  };

  render() {
    return (
      <div className="quotes" id="quote-words-two">
        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.videoID)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#CDB4B7"
          endColor="#EA0521"
        />
      </div>
    );
  }
}

export default LoriiQuoteVideoTwo;
