export const checkPosition = () => {
  const elems = document.querySelectorAll(".item");
  let windowHeight = window.innerHeight;
  window.addEventListener("resize", () => {
    windowHeight = window.innerHeight;
  });
  window.addEventListener("scroll", () => {
    for (let i = 0; i < elems.length; i++) {
      const topEdge = elems[i].getBoundingClientRect().top;
      const bottomEdge = elems[i].getBoundingClientRect().bottom;
      const triggerLine = 160;
      if (topEdge - windowHeight <= -triggerLine) {
        elems[i].classList.add("triggered");
      }
      if (topEdge - windowHeight >= -triggerLine || bottomEdge <= triggerLine) {
        elems[i].classList.remove("triggered");
      }
    }
  });
};

// Disable and enable scroll

var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36

const preventDefault = (e) => {
  e = e || window.event;
  if (e.preventDefault) e.preventDefault();
  e.returnValue = false;
};

const preventDefaultForScrollKeys = (e) => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};

export const disableScroll = () => {
  console.log("disable scroll");
  if (window.addEventListener)
    // older FF
    window.addEventListener("DOMMouseScroll", preventDefault, false);
  document.addEventListener("wheel", preventDefault, { passive: false }); // Disable scrolling in Chrome
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove = preventDefault; // mobile
  document.body.classList.add("noscroll");
  document.onkeydown = preventDefaultForScrollKeys;
};

export const enableScroll = () => {
  console.log("enable scroll");
  if (window.removeEventListener)
    window.removeEventListener("DOMMouseScroll", preventDefault, false);
  document.removeEventListener("wheel", preventDefault, { passive: false }); // Enable scrolling in Chrome
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.body.classList.remove("noscroll");
  document.onkeydown = null;
};
