import React from "react";

class SlideGroup extends React.Component {
  render() {
    return (
      <div className={`slide-group ${this.props.groupName}`}>
        {this.props.children}
      </div>
    );
  }
}

export default SlideGroup;
