import React, { Component } from "react";

class Facebook extends Component {
  render() {
    return (
      <svg
        width="34px"
        height="34px"
        viewBox="0 0 34 34"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="exports"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="icon_fb"
            transform="translate(-939.000000, -342.000000)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <path
              d="M957.59375,376 L957.59375,362.566964 L952.964286,362.566964 L952.964286,357.40625 L957.59375,357.40625 L957.59375,353.611607 C957.59375,351.486607 958.188244,349.829613 959.377232,348.640625 C960.566221,347.451637 962.172619,346.857143 964.196429,346.857143 C965.764881,346.857143 967.080357,346.933036 968.142857,347.084821 L968.142857,351.638393 L965.410714,351.638393 C964.449404,351.638393 963.766369,351.866071 963.361607,352.321429 C963.058036,352.72619 962.90625,353.333333 962.90625,354.142857 L962.90625,357.40625 L968.142857,357.40625 L967.459821,362.566964 L962.90625,362.566964 L962.90625,376 L969.357143,376 C970.369047,376 971.229167,375.645833 971.9375,374.9375 C972.645833,374.229167 973,373.369047 973,372.357143 L973,345.642857 C973,344.630953 972.645833,343.770833 971.9375,343.0625 C971.229167,342.354167 970.369047,342 969.357143,342 L942.642857,342 C941.630953,342 940.770833,342.354167 940.0625,343.0625 C939.354167,343.770833 939,344.630953 939,345.642857 L939,372.357143 C939,373.369047 939.354167,374.229167 940.0625,374.9375 C940.770833,375.645833 941.630953,376 942.642857,376 L957.59375,376 Z"
              id="icon_facebook"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
}

export default Facebook;
