import React, { Component } from "react";
import { connect } from "react-redux";
import { Controller, Scene } from "react-scrollmagic";
import LazyLoad from "react-lazyload";

import { fetchPosts } from "../../state/actions";
import { getArticleData } from "../../state/selectors";
import { GF_ARTICLE_PAGEID } from "../../api/api";
import Loading from "../Story/Loading";
import TwitchHistory from "./TwitchHistory";
import LoriiQuoteVideo from "./LoriiQuoteVideo";
import LoriiQuoteVideoTwo from "./LoriiQuoteVideoTwo";

import { loriiQuote1, loriiQuote2 } from "../../utils/fileManager";

import ImageColumns from "../Article/ImageColumns";
import ImageFull from "../Article/ImageFull";

import "../App/ArticleSplit.scss";
import "./GameFaceSplit.scss";

import { imagePaths } from "../../utils/mediaManager";

const ScrollMagic = window.ScrollMagic;

const quoteTwoVideoID = "lorii-quote-two";
const quoteOneVideoID = "lorii-quote-one";
const quoteOneVideoSrc = loriiQuote1();
const quoteTwoVideoSrc = loriiQuote2();

//images
// const communityBoard = imagePaths("gameface", "community_board.jpg");
const communityPlaying = imagePaths("gameface", "community_playing.jpg");
const communitySmile1 = imagePaths("gameface", "community_smile_1.jpg");
const communitySmile2 = imagePaths("gameface", "community_smile_2.jpg");
const followers1 = imagePaths("gameface", "followers_1.jpg");
const followers2 = imagePaths("gameface", "followers_2.jpg");
const followersTattoo = imagePaths("gameface", "followers_tattoo.jpg");
const introLeon = imagePaths("gameface", "intro_leon.jpg");
const introScrolling = imagePaths("gameface", "intro_scrolling.jpg");
const introWalking1 = imagePaths("gameface", "intro_walking_1.jpg");
const introWalking2 = imagePaths("gameface", "intro_walking_2.jpg");
const playingPixel = imagePaths("gameface", "playing_pixel.jpg");
const playingSmile = imagePaths("gameface", "playing_smile.jpg");
const playingStuff = imagePaths("gameface", "playing_stuff.jpg");
const portrait = imagePaths("gameface", "portrait.jpg");

class GameFaceArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      video1muted: false,
      video2muted: false,
    };

    this.article = null;
  }

  componentDidMount() {
    this.props.fetchData();
  }

  video1MuteToggle = () => {
    const video1 = document.getElementById(quoteOneVideoID);
    video1.muted = !this.state.video1muted;
    this.setState({ video1muted: !this.state.video1muted });
  };

  video2MuteToggle = () => {
    const video2 = document.getElementById(quoteTwoVideoID);
    video2.muted = !this.state.video2muted;
    this.setState({ video2muted: !this.state.video2muted });
  };

  render() {
    const { article } = this.props;
    let layouts = "";

    if (article) {
      layouts = article.acf.article_components;
    }

    let articleOutput = "";
    if (layouts) {
      // Loop through acf and display all layouts.
      articleOutput = layouts.map((layout, i) => {
        if (layout.acf_fc_layout === "text_block") {
          const text = layout.text_block;
          const blockClass = layout.blockClass;
          let output = null;

          if (blockClass) {
            if (blockClass === "twitch") {
              output = (
                <React.Fragment key={i}>
                  <div className={`block-container ${blockClass}`} key={i}>
                    <div
                      className="text-block"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  </div>
                  <TwitchHistory />
                </React.Fragment>
              );
            } else {
              output = (
                <div className={`block-container ${blockClass}`} key={i}>
                  <div
                    className="text-block"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </div>
              );
            }
          } else {
            output = (
              <div className={`block-container`} key={i}>
                <div
                  className="text-block"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
            );
          }
          return output;
        } else if (layout.acf_fc_layout === "image_block") {
          const imageGroup = layout.image_group;
          if (imageGroup === "intro") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageColumns
                  leftCol={[
                    { src: introLeon, size: "tall", imgId: "introLeon" },
                    {
                      src: introWalking2,
                      size: "square",
                      imgId: "introWalking2",
                    },
                  ]}
                  leftClasses=""
                  rightCol={[
                    {
                      src: introScrolling,
                      size: "tall",
                      imgId: "introScrolling",
                    },
                  ]}
                  rightClasses=" align-end"
                />
                <ImageFull
                  src={introWalking1}
                  size="wide"
                  imgId="introWalking1"
                />
              </div>
            );
          } else if (imageGroup === "followers") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageFull src={followers2} size="wide" imgId="followers2" />
                <ImageColumns
                  leftCol={[
                    { src: followers1, size: "square", imgId: "followers1" },
                  ]}
                  leftClasses=""
                  rightCol={[
                    {
                      src: followersTattoo,
                      size: "tall",
                      imgId: "followersTattoo",
                    },
                  ]}
                  rightClasses=""
                />
              </div>
            );
          } else if (imageGroup === "community") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageColumns
                  leftClasses=""
                  rightCol={[
                    {
                      src: communitySmile2,
                      size: "square",
                      imgId: "communitySmile2",
                    },
                    //{ src: communityBoard }
                  ]}
                  rightClasses=""
                />
                <ImageFull
                  src={communityPlaying}
                  size="wide"
                  imgId="communityPlaying"
                />
                <ImageColumns
                  leftClasses=""
                  leftCol={[
                    {
                      src: communitySmile1,
                      size: "tall",
                      imgId: "communitySmile1",
                    },
                  ]}
                  rightClasses=""
                />
              </div>
            );
          } else if (imageGroup === "playing") {
            return (
              <div className="block-container image-block" key={i}>
                <ImageColumns
                  leftCol={[
                    {
                      src: playingPixel,
                      size: "square",
                      imgId: "playingPixel",
                    },
                  ]}
                  leftClasses=" align-end"
                  rightCol={[
                    { src: playingStuff, size: "tall", imgId: "playingStuff" },
                  ]}
                  rightClasses=""
                />
                <ImageFull
                  src={playingSmile}
                  size="wide"
                  imgId="playingSmile"
                />
              </div>
            );
          }
          // else if (imageGroup === "portrait") {
          //   return (
          //     <div className="block-container image-block" key={i}>
          //       <ImageFull src={portrait} size="wide" imgId="portrait" />
          //     </div>
          //   );
          // }
        } else if (layout.acf_fc_layout === "video") {
          const videoID = layout.videoID;
          if (videoID === "lorien") {
            return (
              <Controller key={i}>
                <Scene
                  duration={"150%"}
                  triggerHook="0.75"
                  // indicators={true}
                >
                  {(progress, event) => {
                    // console.log(event, progress);

                    const video1 = document.getElementById(quoteOneVideoID);
                    const wrap1 = document.getElementById("wrap1");
                    if (event.type === "enter") {
                      this.props.controlLoopTrack();
                      if (wrap1) wrap1.classList.add("active");
                      if (video1) {
                        var promise = video1.play();
                        if (promise !== undefined) {
                          promise
                            .then((_) => {
                              // Autoplay started!
                              console.log("autoplay", video1.muted);
                            })
                            .catch((error) => {
                              video1.muted = true;
                              video1.play();
                              this.setState({ video1muted: true });
                            });
                        }
                      }
                    } else if (event.type === "leave") {
                      this.props.controlLoopTrack();
                      if (wrap1) {
                        wrap1.classList.remove("active");
                        wrap1.classList.add("current");
                      }
                      if (video1) {
                        video1.pause();
                      }
                    }
                    return (
                      <div className="quote-wrap" key={i}>
                        <div className="inner">
                          <LazyLoad
                            offset={500}
                            placeholder={<div className="placeholder"></div>}
                          >
                            <div className="video-wrap current" id="wrap1">
                              <video
                                id={quoteOneVideoID}
                                className="video-quote"
                                src={quoteOneVideoSrc}
                                controls={false}
                                playsInline
                              />
                              <div
                                className={`muteToggle ${this.state.video1muted}`}
                                onClick={this.video1MuteToggle}
                              >
                                {this.state.video1muted &&
                                this.state.video1muted
                                  ? "Unmute"
                                  : "Mute"}
                              </div>
                            </div>
                            <LoriiQuoteVideo videoId={quoteOneVideoID} />
                          </LazyLoad>
                        </div>
                      </div>
                    );
                  }}
                </Scene>
              </Controller>
            );
          } else if (videoID === "feelings") {
            return (
              <Controller key={i}>
                <Scene
                  duration={"200%"}
                  triggerHook="0.75"
                  // indicators={true}
                >
                  {(progress, event) => {
                    // console.log(event, progress);

                    const video2 = document.getElementById(quoteTwoVideoID);
                    const wrap2 = document.getElementById("wrap2");
                    if (event.type === "enter") {
                      this.props.controlLoopTrack();
                      if (wrap2) wrap2.classList.add("active");
                      if (video2) {
                        var promise = video2.play();
                        if (promise !== undefined) {
                          promise
                            .then((_) => {
                              // Autoplay started!
                              console.log("autoplay");
                            })
                            .catch((error) => {
                              video2.muted = true;
                              video2.play();
                              this.setState({ video2muted: true });
                            });
                        }
                      }
                    } else if (event.type === "leave") {
                      this.props.controlLoopTrack();
                      if (wrap2) {
                        wrap2.classList.remove("active");
                        wrap2.classList.add("current");
                      }
                      if (video2) video2.pause();
                    }
                    return (
                      <div className="quote-wrap" key={i}>
                        <div className="inner">
                          <LazyLoad
                            offset={500}
                            placeholder={<div className="placeholder"></div>}
                          >
                            <div className="video-wrap" id="wrap2">
                              <video
                                id={quoteTwoVideoID}
                                className="video-quote"
                                src={quoteTwoVideoSrc}
                                controls={false}
                                playsInline
                              />
                              <div
                                className={`muteToggle ${this.state.video2muted}`}
                                onClick={this.video2MuteToggle}
                              >
                                {this.state.video2muted &&
                                this.state.video2muted
                                  ? "Unmute"
                                  : "Mute"}
                              </div>
                            </div>
                            <LoriiQuoteVideoTwo videoId={quoteTwoVideoID} />
                          </LazyLoad>
                        </div>
                      </div>
                    );
                  }}
                </Scene>
              </Controller>
            );
          }
        }
        return null;
      });

      return (
        <div
          className="game-face"
          id="game-face-article"
          ref={(ref) => {
            this.article = ref;
          }}
        >
          <div className="article-main">
            <div className="article-wrap" id="article">
              <div className="title">
                <h2>Making a living playing games eight hours a day</h2>
                {/* <div className="read-duration">5 MIN READ</div>
                <div className="intro">
                  A look into a day in the life of Lorien Gugich, who juggles
                  her mum-life with 8 hours of live-streamed gaming to the world
                </div> */}
              </div>
              {/* <div id="game-intro">
              <GameIntro
                loop={gameFaceLoop}
                prompt="Use the controller to help Loriipops get through her day"
                story="gameFace"
              />
            </div> */}

              {articleOutput}
              <div className="block-container youtube-video">
                <div className="text-block">
                  <div className="video-wrap">
                    <iframe
                      src="https://www.youtube.com/embed/o48YG-290XI"
                      frameBorder="0"
                      allow="accelerometer; encrypted-media; gyroscope;"
                      // playsInline
                      allowFullScreen
                      title="https://www.youtube.com/embed/o48YG-290XI"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="error">
        <Loading />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  article: getArticleData(state, GF_ARTICLE_PAGEID),
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => {
    dispatch(fetchPosts());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GameFaceArticle);
