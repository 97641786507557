import React from "react";
import { Link } from "react-router-dom";

import ChooseCharacter from "./ChooseCharacter";
import Mike from "../../assets/mike.png";
import Lorii from "../../assets/lorii.png";
import Humphrey from "../../assets/humphrey.png";

import "./Menu.scss";

class Menu extends React.Component {
  componentDidMount() {}

  closeMenu = () => {
    this.props.menuToggle();
  };

  render() {
    const { active } = this.props;
    return (
      <div
        aria-hidden={!active}
        className={"menu" + (active ? " open" : "")}
        id="menu"
        ref={(e) => (this.menuRootElement = e)}
      >
        <div className="m-inner">
          <div className="menu-close" onClick={this.closeMenu}>
            Close
          </div>
          <div className="menu-content">
            <nav className="stories">
              <ChooseCharacter text="Choose your character" />
              <ul>
                <li>
                  <div className="count">01</div>
                  <div className="story">
                    <img className="face" alt="" src={Mike} />
                    <div className="overlay">
                      <Link
                        className="lead-link"
                        onClick={this.closeMenu}
                        to="/gameplan"
                      >
                        Game Plan
                      </Link>
                      <div className="sub-links">
                        <Link onClick={this.closeMenu} to="/gameplan/game">
                          Game
                        </Link>
                        <Link onClick={this.closeMenu} to="/gameplan">
                          Article
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="count">02</div>
                  <div className="story">
                    <img className="face" alt="" src={Lorii} />
                    <div className="overlay">
                      <Link
                        className="lead-link"
                        onClick={this.closeMenu}
                        to="/gameface"
                      >
                        Game Face
                      </Link>
                      <div className="sub-links">
                        <Link onClick={this.closeMenu} to="/gameface/game">
                          Game
                        </Link>
                        <Link onClick={this.closeMenu} to="/gameface">
                          Article
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="count">03</div>
                  <div className="story">
                    <img className="face" alt="" src={Humphrey} />
                    <div className="overlay">
                      <Link
                        className="lead-link"
                        onClick={this.closeMenu}
                        to="/gameon"
                      >
                        Game On
                      </Link>
                      <div className="sub-links">
                        <Link onClick={this.closeMenu} to="/gameon">
                          Article
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
            <nav className="supporting">
              <ul>
                <li>
                  <Link onClick={this.closeMenu} to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link onClick={this.closeMenu} to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link onClick={this.closeMenu} to="/videos">
                    Videos
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
