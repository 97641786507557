import { s3url } from "./constants";

// Game On Video
const localGameOnVid = process.env.PUBLIC_URL + "/assets/s3/gameon/gameon.mp4";
const s3GameOnVid = s3url + "/gameon/gameon.mp4";

// Game On Audio
const localHumphreySound1 =
  process.env.PUBLIC_URL + "/assets/s3/gameon/humphrey.mp3";
const s3HumphreySound1 = s3url + "/gameon/humphrey.mp3";

const localHumphreySound2 =
  process.env.PUBLIC_URL + "/assets/s3/gameon/humphrey2.mp3";
const s3HumphreySound2 = s3url + "/gameon/humphrey2.mp3";

const localHumphreySound3 =
  process.env.PUBLIC_URL + "/assets/s3/gameon/humphrey3.mp3";
const s3HumphreySound3 = s3url + "/gameon/humphrey3.mp3";

const localHumphreySound4 =
  process.env.PUBLIC_URL + "/assets/s3/gameon/humphrey4.mp3";
const s3HumphreySound4 = s3url + "/gameon/humphrey4.mp3";

// Gameplan videos
const localministerQuote =
  process.env.PUBLIC_URL + "/assets/s3/gameplan/minister.mp4";
const s3ministerQuote = s3url + "/gameplan/minister.mp4";

const localMike1 = process.env.PUBLIC_URL + "/assets/s3/gameplan/Mike1.mp4";
const s3Mike1 = s3url + "/gameplan/Mike1.mp4";

const localMike2 = process.env.PUBLIC_URL + "/assets/s3/gameplan/Mike2.mp4";
const s3Mike2 = s3url + "/gameplan/Mike2.mp4";

const localCassandra1 =
  process.env.PUBLIC_URL + "/assets/s3/gameplan/Cassandra1.mp4";
const s3Cassandra1 = s3url + "/gameplan/Cassandra1.mp4";

const localCassandra2 =
  process.env.PUBLIC_URL + "/assets/s3/gameplan/Cassandra2.mp4";
const s3Cassandra2 = s3url + "/gameplan/Cassandra2.mp4";

const localGamePlanLoop =
  process.env.PUBLIC_URL + "/assets/s3/gameplan/GamePlanLoop.mp4";
const s3GamePlanLoop = s3url + "/gameplan/GamePlanLoop.mp4";

// gameface videos
const localLoriiQuote2 =
  process.env.PUBLIC_URL + "/assets/s3/gameface/LoriiQuote2.mp4";
const s3LoriiQuote2 = s3url + "/gameface/LoriiQuote2.mp4";

const localGameFacePart1 =
  process.env.PUBLIC_URL + "/assets/s3/gameface/part1.mp4";
const s3GameFacePart1 = s3url + "/gameface/part1.mp4";

const localGameFacePart2 =
  process.env.PUBLIC_URL + "/assets/s3/gameface/part2.mp4";
const s3GameFacePart2 = s3url + "/gameface/part2.mp4";

const localGameFaceMain =
  process.env.PUBLIC_URL + "/assets/s3/gameface/GameFaceMain.mp4";
const s3GameFaceMain = s3url + "/gameface/GameFaceMain.mp4";

const localGameFaceLoop =
  process.env.PUBLIC_URL + "/assets/s3/gameface/GameFaceLoop.mp4";
const s3GameFaceLoop = s3url + "/gameface/GameFaceLoop.mp4";

// Game Plan Scenes

const localLeonPath = process.env.PUBLIC_URL + "/assets/s3/gameface/leon/";
const s3LeonPath = s3url + "/gameface/leon/";

const localDucksPath = process.env.PUBLIC_URL + "/assets/s3/gameface/ducks/";
const s3DucksPath = s3url + "/gameface/ducks/";

const localMakeupPath = process.env.PUBLIC_URL + "/assets/s3/gameface/makeup/";
const s3MakeupPath = s3url + "/gameface/makeup/";

const localGeneralPath =
  process.env.PUBLIC_URL + "/assets/s3/gameface/audio_general/";
const s3GeneralPath = s3url + "/gameface/audio_general/";

const localFaceSliderLorii =
  process.env.PUBLIC_URL + "/assets/s3/gameface/faceSliderLorii.mp4";
const s3FaceSliderLorii = s3url + "/gameface/faceSliderLorii.mp4";

const localFaceSliderLoriiAnimated =
  process.env.PUBLIC_URL + "/assets/s3/gameface/faceSliderLoriiAnimated.mp4";
const s3FaceSliderLoriiAnimated =
  s3url + "/gameface/faceSliderLoriiAnimated.mp4";

const localFaceSliderMike =
  process.env.PUBLIC_URL + "/assets/s3/gameplan/faceSliderMike.mp4";
const s3FaceSliderMike = s3url + "/gameplan/faceSliderMike.mp4";

const localFaceSliderHumphrey =
  process.env.PUBLIC_URL + "/assets/s3/gameon/faceSliderHumphrey.mp4";
const s3FaceSliderHumphrey = s3url + "/gameon/faceSliderHumphrey.mp4";

const localLoriiQuote1 =
  process.env.PUBLIC_URL + "/assets/s3/gameface/LoriiQuote1.mp4";
const s3LoriiQuote1 = s3url + "/gameface/LoriiQuote1.mp4";

const env = process.env.NODE_ENV;

export const FaceSliderLorii = () => {
  return env === "development" ? localFaceSliderLorii : s3FaceSliderLorii;
};

export const FaceSliderLoriiAnimated = () => {
  return env === "development"
    ? localFaceSliderLoriiAnimated
    : s3FaceSliderLoriiAnimated;
};

export const gameOnVid = () => {
  return env === "development" ? localGameOnVid : s3GameOnVid;
};

// Game on audio
export const HumphreySound1 = () => {
  return env === "development" ? localHumphreySound1 : s3HumphreySound1;
};

export const HumphreySound2 = () => {
  return env === "development" ? localHumphreySound2 : s3HumphreySound2;
};

export const HumphreySound3 = () => {
  return env === "development" ? localHumphreySound3 : s3HumphreySound3;
};

export const HumphreySound4 = () => {
  return env === "development" ? localHumphreySound4 : s3HumphreySound4;
};

// GAME PLAN

export const ministerQuote = () => {
  return env === "development" ? localministerQuote : s3ministerQuote;
};

export const MikeQuote1 = () => {
  return env === "development" ? localMike1 : s3Mike1;
};

export const MikeQuote2 = () => {
  return env === "development" ? localMike2 : s3Mike2;
};

export const Cassandra1 = () => {
  return env === "development" ? localCassandra1 : s3Cassandra1;
};

export const Cassandra2 = () => {
  return env === "development" ? localCassandra2 : s3Cassandra2;
};

export const gamePlanLoop = () => {
  return env === "development" ? localGamePlanLoop : s3GamePlanLoop;
};

// Game Face Interactive videos
export const gameFacePart1 = () => {
  return env === "development" ? localGameFacePart1 : s3GameFacePart1;
};
export const gameFacePart2 = () => {
  return env === "development" ? localGameFacePart2 : s3GameFacePart2;
};

export const gameFaceMain = () => {
  return env === "development" ? localGameFaceMain : s3GameFaceMain;
};

export const gameFaceLoop = () => {
  return env === "development" ? localGameFaceLoop : s3GameFaceLoop;
};

// Game Face spines
export const leonAssetPaths = file => {
  return env === "development"
    ? `${localLeonPath}${file}`
    : `${s3LeonPath}${file}`;
};

export const ducksAssetPaths = file => {
  return env === "development"
    ? `${localDucksPath}${file}`
    : `${s3DucksPath}${file}`;
};

export const makeupAssetPaths = file => {
  return env === "development"
    ? `${localMakeupPath}${file}`
    : `${s3MakeupPath}${file}`;
};

export const generalAssetPaths = file => {
  return env === "development"
    ? `${localGeneralPath}${file}`
    : `${s3GeneralPath}${file}`;
};

export const faceSliderMikeReal = () => {
  return env === "development" ? localFaceSliderMike : s3FaceSliderMike;
};

export const faceSliderHumphreyReal = () => {
  return env === "development" ? localFaceSliderHumphrey : s3FaceSliderHumphrey;
};

export const loriiQuote1 = () => {
  return env === "development" ? localLoriiQuote1 : s3LoriiQuote1;
};

export const loriiQuote2 = () => {
  return env === "development" ? localLoriiQuote2 : s3LoriiQuote2;
};
