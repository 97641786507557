const MakeupGame = (
  parent,
  app,
  Spine,
  makeupScene,
  resources,
  videoWidth,
  videoHeight,
  scale,
  video,
  getCurrentTime,
  canvasWrap,
  startGame,
  videoPartEnd,
  startPartFour,
  positionX,
  positionY,
  screenWidth,
  audio
) => {
  resources = resources.resources;
  const bg = new Spine(resources.bgMakeup.spineData);
  const hearts = new Spine(resources.hearts.spineData);
  const lipstick = new Spine(resources.lipstick.spineData);
  const lorii = new Spine(resources.lorii.spineData);
  const mouthShapes = new Spine(resources.mouthShapes.spineData);
  const smudge01 = new Spine(resources.smudge01.spineData);
  const smudge02 = new Spine(resources.smudge02.spineData);
  const smudge03 = new Spine(resources.smudge03.spineData);
  const smudge04 = new Spine(resources.smudge04.spineData);
  const smudge05 = new Spine(resources.smudge05.spineData);
  const smudge06 = new Spine(resources.smudge06.spineData);
  const smudge07 = new Spine(resources.smudge07.spineData);
  const smudge08 = new Spine(resources.smudge08.spineData);
  const smudge09 = new Spine(resources.smudge09.spineData);
  const smudge10 = new Spine(resources.smudge10.spineData);
  const smudge11 = new Spine(resources.smudge11.spineData);
  const smudge12 = new Spine(resources.smudge12.spineData);
  const texture = new Spine(resources.textureMakeup.spineData);
  const controller = new Spine(resources.controllerMakeup.spineData);
  const transition = new Spine(resources.transitionMakeup.spineData);

  // const gameLoadInOut = new Audio(resources.gameLoadInOut.url);
  // const gameLoadIn = new Audio(resources.gameLoadIn.url);
  audio.buttonPress.src = resources.buttonPress.url;
  audio.controllerEnter.src = resources.controllerEnter.url;
  audio.winSound.src = resources.winSound.url;
  audio.uhOh1.src = resources.uhOh1.url;
  audio.uhOh2.src = resources.uhOh2.url;
  audio.onSwish.src = resources.onSwish.url;
  audio.cuteLaugh1.src = resources.cuteLaugh1.url;
  audio.cuteLaugh2.src = resources.cuteLaugh2.url;
  audio.makeupTrackLoop.src = resources.makeupTrackLoop.url;
  audio.makeupResultNegative.src = resources.makeupResultNegative.url;

  // Z-index
  makeupScene.sortableChildren = true;
  transition._zIndex = 5;
  controller._zIndex = 5;
  texture._zIndex = 5;
  hearts._zIndex = 4;
  lipstick._zIndex = 3;
  mouthShapes._zIndex = 2;
  lorii._zIndex = -1;
  bg._zIndex = -5;

  // Position
  bg.position.set(videoWidth / 2, videoHeight / 2);
  bg.scale.set(scale, scale);
  hearts.position.set(videoWidth / 2, videoHeight / 2);
  hearts.scale.set(scale, scale);
  lipstick.position.set(videoWidth / 2, videoHeight / 2);
  lipstick.scale.set(scale, scale);
  lorii.position.set(videoWidth / 2, videoHeight / 2);
  lorii.scale.set(scale, scale);
  mouthShapes.position.set(videoWidth / 2, videoHeight / 2);
  mouthShapes.scale.set(scale, scale);
  smudge01.position.set(videoWidth / 2, videoHeight / 2);
  smudge01.scale.set(scale, scale);
  smudge02.position.set(videoWidth / 2, videoHeight / 2);
  smudge02.scale.set(scale, scale);
  smudge03.position.set(videoWidth / 2, videoHeight / 2);
  smudge03.scale.set(scale, scale);
  smudge04.position.set(videoWidth / 2, videoHeight / 2);
  smudge04.scale.set(scale, scale);
  smudge05.position.set(videoWidth / 2, videoHeight / 2);
  smudge05.scale.set(scale, scale);
  smudge06.position.set(videoWidth / 2, videoHeight / 2);
  smudge06.scale.set(scale, scale);
  smudge07.position.set(videoWidth / 2, videoHeight / 2);
  smudge07.scale.set(scale, scale);
  smudge08.position.set(videoWidth / 2, videoHeight / 2);
  smudge08.scale.set(scale, scale);
  smudge09.position.set(videoWidth / 2, videoHeight / 2);
  smudge09.scale.set(scale, scale);
  smudge10.position.set(videoWidth / 2, videoHeight / 2);
  smudge10.scale.set(scale, scale);
  smudge11.position.set(videoWidth / 2, videoHeight / 2);
  smudge11.scale.set(scale, scale);
  smudge12.position.set(videoWidth / 2, videoHeight / 2);
  smudge12.scale.set(scale, scale);
  texture.position.set(videoWidth / 2, videoHeight / 2);
  texture.scale.set(scale, scale);
  controller.position.set(videoWidth / 2, videoHeight / 2);
  controller.scale.set(scale, scale);
  transition.position.set(videoWidth / 2, videoHeight / 2);
  transition.scale.set(scale, scale);
  if (screenWidth < 800) {
    controller.scale.set(scale * 3.8, scale * 3.8);
    controller.position.set(videoWidth / 2, -Math.abs(videoHeight / 1.15));
  }

  // Add elements to the Scene
  makeupScene.addChild(bg);
  makeupScene.addChild(lipstick);
  makeupScene.addChild(lorii);
  makeupScene.addChild(mouthShapes);
  makeupScene.addChild(smudge01);
  makeupScene.addChild(smudge02);
  makeupScene.addChild(smudge03);
  makeupScene.addChild(smudge04);
  makeupScene.addChild(smudge05);
  makeupScene.addChild(smudge06);
  makeupScene.addChild(smudge07);
  makeupScene.addChild(smudge08);
  makeupScene.addChild(smudge09);
  makeupScene.addChild(smudge10);
  makeupScene.addChild(smudge11);
  makeupScene.addChild(smudge12);
  makeupScene.addChild(hearts);
  makeupScene.addChild(texture);
  makeupScene.addChild(controller);
  makeupScene.addChild(transition);

  // Add the scene to the stage
  app.stage.addChild(makeupScene);

  // Reposition and scale on resize
  window.addEventListener("resize", function () {
    videoHeight = video.offsetHeight;
    videoWidth = videoHeight * 1.3333333;
    positionX = videoWidth / 2;
    positionY = videoHeight / 2;
    scale = videoHeight / 2160;

    bg.position.set(positionX, positionY);
    bg.scale.set(scale, scale);
    hearts.position.set(positionX, positionY);
    hearts.scale.set(scale, scale);
    lipstick.position.set(positionX, positionY);
    lipstick.scale.set(scale, scale);
    lorii.position.set(positionX, positionY);
    lorii.scale.set(scale, scale);
    mouthShapes.position.set(positionX, positionY);
    mouthShapes.scale.set(scale, scale);
    smudge01.position.set(positionX, positionY);
    smudge01.scale.set(scale, scale);
    smudge02.position.set(positionX, positionY);
    smudge02.scale.set(scale, scale);
    smudge03.position.set(positionX, positionY);
    smudge03.scale.set(scale, scale);
    smudge04.position.set(positionX, positionY);
    smudge04.scale.set(scale, scale);
    smudge05.position.set(positionX, positionY);
    smudge05.scale.set(scale, scale);
    smudge06.position.set(positionX, positionY);
    smudge06.scale.set(scale, scale);
    smudge07.position.set(positionX, positionY);
    smudge07.scale.set(scale, scale);
    smudge08.position.set(positionX, positionY);
    smudge08.scale.set(scale, scale);
    smudge09.position.set(positionX, positionY);
    smudge09.scale.set(scale, scale);
    smudge10.position.set(positionX, positionY);
    smudge10.scale.set(scale, scale);
    smudge11.position.set(positionX, positionY);
    smudge11.scale.set(scale, scale);
    smudge12.position.set(positionX, positionY);
    smudge12.scale.set(scale, scale);
    texture.position.set(positionX, positionY);
    texture.scale.set(scale, scale);
    controller.position.set(positionX, positionY);
    controller.scale.set(scale, scale);
    transition.position.set(positionX, positionY);
    transition.scale.set(scale, scale);
    if (screenWidth < 800) {
      controller.scale.set(scale * 3.8, scale * 3.8);
      controller.position.set(videoWidth / 2, -Math.abs(videoHeight / 1.15));
    }
  });

  getCurrentTime = setInterval(() => {
    if (parent.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
      parent.setState({
        currentTime: parseFloat(video.currentTime.toFixed(1)),
        duration: parseFloat(video.duration.toFixed(1)),
      });
    }

    if (parent.state.currentTime === startGame) {
      parent.setState({ videoControls: false });

      // Start soundtrack loop
      audio.makeupTrackLoop.addEventListener(
        "ended",
        function () {
          this.currentTime = 0;
          this.play();
        },
        false
      );
      if (parent.state.makeupFinished !== true) audio.makeupTrackLoop.play();

      // Show Scene
      makeupScene.visible = true;

      // Set inital animations
      bg.state.setAnimation(0, "appear", false);
      hearts.state.setAnimation(0, "appear", false);
      lipstick.state.setAnimation(0, "appear", false);
      lorii.state.setAnimation(0, "appear", false);
      mouthShapes.state.setAnimation(0, "appear", false);
      smudge01.state.setAnimation(0, "appear", false);
      smudge02.state.setAnimation(0, "appear", false);
      smudge03.state.setAnimation(0, "appear", false);
      smudge04.state.setAnimation(0, "appear", false);
      smudge05.state.setAnimation(0, "appear", false);
      smudge06.state.setAnimation(0, "appear", false);
      smudge07.state.setAnimation(0, "appear", false);
      smudge08.state.setAnimation(0, "appear", false);
      smudge09.state.setAnimation(0, "appear", false);
      smudge10.state.setAnimation(0, "appear", false);
      smudge11.state.setAnimation(0, "appear", false);
      smudge12.state.setAnimation(0, "appear", false);
      texture.state.setAnimation(0, "appear", false);
      controller.state.setAnimation(0, "appear", false);

      canvasWrap.classList.remove("disabled");
    } else if (parent.state.currentTime === videoPartEnd) {
      video.pause();

      clearInterval(getCurrentTime);
    }
  }, 50);

  const onButtonDown = () => {
    controller.state.setAnimation(0, "tap", false);
    if (parent.state.buttonActive !== true) {
      parent.setState({
        buttonActive: true,
      });
    }
    lipstick.state.timeScale = 1;
  };

  transition.state.timescale = 0.01;

  const lipsFull = () => {
    if (
      parent.state.smudge05 === "full" &&
      parent.state.smudge06 === "full" &&
      parent.state.smudge07 === "full" &&
      parent.state.smudge08 === "full"
    ) {
      return true;
    }
    return false;
  };

  const onButtonUp = () => {
    if (parent.state.surprise !== false) {
      parent.setState({
        surprise: false,
      });
    }
    controller.state.setAnimation(0, "tap", false);
    if (parent.state.buttonActive !== false) {
      parent.setState({
        buttonActive: false,
      });
    }
    lipstick.state.timeScale = 2;
    const fullLips = lipsFull();
    if (fullLips === true) {
      bg.state.setAnimation(0, "successAndExit", false);
      hearts.state.setAnimation(0, "successAndExit", false);
      lipstick.state.setAnimation(0, "successAndExit", false);
      lorii.state.setAnimation(0, "successAndExit", false);
      mouthShapes.state.setAnimation(0, "successAndExit", false);
      smudge01.state.setAnimation(0, "successAndExit", false);
      smudge02.state.setAnimation(0, "successAndExit", false);
      smudge03.state.setAnimation(0, "successAndExit", false);
      smudge04.state.setAnimation(0, "successAndExit", false);
      smudge05.state.setAnimation(0, "successAndExit", false);
      smudge06.state.setAnimation(0, "successAndExit", false);
      smudge07.state.setAnimation(0, "successAndExit", false);
      smudge08.state.setAnimation(0, "successAndExit", false);
      smudge09.state.setAnimation(0, "successAndExit", false);
      smudge10.state.setAnimation(0, "successAndExit", false);
      smudge11.state.setAnimation(0, "successAndExit", false);
      smudge12.state.setAnimation(0, "successAndExit", false);
      texture.state.setAnimation(0, "successAndExit", false);
      controller.state.setAnimation(0, "successAndExit", false);
      transition.state.setAnimation(0, "successAndExit", false);
    }
  };

  controller.interactive = true;
  controller.on("mousedown", onButtonDown).on("touchstart", onButtonDown);
  controller.on("mouseup", onButtonUp).on("touchend", onButtonUp);

  const that = parent;

  // To get details of the event
  //console.log("SKELETON", event, entry, event.data.name);

  bg.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        bg.state.setAnimation(0, "idle", true);
        that.setState({ makeupBg: "idle" });
      }
    },
  });

  lorii.state.addListener({
    event: function (entry, event) {
      // console.log("lorii", event, entry, event.data.name);
      if (event.data.name === "playIdle") {
        lorii.state.setAnimation(0, "idle", true);
        that.setState({ lorii: "idle" });
      } else if (event.data.name === "LipsOpenCuteLaugh_01") {
        audio.cuteLaugh1.play();
      } else if (event.data.name === "LipsOpenCuteLaugh_02") {
        audio.cuteLaugh2.play();
      } else if (event.data.name === "UhOh01") {
        audio.uhOh1.play();
      } else if (event.data.name === "UhOh02") {
        audio.uhOh2.play();
      }
    },
  });

  hearts.state.addListener({
    event: function (entry, event) {
      // console.log("hearts", event, entry, event.data.name);
      if (event.data.name === "HeartWinSound") {
        audio.winSound.play();
      }
    },
  });

  // mouthShapes.state.addListener({
  //   event: function(entry, event) {
  //     console.log("mouthShapes", event, entry, event.data.name);
  //   }
  // });

  lipstick.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        lipstick.state.setAnimation(0, "idle", true);
        lipstick.state.timeScale = 2;
        that.setState({ lipstick: "idle" });
      }
      if (that.state.buttonActive !== false) {
        if (that.state.surprise !== true) {
          if (event.data.name === "smudge01") {
            smudge01.state.setAnimation(0, "smudge", false);
          }
          if (event.data.name === "smudge02") {
            smudge02.state.setAnimation(0, "smudge", false);
          }
          if (event.data.name === "smudge03") {
            smudge03.state.setAnimation(0, "smudge", false);
          }
          if (event.data.name === "smudge04") {
            smudge04.state.setAnimation(0, "smudge", false);
          }
          if (event.data.name === "smudge05") {
            smudge05.state.setAnimation(0, "smudge", false);
            that.setState({ smudge05: "full" });
          }
          if (event.data.name === "smudge06") {
            smudge06.state.setAnimation(0, "smudge", false);
            that.setState({ smudge06: "full" });
          }
          if (event.data.name === "smudge07") {
            smudge07.state.setAnimation(0, "smudge", false);
            that.setState({ smudge07: "full" });
          }
          if (event.data.name === "smudge08") {
            smudge08.state.setAnimation(0, "smudge", false);
            that.setState({ smudge08: "full" });
          }
          if (event.data.name === "smudge09") {
            smudge09.state.setAnimation(0, "smudge", false);
          }
          if (event.data.name === "smudge10") {
            smudge10.state.setAnimation(0, "smudge", false);
          }
          if (event.data.name === "smudge11") {
            smudge11.state.setAnimation(0, "smudge", false);
          }
          if (event.data.name === "smudge12") {
            smudge12.state.setAnimation(0, "smudge", false);
          }

          if (
            event.data.name === "smudge01" ||
            event.data.name === "smudge02" ||
            event.data.name === "smudge03" ||
            event.data.name === "smudge04" ||
            event.data.name === "smudge05" ||
            event.data.name === "smudge06" ||
            event.data.name === "smudge07" ||
            event.data.name === "smudge08" ||
            event.data.name === "smudge09" ||
            event.data.name === "smudge10" ||
            event.data.name === "smudge11" ||
            event.data.name === "smudge12"
          ) {
            audio.onSwish.play();
          } else {
            audio.makeupResultNegative.play();
          }
        }
      }
    },
  });

  smudge01.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "surprise") {
        lorii.state.setAnimation(0, "surprise", false);
        mouthShapes.state.setAnimation(0, "surprise", false);
        that.setState({
          surprise: true,
        });
        if (that.state.smudge01Visible === "true") {
          smudge01.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge02Visible === "true") {
          smudge02.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge03Visible === "true") {
          smudge03.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge04Visible === "true") {
          smudge04.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge05Visible === "true") {
          smudge05.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge06Visible === "true") {
          smudge06.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge07Visible === "true") {
          smudge07.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge08Visible === "true") {
          smudge08.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge09Visible === "true") {
          smudge09.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge10Visible === "true") {
          smudge10.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge11Visible === "true") {
          smudge11.state.setAnimation(0, "surprise", false);
        }
        if (that.state.smudge12Visible === "true") {
          smudge12.state.setAnimation(0, "surprise", false);
        }
      }
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge01Visible: event.stringValue });
      }
    },
  });

  smudge02.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge02Visible: event.stringValue });
      }
    },
  });
  smudge03.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge03Visible: event.stringValue });
      }
    },
  });
  smudge04.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge04Visible: event.stringValue });
      }
    },
  });
  smudge05.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge05Visible: event.stringValue });
      }
    },
  });
  smudge06.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge06Visible: event.stringValue });
      }
    },
  });
  smudge07.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge07Visible: event.stringValue });
      }
    },
  });
  smudge08.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge08Visible: event.stringValue });
      }
    },
  });
  smudge09.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge09Visible: event.stringValue });
      }
    },
  });
  smudge10.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge10Visible: event.stringValue });
      }
    },
  });
  smudge11.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge11Visible: event.stringValue });
      }
    },
  });
  smudge12.state.addListener({
    event: function (entry, event) {
      // console.log(event, that.state);
      if (event.data.name === "smudgeVisible") {
        that.setState({ smudge12Visible: event.stringValue });
      }
    },
  });

  texture.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        texture.state.setAnimation(0, "idle", true);
        that.setState({ makeupTexture: "idle" });
      }
    },
  });

  controller.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        controller.state.setAnimation(0, "idle", true);
        that.setState({ makeupController: "idle" });
      } else if (event.data.name === "ControllerSlideIn") {
        audio.controllerEnter.play();
      } else if (event.data.name === "MainButtonPress") {
        // buttonPress.play();
      }
    },
  });

  transition.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "disappear") {
        lorii.state.setAnimation(0, "disappear", false);
        mouthShapes.state.setAnimation(0, "disappear", false);
        smudge01.state.setAnimation(0, "disappear", false);
        smudge02.state.setAnimation(0, "disappear", false);
        // smudge03.state.setAnimation(0, "disappear", false);
        smudge04.state.setAnimation(0, "disappear", false);
        smudge05.state.setAnimation(0, "disappear", false);
        smudge06.state.setAnimation(0, "disappear", false);
        smudge07.state.setAnimation(0, "disappear", false);
        smudge08.state.setAnimation(0, "disappear", false);
        smudge09.state.setAnimation(0, "disappear", false);
        smudge10.state.setAnimation(0, "disappear", false);
        smudge11.state.setAnimation(0, "disappear", false);
        smudge12.state.setAnimation(0, "disappear", false);
      } else if (event.data.name === "playVideo") {
        video.currentTime = startPartFour;
        video.play();
        parent.setState({ videoControls: true, makeupFinished: true });
        canvasWrap.classList.add("disabled");
        audio.makeupTrackLoop.pause();
      } else if (event.data.name === "dropAssets") {
        makeupScene.visible = false;
      }
    },
  });
};

export default MakeupGame;
