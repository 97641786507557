import React, { Component } from "react";

import nzoaLogo from "../../assets/nzoa_logo.png";

class NZOALogo extends Component {
  render() {
    return (
      <div className="nzoa-logo">
        <img src={nzoaLogo} alt="" />
      </div>
    );
  }
}

export default NZOALogo;
