const studioToHomeAssets = [];
const levelUpAssests = [];
const confToPartyAssests = [];

export const pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const studioToHome = () => {
  for (let i = 0; i < 50; i++) {
    const count = pad(i, 3);
    studioToHomeAssets.push({
      name: `sth${count}`,
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/transitions/studio-home/studio-home-${count}.png`,
    });
  }

  return studioToHomeAssets;
};

export const levelUp = () => {
  for (let i = 0; i < 99; i++) {
    const count = pad(i, 3);
    levelUpAssests.push({
      name: `lu${count}`,
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/transitions/level-up/level-up-${count}.png`,
    });
  }

  return levelUpAssests;
};

export const confToParty = () => {
  for (let i = 0; i < 50; i++) {
    const count = pad(i, 3);
    confToPartyAssests.push({
      name: `ctp${count}`,
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/transitions/conf-party/conf-party-${count}.png`,
    });
  }

  return confToPartyAssests;
};

export const stillsOne = () => {
  return [
    {
      name: "image1",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/mike-intro-a.png`,
    },
    {
      name: "image2",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/mike-intro-b.png`,
    },
    {
      name: "image3",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/mike-intro-c.png`,
    },
    {
      name: "image4",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/mike-intro-d.png`,
    },
    {
      name: "image26",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/studio-home.png`,
    },
  ];
};

export const stillsTwo = () => {
  return [
    {
      name: "image5",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/graduation.png`,
    },
    {
      name: "image6",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/conference-a.png`,
    },
    {
      name: "image7",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/conference-b.png`,
    },
    {
      name: "image8",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/agileboard.png`,
    },
    {
      name: "image9",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/studio-party.png`,
    },
    {
      name: "image11",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/studio-production.png`,
    },
    {
      name: "image14",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/crunch.png`,
    },
    {
      name: "image15",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/parliament-a.png`,
    },
    {
      name: "image16",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/parliament-b.png`,
    },
    {
      name: "image20",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/cruise.png`,
    },
    {
      name: "image24",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/stills/level-up.png`,
    },
    {
      name: "image25",
      url: `${process.env.PUBLIC_URL}/assets/GamePlan/wires/SoccerGame.png`,
    },
  ];
};
