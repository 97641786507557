import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Logo extends Component {
  burgerClick = () => {
    this.props.menuToggle();
  };
  render() {
    return (
      <div
        id="menu-toggle"
        className={this.props.section}
        onClick={this.burgerClick}
      >
        <svg
          width="51px"
          height="36px"
          viewBox="0 0 51 36"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="exports"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Desktop-HD" transform="translate(-334.000000, -9.000000)">
              <g
                id="burger"
                transform="translate(339.000000, 13.000000)"
                stroke="#FFFFFF"
                strokeLinecap="square"
                strokeWidth="2.39999986"
                style={{ pointerEvents: "bounding-box" }}
              >
                <path
                  d="M0.399999976,19.1999999 L40.6000001,19.1999999"
                  id="Line-3-Copy-2"
                ></path>
                <path
                  d="M0.399999976,10.1999999 L40.6000001,10.1999999"
                  id="Line-3-Copy-3"
                ></path>
                <path
                  d="M0.399999976,1.19999993 L40.6000001,1.19999993"
                  id="Line-3-Copy-4"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default withRouter(Logo);
