import { s3url } from "./constants";

const env = process.env.NODE_ENV;
const localPath = "/assets/gameon-stills";
const s3Path = s3url + "/gameon-stills";

const localCanvasPath = "/assets/gameon-canvas-stills";
const s3CanvasPath = s3url + "/gameon-canvas-stills";

let totalCount = null;
let startNumber = null;
let loopStop = null;

const swayAssets = [];
const rotateAssets = [];
const handsAssets = [];
const sway1Assets = [];
const zoomInAssets = [];
const zoomOutAssets = [];
const sway2Assets = [];
const humphreyDissolveAssets = [];
const controllerIntroAssets = [];
const controllerRotateStartAssets = [];
const controllerRotateFinishAssets = [];
const controllerExplodeAssets = [];
const controllerToHumphreyAssets = [];
const superHeroHandsAssets = [];

const canvasAssets = [];

export const pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const canvasPaths = () => {
  return env === "development"
    ? "assets/gameon-canvas-stills/"
    : s3url + "/gameon-canvas-stills/";
};

export const canvasMobilePaths = () => {
  return env === "development"
    ? "assets/gameon-canvas-stills/_mobile/"
    : s3url + "/gameon-canvas-stills/_mobile/";
};

export function getFirstSequenceImageUrls(isMobile) {
  return getSequenceImageUrls(1, 437, isMobile);
}

export function getSecondSequenceImageUrls(isMobile) {
  return getSequenceImageUrls(438, 793, isMobile);
}

function getSequenceImageUrls(startIndex, endIndex, isMobile = false) {
  const urlPrefix = env === 'development' ? localCanvasPath : s3CanvasPath;

  const extraUrlPart = isMobile ? '_mobile/' : '';

  const result = [];
  for (let i = startIndex; i <= endIndex; i++) {
    result.push(`${urlPrefix}/${extraUrlPart}${i.toString().padStart(3, '0')}.jpg`);
  }
  return result;
}

export const canvasFrames = () => {
  for (let i = 1; i <= 793; i++) {
    const count = pad(i, 3);
    if (canvasAssets.length < 793) {
      const path =
        env === "development"
          ? `${localCanvasPath}/${count}.jpg`
          : `${s3CanvasPath}/${count}.jpg`;
      canvasAssets.push({
        name: `${count}`,
        url: path
      });
    }
  }
  return canvasAssets;
};

export const canvasMobileFrames = () => {
  for (let i = 1; i <= 782; i++) {
    const count = pad(i, 3);
    if (canvasAssets.length < 782) {
      const path =
        env === "development"
          ? `${localCanvasPath}/_mobile/${count}.jpg`
          : `${s3CanvasPath}/_mobile/${count}.jpg`;
      canvasAssets.push({
        name: `${count}`,
        url: path
      });
    }
  }
  return canvasAssets;
};

export const initialFrame = () => {
  return env === "development"
    ? `${localPath}/sway-001.jpg`
    : `${s3Path}/sway-001.jpg`;
};

totalCount = 41;
startNumber = 1;
export const initial = () => {
  for (let i = 1; i <= 41; i++) {
    const count = pad(i, 3);
    if (swayAssets.length < 41) {
      const path =
        env === "development"
          ? `${localPath}/sway-${count}.jpg`
          : `${s3Path}/sway-${count}.jpg`;
      swayAssets.push({
        name: `initial${count}`,
        url: path
      });
    }
  }
  return swayAssets;
};

export const rotate = () => {
  totalCount = 55;
  startNumber = 42;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (rotateAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/rotate-${count}.jpg`
          : `${s3Path}/rotate-${count}.jpg`;
      rotateAssets.push({
        name: `rotate${count}`,
        url: path
      });
    }
  }
  return rotateAssets;
};

export const hands = () => {
  totalCount = 59;
  startNumber = 97;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (handsAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/hands-${count}.jpg`
          : `${s3Path}/hands-${count}.jpg`;
      handsAssets.push({
        name: `hands${count}`,
        url: path
      });
    }
  }
  return handsAssets;
};

export const sway1 = () => {
  totalCount = 43;
  startNumber = 156;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (sway1Assets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/sway-1-${count}.jpg`
          : `${s3Path}/sway-1-${count}.jpg`;
      sway1Assets.push({
        name: `sway1${count}`,
        url: path
      });
    }
  }
  return sway1Assets;
};

export const zoomIn = () => {
  totalCount = 82;
  startNumber = 199;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (zoomInAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/zoom-in-${count}.jpg`
          : `${s3Path}/zoom-in-${count}.jpg`;
      zoomInAssets.push({
        name: `zoomIn${count}`,
        url: path
      });
    }
  }
  return zoomInAssets;
};

export const zoomOut = () => {
  totalCount = 17;
  startNumber = 281;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (zoomOutAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/zoom-out-${count}.jpg`
          : `${s3Path}/zoom-out-${count}.jpg`;
      zoomOutAssets.push({
        name: `zoomOut${count}`,
        url: path
      });
    }
  }
  return zoomOutAssets;
};

export const sway2 = () => {
  totalCount = 54;
  startNumber = 298;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (sway2Assets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/sway-2-${count}.jpg`
          : `${s3Path}/sway-2-${count}.jpg`;
      sway2Assets.push({
        name: `sway2${count}`,
        url: path
      });
    }
  }
  return sway2Assets;
};

export const humphreyDissolve = () => {
  totalCount = 85;
  startNumber = 352;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (humphreyDissolveAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/humphrey-dissolve-${count}.jpg`
          : `${s3Path}/humphrey-dissolve-${count}.jpg`;
      humphreyDissolveAssets.push({
        name: `humphreyDissolve${count}`,
        url: path
      });
    }
  }
  return humphreyDissolveAssets;
};

export const controllerIntro = () => {
  totalCount = 59;
  startNumber = 437;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (controllerIntroAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/controller-intro-${count}.jpg`
          : `${s3Path}/controller-intro-${count}.jpg`;
      controllerIntroAssets.push({
        name: `controllerIntro${count}`,
        url: path
      });
    }
  }
  return controllerIntroAssets;
};

export const controllerRotateStart = () => {
  totalCount = 65;
  startNumber = 496;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (controllerRotateStartAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/controller-rotate-start-${count}.jpg`
          : `${s3Path}/controller-rotate-start-${count}.jpg`;
      controllerRotateStartAssets.push({
        name: `controllerRotateStart${count}`,
        url: path
      });
    }
  }
  return controllerRotateStartAssets;
};

export const controllerRotateFinish = () => {
  totalCount = 12;
  startNumber = 561;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (controllerRotateFinishAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/controller-rotate-finish-${count}.jpg`
          : `${s3Path}/controller-rotate-finish-${count}.jpg`;
      controllerRotateFinishAssets.push({
        name: `controllerRotateFinish${count}`,
        url: path
      });
    }
  }
  return controllerRotateFinishAssets;
};

export const controllerExplode = () => {
  totalCount = 48;
  startNumber = 573;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (controllerExplodeAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/controller-explode-${count}.jpg`
          : `${s3Path}/controller-explode-${count}.jpg`;
      controllerExplodeAssets.push({
        name: `controllerExplode${count}`,
        url: path
      });
    }
  }
  return controllerExplodeAssets;
};

export const controllerToHumphrey = () => {
  totalCount = 113;
  startNumber = 621;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (controllerToHumphreyAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/controller-to-humphrey-${count}.jpg`
          : `${s3Path}/controller-to-humphrey-${count}.jpg`;
      controllerToHumphreyAssets.push({
        name: `controllerToHumphrey${count}`,
        url: path
      });
    }
  }
  return controllerToHumphreyAssets;
};

export const superHeroHands = () => {
  totalCount = 60;
  startNumber = 734;
  loopStop = totalCount + startNumber;
  for (let i = startNumber; i < loopStop; i++) {
    const count = pad(i, 3);
    if (superHeroHandsAssets.length < totalCount) {
      const path =
        env === "development"
          ? `${localPath}/super-hero-hands-${count}.jpg`
          : `${s3Path}/super-hero-hands-${count}.jpg`;
      superHeroHandsAssets.push({
        name: `superHeroHands${count}`,
        url: path
      });
    }
  }
  return superHeroHandsAssets;
};
