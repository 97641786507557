import React, {
  Component,
  Children,
  isValidElement,
  cloneElement,
} from "react";

let audioList = {};
let audioLoops = {};

class AudioWrapper extends Component {
  constructor() {
    super();

    this.state = {
      audioEnabled: false,
      unMutePrompt: false,
      muteState: false,
      windowFocused: true,
    };
  }

  componentDidMount() {
    // To stop audio playing when the tab isn't active
    // Or safari is closed on iOS
    const main = this;
    document.addEventListener("visibilitychange", function (event) {
      main.setState({ windowFocused: !main.state.windowFocused });
      if (!main.state.windowFocused) {
        //loopTrack.pause();
        // @TODO: Go through the loop object and pause any loops in there
      } else {
        //loopTrack.play();
        // @TODO: Go through the loop object and pause any loops in there
      }
    });

    // throwSound = new Audio(audioPaths("GumbootThrowLoop.mp3"));
  }

  updateAudioLoops = (name, loopTrack) => {
    audioLoops[name] = loopTrack;
    loopTrack.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false
    );
  };

  removeLoopTrack = (name) => {
    delete audioLoops[name];
  };

  updateAudioList = (name, track) => {
    audioList[name] = track;
  };

  removeFromAudioList = (trackNames) => {
    trackNames.map((name) => {
      delete audioList[name];
    });
  };

  controlPlayState = (audio) => {
    console.log(audio, audio.paused);
    if (audio.paused) {
      var promise = audio.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            this.setState({
              audioEnabled: true,
              unMutePrompt: false,
              muteState: false,
            });
            console.log("autoplay");
          })
          .catch((error) => {
            console.log("autoplay failed - ", error);
            // Show a button on the video saying pres play to start
            this.setState({ unMutePrompt: true, muteState: true });
          });
      }
    } else {
      audio.pause();
      this.setState({ audioEnabled: false, muteState: true });
    }
  };

  muteToggle = () => {
    this.setState({ muteState: !this.state.muteState, unMutePrompt: false });
    Object.values(audioList).map((audio) => {
      audio.muted = !this.state.muteState;
    });
    Object.values(audioLoops).map((audio) => {
      if (audio.paused) audio.play();
    });
  };

  render() {
    const childrenWithProps = Children.map(this.props.children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          muteState: this.state.muteState,
          controlPlayState: this.controlPlayState,
          muteToggle: this.muteToggle,
          unMutePrompt: this.state.unMutePrompt,
          updateAudioLoops: this.updateAudioLoops,
          removeLoopTrack: this.removeLoopTrack,
          updateAudioList: this.updateAudioList,
          removeFromAudioList: this.updateAudioList,
        });
      }
      return child;
    });

    return <React.Fragment>{childrenWithProps}</React.Fragment>;
  }
}

export default AudioWrapper;
