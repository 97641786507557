import React, { Component } from "react";
import {
  TimelineMax,
  TweenMax,
  CSSPlugin,
  ScrollToPlugin,
  Expo,
  Elastic,
} from "gsap/all";
import ScrollSwipe from "scroll-swipe";
import ReactGA from "react-ga";

import Hero from "../Story/Hero";
import GameTease from "../Story/GameTease";
import GameFaceArticle from "./GameFaceArticle";
import NextStory from "../Article/NextStory";
import Mute from "../Video/Mute";

import {
  FaceSliderLorii,
  FaceSliderLoriiAnimated,
} from "../../utils/fileManager";
import { audioPaths } from "../../utils/mediaManager";

import "../App/StorySplit.scss";
import "./GameFace.scss";

const ScrollMagic = window.ScrollMagic;

let isScrolling = null;

let audioList = new Array();
let loopTrack = null;

let nav = null;

class GameFace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: false,
      audioEnabled: false,
      playPrompt: false,
      muteState: false,
    };
    this.controller = new ScrollMagic.Controller();

    this.article = null;
    this.articleWrap = null;
    this.articleTween = null;
    this.scrollTween = null;
  }

  componentDidMount() {
    // this.props.updateNav("gameFace");
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.initializeSwipe();

    nav = document.getElementById("nav-bar");
    this.onScroll();

    this.articleTween = new TimelineMax({ paused: false }).fromTo(
      this.article,
      1,
      {
        padding: "0 5%",
        // y: "40vh"
      },
      {
        padding: "0",
        //y: 0,
        ease: Expo.easeInOut,
        delay: 0.3,
      }
    );

    new ScrollMagic.Scene({
      triggerElement: `#game-face-article`,
      triggerHook: 0.75,
      duration: "80%",
    })
      // .addIndicators()
      .setTween(this.articleTween)
      .addTo(this.controller);

    loopTrack = new Audio(audioPaths("HomePageMusicBed.mp3"));
    loopTrack.volume = 0.4;
    this.controlPlayState(loopTrack);
    loopTrack.addEventListener(
      "ended",
      function () {
        console.log("looped");
        this.currentTime = 0;
        this.play();
      },
      false
    );
    audioList.push(loopTrack);
  }

  componentWillUnmount() {
    audioList.map((audio) => {
      audio.muted = true;
      audio.pause();
    });
    if (nav) {
      nav.classList.remove("dark");
    }
  }

  unmute = () => {
    this.controlPlayState(loopTrack);
  };

  muteToggle = () => {
    console.log("mute toggle");
    this.setState({ muteState: !this.state.muteState });
    // Now mute or unmute all audio on this page
    audioList.map((audio) => {
      audio.muted = !this.state.muteState;
    });
  };

  onScroll = () => {
    window.addEventListener("scroll", this.scrollFunction);
  };

  controlPlayState = (audio) => {
    if (audio.paused) {
      var promise = audio.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            this.setState({ audioEnabled: true, playPrompt: false });
            console.log("autoplay");
          })
          .catch((error) => {
            // Show a button on the video saying pres play to start
            this.setState({ playPrompt: true });
          });
      }
    } else {
      audio.pause();
      this.setState({ audioEnabled: false });
    }
  };

  controlLoopTrack = () => {
    this.controlPlayState(loopTrack);
  };

  scrollFunction = () => {
    let scrollPosition = window.pageYOffset;
    if (this.articleWrap) {
      const articlePosition = this.articleWrap.offsetTop;
      const articleHeight = this.articleWrap.getBoundingClientRect().height;
      const endPosition = Math.floor(articlePosition + articleHeight);

      if (nav) {
        const navHeight = nav.getBoundingClientRect().height;
        if (
          scrollPosition >= articlePosition &&
          scrollPosition <= endPosition - navHeight
        ) {
          nav.classList.add("dark");
          nav.classList.remove("light");
        } else {
          nav.classList.add("light");
          nav.classList.remove("dark");
        }
      }
    }
  };

  initializeSwipe = () => {
    // const scrollCb = data => {
    //   console.log(data);
    //   const { direction, mappedIntent } = data;
    //   if (this.state.scrolling === false) {
    //     this.scrollToPoint(direction, mappedIntent);
    //     this.setState({
    //       scrolling: true
    //     });
    //   }
    //   window.clearTimeout(isScrolling);
    //   const that = this;
    //   // Set a timeout to run after scrolling ends
    //   isScrolling = setTimeout(function() {
    //     // Run the callback
    //     console.log("Scrolling has stopped.");
    //     that.setState({
    //       scrolling: false
    //     });
    //   }, 66);
    //   //perform actions such as animations/transitions or just plain funciton calls, then set the scrollPending back to false to listen for the next event
    //   ss.listen();
    // };
    // const touchCb = data => {
    //   // COPY CONTENTS OF ScrollCb
    // };
    // const ss = new ScrollSwipe({
    //   target: document.body, // can be a div, or anything else you want to track scroll/touch events on
    //   scrollSensitivity: 0.3, // the lower the number, the more sensitive
    //   touchSensitivity: 0.3, // the lower the number, the more senitive,
    //   scrollPreventDefault: true, // prevent default option for scroll events, manually handle scrolls with scrollCb
    //   touchPreventDefault: true, // prevent default option for touch events, manually handle scrolls with touchCb
    //   scrollCb,
    //   touchCb
    // });
  };

  scrollToPoint(direction, mappedIntent) {
    if (direction === "VERTICAL") {
      if (mappedIntent === "DOWN") {
        TweenMax.to(window, 0.8, {
          scrollTo: "#article",
          ease: Expo.easeOut,
        });
      }
    }
  }

  render() {
    return (
      <div className="game-face story-wrap">
        <div className="mute-toggle" onClick={this.muteToggle}>
          <Mute mode={this.state.muteState ? "mute-off" : "mute-on"} />
        </div>
        <div className="hero-wrap">
          <Hero
            story="gameFace"
            video={FaceSliderLorii()}
            title="Game Face"
            lineOne="The dual lives" //"The dual lives of"
            lineTwo="of a video" // "one of our most successful"
            lineThree="game streamer" //"video game streamers"
            ref={(ref) => {
              this.hero = ref;
            }}
            hideScroll="true"
            intro="Join a day in the life of Lorien Gugich, aka Loriipops, who juggles her home life with 8 hours of live-streamed video gaming to the world"
            teaseVideo={FaceSliderLoriiAnimated()}
            teaseTitle="A day in the Life"
            teaseText="Help Loriipops through her day"
            teaseLink="/gameface/game"
          />
        </div>
        <div
          className="article"
          ref={(ref) => {
            this.article = ref;
          }}
          id="the-article"
        >
          <div
            className="article-wrap"
            ref={(ref) => {
              this.articleWrap = ref;
            }}
          >
            <GameFaceArticle controlLoopTrack={this.controlLoopTrack} />
            <div className="game-prompt-spacer">
              <GameTease
                story="gameFace"
                video={FaceSliderLoriiAnimated()}
                title="A day in the Life"
                text="Help Loriipops through her day"
                link="/gameface/game"
                mobile={true}
              />
            </div>
          </div>
          <NextStory current="gameface" />
        </div>
      </div>
    );
  }
}

export default GameFace;
