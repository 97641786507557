let url = "https://api.gamechange.nz/index.php";
// const env = process.env.NODE_ENV;
// if (env === "development") {
//   url = "http://localhost:8001";
// }

export const API_URL_SITE = url + "/wp-json/";
export const API_URL_PAGES = API_URL_SITE + "wp/v2/pages/?&per_page=99";
export const API_URL_POSTS = API_URL_SITE + "wp/v2/posts/?&per_page=99";

export const GP_ARTICLE_PAGEID = 41;
export const GF_ARTICLE_PAGEID = 45;
export const GO_ARTICLE_PAGEID = 47;
