import React, { Component } from "react";

import QuoteVideo from "../Article/QuoteVideo";

const Timings = [
  {
    start: 0.14,
    stop: 3,
    delay: 0.1,
    word:
      "The interactive media industry really has all the qualities that we are looking for:",
  },
  {
    start: 11,
    stop: 11.5,
    delay: 8,
    word: "93% export revenue",
  },
  {
    start: 17.5,
    stop: 18.0,
    delay: 6,
    word: "Low emissions",
  },
  {
    start: 20.5,
    stop: 21,
    delay: 3.5,
    word: "Knowledge industry",
  },
  {
    start: 23.5,
    stop: 24,
    delay: 2.5,
    word: "New Zealand IP",
  },
  {
    start: 28.5,
    stop: 29,
    delay: 4.5,
    word: "High value products",
  },
  {
    start: 32.5,
    stop: 33,
    delay: 3.5,
    word: "Creating skilled, well paid jobs",
  },
  {
    start: 36.5,
    stop: 37,
    delay: 3.5,
    word: "",
  },
];

let getCurrentTime = null;

class A44sQuoteVideoThree extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: "initial",
      progress: 0,
    };
  }
  componentDidMount() {
    this.getVideoData();
    // for (var i = 1; i < Timings.length; i++) {
    //   delayItems.push(getDelay(Timings[i - 1], Timings[i]));
    // }
  }

  componentWillUnmount() {
    clearInterval(getCurrentTime);
    const video = document.getElementById(this.props.videoid);
    video.removeEventListener("loadedmetadata", this.videoLoaded);
  }

  updateTime = () => {
    const video = document.getElementById(this.props.videoid);
    getCurrentTime = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        if (this.state.currentTime === this.state.duration) {
          clearInterval(getCurrentTime);
        }
      }
    }, 100);
  };

  getVideoData = () => {
    const video = document.getElementById(this.props.videoid);
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.videoLoaded);
      return video.duration;
    }
    return null;
  };

  videoLoaded = () => {
    const video = document.getElementById(this.props.videoid);
    if (this.state.duration === "initial") {
      this.setState({
        duration: parseFloat(video.duration.toFixed(1)),
      });
      this.updateTime();
    }
  };

  render() {
    return (
      <div className="quotes" id="a44-quote-three">
        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.videoid)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#B6CAE1"
          endColor="#4179BD"
        />
      </div>
    );
  }
}

export default A44sQuoteVideoThree;
