import { s3url } from "./constants";

const env = process.env.NODE_ENV;
const localPath = "/assets/s3";
const s3Path = s3url;

export const imagePaths = (story, file) => {
  return env === "development"
    ? `${localPath}/article_images/${story}/${file}`
    : `${s3Path}/article_images/${story}/${file}`;
};

export const videoPaths = file => {
  return env === "development"
    ? `${localPath}/videos/${file}`
    : `${s3Path}/videos/${file}`;
};

export const audioPaths = file => {
  return env === "development"
    ? `${localPath}/audio/${file}`
    : `${s3Path}/audio/${file}`;
};
