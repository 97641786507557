import React, { Component } from "react";

import "./VideoControls.scss";

class Mute extends Component {
  render() {
    return (
      <React.Fragment>
        <svg
          width="30px"
          height="21px"
          viewBox="0 0 30 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={`mute ${this.props.mode}`}
        >
          <g
            id="exports"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="mute">
              <polygon
                id="speaker-dark"
                fill="#000000"
                points="6.68042718 6.5 15.9661415 0 15.9661415 21 6.68042718 14.5 0 14.5 0 6.5"
              ></polygon>
              <polygon
                id="speaker"
                fill="#FFFFFF"
                points="6.68042718 6.5 15.9661415 0 15.9661415 21 6.68042718 14.5 0 14.5 0 6.5"
              ></polygon>
              <g
                id="mute-x-dark"
                transform="translate(20.000000, 6.000000)"
                stroke="#000000"
                strokeLinecap="round"
              >
                <g id="Group-3">
                  <path d="M0.5,0.5 L9.01469318,9.01469318" id="Line-4"></path>
                </g>
                <g id="Group-2" transform="translate(0.500000, 0.500000)">
                  <path d="M8.51000977,0 L0,8.51469318" id="Line-4"></path>
                </g>
              </g>
              <g
                id="mute-x"
                transform="translate(20.000000, 6.000000)"
                stroke="#FFFFFF"
                strokeLinecap="round"
              >
                <g id="Group-3">
                  <path d="M0.5,0.5 L9.01469318,9.01469318" id="Line-4"></path>
                </g>
                <g id="Group-2" transform="translate(0.500000, 0.500000)">
                  <path d="M8.51000977,0 L0,8.51469318" id="Line-4"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </React.Fragment>
    );
  }
}

export default Mute;
