import React from "react";
import { Timeline, Tween } from "react-gsap";

class QuoteVideo extends React.Component {
  getWordDuration = item => {
    const start = parseFloat(item.start.toFixed(1));
    const end = parseFloat(item.stop.toFixed(1));
    const duration = end - start;
    return parseFloat(duration.toFixed(1));
  };

  render() {
    const startColor = this.props.startColor;
    const endColor = this.props.endColor;
    return (
      <div className="quote-text">
        <Timeline
          paused={true}
          progress={this.props.progress}
          duration={this.props.duration}
          smoothChildTiming={true}
        >
          {this.props.timings &&
            this.props.timings.map((word, i) => {
              const start = parseFloat(word.start.toFixed(1));
              return (
                <Tween
                  key={i}
                  from={{ color: startColor }}
                  to={{ color: endColor }}
                  stagger={start}
                  delay={word.delay}
                  duration={this.getWordDuration(word)}
                >
                  <span>{word.word} </span>
                </Tween>
              );
            })}
        </Timeline>
      </div>
    );
  }
}

export default QuoteVideo;
