import React, { Component } from "react";

import ImageScroll from "./ImageScroll";

import "./ImageLayout.scss";

class ImageColumns extends Component {
  render() {
    return (
      <div className="image-columns">
        <div className={`col left-col${this.props.leftClasses}`}>
          {this.props.leftCol &&
            this.props.leftCol.map((image, l) => {
              return (
                <ImageScroll
                  size={image.size}
                  src={image.src}
                  imgId={image.imgId}
                  key={l}
                />
              );
            })}
        </div>
        <div className={`col right-col${this.props.rightClasses}`}>
          {this.props.rightCol &&
            this.props.rightCol.map((image, r) => {
              return (
                <ImageScroll
                  size={image.size}
                  src={image.src}
                  imgId={image.imgId}
                  key={r}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default ImageColumns;
