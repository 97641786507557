import React, { Component } from "react";
import { Link } from "react-router-dom";

import gameTitle from "../../assets/The_Game_Plan.svg";
import gameIntro from "../../assets/gamePlanGameIntro.jpg";
import arrow from "../../assets/arrow-down-story.svg";

import "./GamePlanGameIntro.scss";

class GamePlanGameIntro extends Component {
  render() {
    return (
      <div>
        <div className="game-intro game-plan" id="game-plan-game-intro">
          <div className="preview">
            <img src={gameIntro} alt="" />
          </div>
          <div className="content">
            <div>
              <div className="title">
                <img src={gameTitle} alt="" />
              </div>
              <div className="sub-title">A MINI GAME</div>
            </div>
            <div>
              <div className="description">
                Push Mike through his game development career
              </div>
              <Link className="btn" to="/gameplan/game/scene/1">
                Let's Go!
              </Link>
            </div>
          </div>
          <div className="article-prompt">
            <Link to="/gameplan/#the-article">BACK TO THE STORY</Link>
            {/* <img src={arrow} alt="" /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default GamePlanGameIntro;
