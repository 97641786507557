import * as imageCache from './image-cache';
import * as imageLoaderRegistry from './image-loader-registry';
import { createImageRenderer } from './image-renderer';
import { createBackgroundLoader } from './background-loader';
import { createSpinner } from './spinner';

export function createFrameViewer(container, images) {
  let isDestroyed = false;

  const spinner = createSpinner();
  const imageRenderer = createImageRenderer(container, spinner);
  const backgroundLoader = createBackgroundLoader(images);

  let activeIndex = NaN;
  let unsubscribeFromActiveLoader = [];

  return {
    showByIndex,
    showByRatio,
    destroy
  };

  function showByIndex(frameIndex) {
    frameIndex = Math.max(0, Math.min(images.length - 1, frameIndex));
    if (isDestroyed || frameIndex === activeIndex) {
      return;
    }

    clearSubscriptionsForActiveLoaded();

    activeIndex = frameIndex;
    const imageUrl = images[activeIndex];

    if (imageCache.hasImage(imageUrl)) {
      renderImage(imageUrl);
      backgroundLoader.setActiveFrameIndex(activeIndex);
      return;
    }

    const loader = imageLoaderRegistry.getLoader(imageUrl);
    backgroundLoader.setActiveFrameIndex(activeIndex);
    spinner.show();

    const onImageLoaded = () => {
      clearSubscriptionsForActiveLoaded();
      renderImage(imageUrl);
    };

    unsubscribeFromActiveLoader = [
      loader.onLoad(onImageLoaded)
    ];
  }

  function showByRatio(ratio) {
    if (isDestroyed) {
      return;
    }

    ratio = Math.min(1, Math.max(0, ratio));
    showByIndex(Math.floor(ratio * images.length));
  }

  function destroy() {
    if (isDestroyed) {
      return;
    }
    isDestroyed = true;

    clearSubscriptionsForActiveLoaded();
    imageRenderer.destroy();
  }

  function renderImage(imageUrl) {
    spinner.hide();

    if (imageCache.hasImage(imageUrl)) {
      imageRenderer.show(imageCache.getImage(imageUrl));
    }
  }

  function clearSubscriptionsForActiveLoaded() {
    unsubscribeFromActiveLoader.forEach(fn => fn());
    unsubscribeFromActiveLoader = [];
  }
}
