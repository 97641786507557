import React, { Component } from "react";

import QuoteVideo from "../../components/Article/QuoteVideo";

const Timings = [
  {
    start: 0.32,
    stop: 1.03,
    delay: 0,
    word: "So,",
  },

  {
    start: 1.57,
    stop: 2.02,
    delay: 0.5,
    word: "Lorien",
  },
  {
    start: 2.29,
    stop: 2.47,
    delay: 0.3,
    word: "and",
  },
  {
    start: 2.58,
    stop: 3.0,
    delay: 0.1,
    word: "Loriipops",
  },
  {
    start: 3.38,
    stop: 3.72,
    delay: 0.4,
    word: "are",
  },
  // {
  //   start: 4.48,
  //   stop: 4.56,
  //   delay: 0.8,
  //   word: "they're"
  // },
  {
    start: 4.66,
    stop: 4.75,
    delay: 0.9,
    word: "the",
  },
  {
    start: 4.81,
    stop: 5.13,
    delay: 0.1,
    word: "same",
  },
  {
    start: 5.29,
    stop: 5.91,
    delay: 0.2,
    word: "person",
  },
  {
    start: 6.14,
    stop: 6.24,
    delay: 0.2,
    word: "but",
  },
  {
    start: 6.45,
    stop: 6.92,
    delay: 0.2,
    word: "Loriipops",
  },
  {
    start: 6.99,
    stop: 7.1,
    delay: 0.1,
    word: "is",
  },
  {
    start: 7.3,
    stop: 7.83,
    delay: 0.2,
    word: "definitely",
  },
  {
    start: 7.85,
    stop: 7.95,
    delay: 0,
    word: "a",
  },
  {
    start: 8.12,
    stop: 8.47,
    delay: 0.2,
    word: "more",
  },
  {
    start: 9.43,
    stop: 10.43,
    delay: 1,
    word: "excitable",
  },
  {
    start: 10.68,
    stop: 10.97,
    delay: 0.3,
    word: "and",
  },
  {
    start: 11.28,
    stop: 11.61,
    delay: 0.3,
    word: "more",
  },
  {
    start: 11.74,
    stop: 12.41,
    delay: 0.1,
    word: "charismatic",
  },
  {
    start: 12.75,
    stop: 13.16,
    delay: 0.3,
    word: "version",
  },
  {
    start: 13.66,
    stop: 13.75,
    delay: 0.5,
    word: "of",
  },
  {
    start: 13.85,
    stop: 14.09,
    delay: 0.1,
    word: "me.",
  },
  {
    start: 14.42,
    stop: 14.72,
    delay: 0.3,
    word: "She's",
  },

  {
    start: 14.74,
    stop: 14.84,
    delay: 0,
    word: "like",
  },
  {
    start: 14.92,
    stop: 15.03,
    delay: 0.1,
    word: "the",
  },
  {
    start: 15.07,
    stop: 15.26,
    delay: 0,
    word: "hidden",
  },
  {
    start: 15.39,
    stop: 15.64,
    delay: 0.1,
    word: "gem",
  },
  {
    start: 15.71,
    stop: 15.97,
    delay: 0.1,
    word: "inside",
  },
  {
    start: 16.02,
    stop: 16.16,
    delay: 0,
    word: "of",
  },
  {
    start: 16.18,
    stop: 16.48,
    delay: 0,
    word: "me",
  },
  {
    start: 16.67,
    stop: 16.76,
    delay: 0.2,
    word: "that",
  },
  {
    start: 16.78,
    stop: 16.86,
    delay: 0,
    word: "I",
  },
  {
    start: 16.85,
    stop: 17.01,
    delay: -0,
    word: "only",
  },
  {
    start: 17.21,
    stop: 17.47,
    delay: 0.2,
    word: "release",
  },
  {
    start: 17.78,
    stop: 18.04,
    delay: 0.3,
    word: "at",
  },
  // {
  //   start: 18.38,
  //   stop: 18.56,
  //   delay: 0.3,
  //   word: "you know"
  // },
  {
    start: 18.63,
    stop: 18.72,
    delay: 0.59,
    word: "a",
  },
  {
    start: 18.81,
    stop: 19.08,
    delay: 0.8,
    word: "certain",
  },
  {
    start: 19.17,
    stop: 19.43,
    delay: 0.1,
    word: "time.",
  },
  {
    start: 19.5,
    stop: 20.0,
    delay: 0.1,
    word: " ",
  },
];
let getCurrentTime = null;

class LoriiQuoteVideo extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: 0,
      progress: 0,
    };
  }
  componentDidMount() {
    this.getVideoData();
  }

  componentWillUnmount() {
    clearInterval(getCurrentTime);
    const video = document.getElementById(this.props.videoId);
    if (video) {
      video.removeEventListener("loadedmetadata", this.getDuration);
    }
  }

  updateTime = () => {
    const video = document.getElementById(this.props.videoId);
    getCurrentTime = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        // const timeline = this.timeline.getGSAP();
        // console.log(timeline._timeline);
      }
    }, 50);
  };

  getVideoData = () => {
    const video = document.getElementById(this.props.videoId);
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.getDuration);
      return video.duration;
    }
    return null;
  };

  getDuration = () => {
    const video = document.getElementById(this.props.videoId);
    if (video) {
      this.setState({
        duration: parseFloat(video.duration.toFixed(1)),
      });
      this.updateTime();
    }
  };

  render() {
    return (
      <div className="quotes" id="quote-words-one">
        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.videoId)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#CDB4B7"
          endColor="#EA0521"
        />
      </div>
    );
  }
}

export default LoriiQuoteVideo;
