import React, { Component } from "react";

import QuoteVideo from "../Article/QuoteVideo";

const Timings = [
  {
    start: 0.04,
    stop: 0.19,
    delay: 0,
    word: "I've",
  },
  {
    start: 0.2,
    stop: 0.33,
    delay: 0,
    word: "drawn",
  },
  {
    start: 0.47,
    stop: 0.56,
    delay: 0.1,
    word: "a lot",
  },
  {
    start: 0.76,
    stop: 1.25,
    delay: 0.2,
    word: " of inspiration",
  },
  {
    start: 1.32,
    stop: 1.42,
    delay: 0.1,
    word: "from",
  },
  {
    start: 1.56,
    stop: 1.69,
    delay: 0.1,
    word: "kind of",
  },
  {
    start: 2.23,
    stop: 2.64,
    delay: 0.5,
    word: "the wide",
  },
  {
    start: 3.41,
    stop: 3.68,
    delay: 0.8,
    word: "range",
  },
  {
    start: 3.74,
    stop: 3.83,
    delay: 0.1,
    word: "of",
  },
  {
    start: 3.94,
    stop: 4.12,
    delay: 0.1,
    word: "games",
  },
  {
    start: 4.17,
    stop: 4.33,
    delay: 0,
    word: "out",
  },
  {
    start: 4.42,
    stop: 4.56,
    delay: 0.1,
    word: "there,",
  },
  {
    start: 4.61,
    stop: 4.69,
    delay: 0.1,
    word: "I",
  },
  {
    start: 4.73,
    stop: 4.8,
    delay: 0.1,
    word: "think",
  },
  {
    start: 4.84,
    stop: 5.0,
    delay: 0,
    word: "that's",
  },
  {
    start: 5.16,
    stop: 5.43,
    delay: 0.2,
    word: "important",
  },
  {
    start: 5.96,
    stop: 6.21,
    delay: 0.5,
    word: "for",
  },
  {
    start: 6.38,
    stop: 6.48,
    delay: 0.2,
    word: "game",
  },
  {
    start: 6.55,
    stop: 6.76,
    delay: 0.1,
    word: "design",
  },
  {
    start: 6.82,
    stop: 6.94,
    delay: 0.1,
    word: "to",
  },
  {
    start: 7.04,
    stop: 7.15,
    delay: 0.1,
    word: "know",
  },
  {
    start: 7.19,
    stop: 7.3,
    delay: 0,
    word: "what's",
  },
  {
    start: 7.31,
    stop: 7.42,
    delay: 0,
    word: "out",
  },
  {
    start: 7.62,
    stop: 7.66,
    delay: 0.2,
    word: "there",
  },
  {
    start: 7.72,
    stop: 7.82,
    delay: 0.1,
    word: "to",
  },
  {
    start: 7.92,
    stop: 8.03,
    delay: 0.1,
    word: "know",
  },
  {
    start: 8.11,
    stop: 8.23,
    delay: 0.1,
    word: "why",
  },
  {
    start: 8.42,
    stop: 8.52,
    delay: 0.2,
    word: "those",
  },
  {
    start: 8.76,
    stop: 8.87,
    delay: 0.2,
    word: "things",
  },
  {
    start: 9.45,
    stop: 9.71,
    delay: 0.6,
    word: "worked",
  },
  {
    start: 10.17,
    stop: 10.28,
    delay: 0.5,
    word: "within",
  },
  {
    start: 10.32,
    stop: 10.41,
    delay: 0.1,
    word: "the",
  },
  {
    start: 10.58,
    stop: 10.75,
    delay: 0.2,
    word: "context",
  },
  {
    start: 10.91,
    stop: 11.04,
    delay: 0.2,
    word: "they were",
  },
  {
    start: 11.22,
    stop: 11.4,
    delay: 0.2,
    word: "presented",
  },
  {
    start: 11.54,
    stop: 11.56,
    delay: 0.1,
    word: "in,",
  },
  {
    start: 12.22,
    stop: 12.36,
    delay: 0.7,
    word: "and",
  },
  {
    start: 12.39,
    stop: 12.46,
    delay: 0,
    word: "kind",
  },
  {
    start: 12.5,
    stop: 12.56,
    delay: 0.1,
    word: "of",
  },
  {
    start: 12.73,
    stop: 12.89,
    delay: 0.2,
    word: "learning",
  },
  {
    start: 13.02,
    stop: 13.09,
    delay: 0.1,
    word: "and",
  },
  {
    start: 13.16,
    stop: 13.46,
    delay: 0.1,
    word: "building",
  },
  {
    start: 13.54,
    stop: 13.79,
    delay: 0,
    word: "on",
  },
  {
    start: 14.4,
    stop: 14.52,
    delay: 0.6,
    word: "those",
  },
  {
    start: 14.65,
    stop: 15.08,
    delay: 0.1,
    word: "elements.",
  },
];

let getCurrentTime = null;

class MikesQuoteVideoTwo extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: "initial",
      progress: 0,
    };
  }
  componentDidMount() {
    this.getVideoData();
  }

  componentWillUnmount() {
    clearInterval(getCurrentTime);
    const video = document.getElementById(this.props.videoid);
    video.removeEventListener("loadedmetadata", this.videoLoaded);
  }

  updateTime = () => {
    const video = document.getElementById(this.props.videoid);
    getCurrentTime = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        if (this.state.currentTime === this.state.duration) {
          clearInterval(getCurrentTime);
        }
      }
    }, 100);
  };

  getVideoData = () => {
    const video = document.getElementById(this.props.videoid);
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.videoLoaded);
      return video.duration;
    }
    return null;
  };

  videoLoaded = () => {
    const video = document.getElementById(this.props.videoid);
    if (this.state.duration === "initial") {
      this.setState({
        duration: parseFloat(video.duration.toFixed(1)),
      });
      this.updateTime();
    }
  };

  render() {
    return (
      <div className="quotes" id="mike-quote-two">
        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.videoid)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#B6CAE1"
          endColor="#4179BD"
        />
      </div>
    );
  }
}

export default MikesQuoteVideoTwo;
