import { API_URL_POSTS } from "../api/api";
import postsData from "../data/postsData";

export const initialized = () => ({ type: "INITIALIZED" });

// Request data from WP API.
export const requestPosts = () => {
  return {
    type: "REQUEST_POSTS"
  };
};

// Receive  data from WP API.
export const receivePosts = json => {
  return {
    type: "RECEIVE_POSTS",
    posts: json,
    receivedAt: Date.now()
  };
};

//
// export const fetchGPArticleData = () => async dispatch => {
//   dispatch(requestGPArticleData([]));
//   try {
//     const response = await fetch(API_URL_PAGES);
//     if (response) {
//       dispatch(receiveGPArticleData(response.json()));
//     }
//   } catch (error) {
//     console.log("An error occurred.", error);
//   }
// };

// export const fetchPosts = () => {
//   return dispatch => {
//     dispatch(requestPosts());
//     return fetch(API_URL_POSTS)
//       .then(
//         response => response.json(),
//         error => console.log("An error occurred.", error)
//       )
//       .then(json => dispatch(receivePosts(json)));
//   };
// };

/**
 * Simulate fetching posts from WP API, but using local data instead.
 */
export const fetchPosts = () => {
  return dispatch => {
    dispatch(requestPosts());
    setTimeout(() => {
      dispatch(receivePosts(postsData));
    }, 100);
  };
};
