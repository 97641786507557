import React from "react";
// import { DragSource } from "react-dnd";
import { useDrag } from "react-dnd";

import { isMobile } from "react-device-detect";

// const boxLVSource = {
//   beginDrag(props, monitor, component) {
//     const item = { id: props.id };
//     return item;
//   },

//   endDrag(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       return;
//     }
//     return props.handleDrop(props.item.id);
//   },
// };

// function collect(connect, monitor) {
//   return {
//     connectDragSource: connect.dragSource(),
//     isDragging: monitor.isDragging(),
//   };
// }

// class BoxLevelUp extends React.Component {
//   render() {
//     const { isDragging, connectDragSource, item } = this.props;
//     const classEl = isDragging ? " dragging" : "";
//     let opacity = isDragging ? 0 : 1;
//     if (isMobile) {
//       opacity = 1;
//     }

//     return connectDragSource(
//       <div
//         className={`box-level-up--item${classEl}`}
//         id={`box${item.id}`}
//         style={{ opacity }}
//         title={item.name}
//       >
//         {item.name}
//       </div>
//     );
//   }
// }

// export default DragSource("box-level-up", boxLVSource, collect)(BoxLevelUp);

export const BoxLevelUp = ({ boxItem, handleDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name: boxItem.name, type: "box" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      console.log("dropped", item, monitor);
      if (item && isMobile) {
        handleDrop(boxItem.id);
      } else {
        if (item && dropResult) {
          handleDrop(boxItem.id);
          // alert(`You dropped ${item.name} into ${dropResult.name}!`);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const classEl = isDragging ? " dragging" : "";
  let opacity = isDragging ? 0 : 1;
  if (isMobile) {
    opacity = 1;
  }
  return (
    <div
      ref={drag}
      className={`box-level-up--item${classEl}`}
      id={`box${boxItem.id}`}
      style={{ opacity }}
      title={boxItem.name}
    >
      {boxItem.name}
    </div>
  );
};

export default BoxLevelUp;
