export const getArticleData = (state, articleID) => {
  const posts = state.posts;
  if (posts) {
    const article = posts.filter(post => {
      if (post.id === articleID) {
        return {
          title: post.title.rendered,
          segments: [...post.acf.article_components]
        };
      }
      return null;
    });
    return article[0];
  }
  return null;
};
