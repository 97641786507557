import React, { Component } from "react";

import "./VideoControls.scss";

class Replay extends Component {
  render() {
    return (
      <React.Fragment>
        <svg
          width="21px"
          height="24px"
          viewBox="0 0 21 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          id="replay"
        >
          <g
            id="exports"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="replay-icon" fill="#FFFFFF" fillRule="nonzero">
              <path
                d="M20.1985242,12.7711351 C19.9018736,7.77751598 15.8476481,3.69856966 10.854029,3.32775635 L10.854029,0.509575257 C10.854029,0.0893201807 10.3596112,-0.157888687 10.0382397,0.114041068 L4.30299397,4.66268424 C4.05578511,4.86045134 4.05578511,5.25598553 4.30299397,5.45375262 L10.0382397,9.97767491 C10.3596112,10.2496047 10.854029,10.0023958 10.854029,9.58214072 L10.854029,6.86284317 C14.3643949,7.25837736 17.0342507,10.4473718 16.6387165,14.1307839 C16.317345,17.1467321 13.8699772,19.5940999 10.854029,19.9154714 C7.09645419,20.3357265 3.85801801,17.5669872 3.53664648,13.9577377 C3.5119256,13.6363662 3.23999584,13.3891573 2.91862431,13.3891573 L0.619581839,13.3891573 C0.248768536,13.3891573 -0.0231612187,13.6858079 0.0015596681,14.0566212 C0.37237297,19.3221701 4.74796994,23.5 10.1124024,23.5 C15.872369,23.5 20.5446166,18.6299853 20.1985242,12.7711351 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </svg>
      </React.Fragment>
    );
  }
}

export default Replay;
