const LeonGame = (
  parent,
  app,
  Spine,
  leonScene,
  resources,
  videoWidth,
  videoHeight,
  scale,
  video,
  getCurrentTime,
  canvasWrap,
  startGame,
  videoPartEnd,
  positionX,
  positionY,
  screenWidth,
  audio
) => {
  resources = resources.resources;

  const spoon = new Spine(resources.spoon.spineData);
  const baby = new Spine(resources.baby.spineData);
  const texture = new Spine(resources.texture.spineData);
  const bg = new Spine(resources.bg.spineData);
  const controller = new Spine(resources.controller.spineData);

  const controllerEnter = new Audio(resources.controllerEnter.url);
  // const gameLoadInOut = new Audio(resources.gameLoadInOut.url);
  // const gameLoadIn = new Audio(resources.gameLoadIn.url);
  const buttonPress = new Audio(resources.buttonPress.url);

  audio.cry1.src = resources.cry1.url;
  audio.cry2.src = resources.cry2.url;
  audio.loopBed.src = resources.loopBed.url;
  audio.babyAppear.src = resources.babyAppear.url;
  audio.coo1.src = resources.coo1.url;
  audio.coo2.src = resources.coo2.url;
  audio.mouthSound.src = resources.mouthSound.url;
  audio.spoonAppear.src = resources.spoonAppear.url;
  audio.leonResultPositive.src = resources.leonResultPositive.url;
  audio.leonResultNegative.src = resources.leonResultNegative.url;

  // Z-index
  app.stage.sortableChildren = true;
  controller._zIndex = 5;
  texture._zIndex = 3;
  spoon._zIndex = 2;
  baby._zIndex = 1;
  bg._zIndex = -5;

  // Position
  bg.position.set(videoWidth / 2, videoHeight / 2);
  bg.scale.set(scale, scale);
  texture.position.set(videoWidth / 2, videoHeight / 2);
  texture.scale.set(scale, scale);
  spoon.position.set(videoWidth / 2, videoHeight / 2);
  spoon.scale.set(scale, scale);
  baby.position.set(videoWidth / 2, videoHeight / 2);
  baby.scale.set(scale, scale);
  controller.position.set(videoWidth / 2, videoHeight / 2);
  controller.scale.set(scale, scale);

  if (screenWidth < 800) {
    controller.scale.set(scale * 3.8, scale * 3.8);
    controller.position.set(videoWidth / 2, -Math.abs(videoHeight / 1.15));
  }

  // Add elements to the Scene
  leonScene.addChild(bg);
  leonScene.addChild(texture);
  leonScene.addChild(baby);
  leonScene.addChild(spoon);
  leonScene.addChild(controller);

  // Add the scene to the stage
  app.stage.addChild(leonScene);

  window.addEventListener("resize", function () {
    videoHeight = video.offsetHeight;
    videoWidth = videoHeight * 1.3333333;
    positionX = videoWidth / 2;
    positionY = videoHeight / 2;
    scale = videoHeight / 2160;
    spoon.position.set(positionX, positionY);
    spoon.scale.set(scale, scale);
    baby.position.set(positionX, positionY);
    baby.scale.set(scale, scale);
    texture.position.set(positionX, positionY);
    texture.scale.set(scale, scale);
    bg.position.set(positionX, positionY);
    bg.scale.set(scale, scale);
    controller.position.set(positionX, positionY);
    controller.scale.set(scale, scale);
    if (screenWidth < 800) {
      controller.scale.set(scale * 3.8, scale * 3.8);
      controller.position.set(videoWidth / 2, -Math.abs(videoHeight / 1.15));
    }
  });
  getCurrentTime = setInterval(() => {
    if (parent.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
      parent.setState({
        currentTime: parseFloat(video.currentTime.toFixed(1)),
        duration: parseFloat(video.duration.toFixed(1)),
      });
    }
    if (parent.state.currentTime === startGame) {
      parent.setState({ videoControls: false });

      audio.babyAppear.play();
      // Start soundtrack loop
      audio.loopBed.addEventListener(
        "ended",
        function () {
          this.currentTime = 0;
          this.play();
        },
        false
      );
      audio.babyAppear.addEventListener(
        "ended",
        function () {
          if (parent.state.leonFinished !== true) audio.loopBed.play();
        },
        false
      );

      // Show Scene
      leonScene.visible = true;
      // Set inital animations
      bg.state.setAnimation(0, "appear", false);
      texture.state.setAnimation(0, "appear", true);
      baby.state.setAnimation(0, "appear", false);
      spoon.state.setAnimation(0, "appear", false);
      controller.state.setAnimation(0, "appear", true);

      canvasWrap.classList.remove("disabled");
    } else if (parent.state.currentTime === videoPartEnd) {
      video.pause();

      clearInterval(getCurrentTime);
    }
  }, 50);

  const onButtonDown = () => {
    spoon.state.setAnimation(0, "feed", false);
    controller.state.setAnimation(0, "tap", false);
  };

  controller.interactive = true;
  controller.on("mousedown", onButtonDown).on("touchstart", onButtonDown);

  const that = parent;

  // To get details of the event
  //console.log("SKELETON", event, entry, event.data.name);

  bg.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        bg.state.setAnimation(0, "idle", true);
        that.setState({ leonBg: "idle" });
      }
    },
  });

  baby.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        baby.state.setAnimation(0, "idle", true);
        that.setState({ baby: "idle" });
      } else if (event.data.name === "canCry") {
        if (that.state.controller !== null) {
          if (Math.random() < 0.4) {
            baby.state.setAnimation(0, "cry", false);
            that.setState({ baby: "cry" });
          }
        }
      } else if (event.data.name === "mouthIsOpen") {
        if (event.stringValue === "true") {
          if (Math.random() < 0.5) audio.cry1.play();
          else audio.cry2.play();
          that.setState({ baby: "mouthIsOpen" });
        } else if (event.stringValue === "false") {
          that.setState({ baby: "mouthIsNotOpen" });
        }
      } else if (event.data.name === "BabyCoo02") {
        if (Math.random() < 0.5) audio.coo1.play();
        else audio.coo2.play();
      } else if (event.data.name === "BabyEatMouthSound") {
        audio.mouthSound.play();
      } else if (event.data.name === "BabyAppearDisappearSwoosh") {
        audio.babyAppear.play();
      }
    },
  });

  spoon.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        spoon.state.setAnimation(0, "idle", true);
        that.setState({ spoon: "idle" });
      } else if (event.data.name === "spoonAtBaby") {
        if (that.state.baby === "mouthIsOpen") {
          spoon.state.setAnimation(0, "successAndExit", false);
          baby.state.setAnimation(0, "successAndExit", false);
          controller.state.setAnimation(0, "successAndExit", false);
          bg.state.setAnimation(0, "successAndExit", false);
          texture.state.setAnimation(0, "successAndExit", false);
        } else if (that.state.baby === "mouthIsNotOpen") {
          spoon.state.setAnimation(0, "fail", false);
          baby.state.setAnimation(0, "fail", false);
        }
      } else if (event.data.name === "playVideo") {
        video.play();
        that.setState({
          videoControls: true,
          leonFinished: true,
        });
        canvasWrap.classList.add("disabled");
        audio.loopBed.pause();
      } else if (event.data.name === "unloadAssets") {
        leonScene.visible = false;
      } else if (event.data.name === "SpoonAppearSwoosh") {
        audio.spoonAppear.play();
      } else if (event.data.name === "SpoonNotInMouthNegativeResult") {
        audio.leonResultNegative.play();
      } else if (event.data.name === "SpoonInMouthPositiveResult") {
        audio.leonResultPositive.play();
      }
    },
  });

  controller.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        controller.state.setAnimation(0, "idle", true);
        that.setState({ controller: "idle" });
      } else if (event.data.name === "ControllerSlideIn") {
        controllerEnter.play();
      } else if (event.data.name === "MainButtonPress") {
        buttonPress.play();
      }
    },
  });

  texture.state.addListener({
    event: function (entry, event) {
      if (event.data.name === "playIdle") {
        texture.state.setAnimation(0, "idle", true);
        that.setState({ texture: "idle" });
      }
    },
  });
};

export default LeonGame;
