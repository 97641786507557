import React, { Component } from "react";

import "./GameTease.scss";

class GameTease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      endPrompt: false,
    };
  }
  componentDidMount() {
    this.onScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollFunction);
  }

  onScroll = () => {
    window.addEventListener("scroll", this.scrollFunction);
  };

  scrollFunction = () => {
    if (!this.state.article) {
      this.setState({ article: document.getElementById("article") });
    } else {
      const articleOffset = this.state.article.offsetTop;
      const articleHeight = this.state.article.clientHeight;

      if (window.scrollY > articleOffset + articleHeight - 100) {
        console.log("endpromt applied");
        if (!this.state.endPrompt) {
          this.setState({ endPrompt: true });
        }
      } else {
        this.setState({ endPrompt: false });
      }
    }
  };

  render() {
    return (
      <a
        className={`game-tease ${this.props.story}${
          this.props.scrolled && !this.state.endPrompt ? " scrolled" : ""
        }${this.state.endPrompt ? " end-prompt" : ""}${
          this.props.mobile ? " mobile" : " desktop"
        }`}
        id="game-tease"
        href={this.props.link}
      >
        <div className="video-wrap">
          <video src={this.props.video} playsInline muted autoPlay />
        </div>
        <div className="cta">
          <div className="text-wrap">
            <div className="label">Mini Game</div>
            <div className="title">{this.props.title}</div>
            <div className="text">{this.props.text}</div>
          </div>
          <div className="arrow">
            <svg
              width="42px"
              height="43px"
              viewBox="0 0 42 43"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Design"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="square"
              >
                <g
                  id="Desktop-HD-Copy-62"
                  transform="translate(-1305.000000, -574.000000)"
                  stroke="#FFFFFF"
                  strokeWidth="3"
                >
                  <g
                    id="arrow-cta"
                    transform="translate(1306.000000, 577.000000)"
                  >
                    <path
                      d="M0.611193296,18 L37.5256622,18"
                      id="Line-7-Copy"
                    ></path>
                    <polyline
                      id="Path-3-Copy"
                      points="23 0 38.1484375 18.7410723 23 37"
                    ></polyline>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </a>
    );
  }
}

export default GameTease;
