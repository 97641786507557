import {
  generalAssetPaths,
  leonAssetPaths,
  ducksAssetPaths,
  makeupAssetPaths
} from "./fileManager";

export const generalAssets = [
  {
    name: "controllerEnter",
    url: generalAssetPaths("ControllerSlideIn.mp3")
  },
  {
    name: "gameLoadInOut",
    url: generalAssetPaths("GameLoadIn&OutSwoosh.mp3")
  },
  {
    name: "gameLoadIn",
    url: generalAssetPaths("GameLoadInSwoosh.mp3")
  },
  {
    name: "buttonPress",
    url: generalAssetPaths("MainButtonPress.mp3")
  }
];

export const leonAssets = [
  {
    name: "spoon",
    url: leonAssetPaths("spoon.json")
  },
  {
    name: "baby",
    url: leonAssetPaths("baby.json")
  },
  {
    name: "texture",
    url: leonAssetPaths("texture.json")
  },
  {
    name: "bg",
    url: leonAssetPaths("bg.json")
  },
  {
    name: "controller",
    url: leonAssetPaths("controller.json")
  },
  {
    name: "loopBed",
    url: leonAssetPaths("audio/BabyMusicTrackLoop.mp3")
  },
  {
    name: "cry1",
    url: leonAssetPaths("audio/BabyCry01.mp3")
  },
  {
    name: "cry2",
    url: leonAssetPaths("audio/BabyCry02.mp3")
  },
  {
    name: "babyAppear",
    url: leonAssetPaths("audio/BabyAppearDisappearSwoosh.mp3")
  },
  {
    name: "coo1",
    url: leonAssetPaths("audio/BabyCoo01.mp3")
  },
  {
    name: "coo2",
    url: leonAssetPaths("audio/BabyCoo02.mp3")
  },
  {
    name: "mouthSound",
    url: leonAssetPaths("audio/BabyEatMouthSound.mp3")
  },
  {
    name: "spoonAppear",
    url: leonAssetPaths("audio/SpoonAppearSwoosh.mp3")
  },
  {
    name: "leonResultPositive",
    url: leonAssetPaths("audio/SpoonInMouthPositiveResult.mp3")
  },
  {
    name: "leonResultNegative",
    url: leonAssetPaths("audio/SpoonNotInMouthNegativeResult.mp3")
  }
];

export const ducksAssets = [
  {
    name: "bgDuck",
    url: ducksAssetPaths("bg.json")
  },
  {
    name: "forestBack",
    url: ducksAssetPaths("forest_back.json")
  },
  {
    name: "forestFront",
    url: ducksAssetPaths("forest_front.json")
  },
  {
    name: "hotspot1",
    url: ducksAssetPaths("hotspot_01.json")
  },
  {
    name: "hotspot2",
    url: ducksAssetPaths("hotspot_02.json")
  },
  {
    name: "hotspot3",
    url: ducksAssetPaths("hotspot_03.json")
  },
  {
    name: "hotspot4",
    url: ducksAssetPaths("hotspot_04.json")
  },
  {
    name: "hotspot5",
    url: ducksAssetPaths("hotspot_05.json")
  },
  {
    name: "textureDuck",
    url: ducksAssetPaths("texture.json")
  },
  {
    name: "controllerDuck",
    url: ducksAssetPaths("controller.json")
  },
  {
    name: "transitionDuck",
    url: ducksAssetPaths("transition.json")
  },
  {
    name: "duckCount1",
    url: ducksAssetPaths("ui_duck_01.json")
  },
  {
    name: "duckCount2",
    url: ducksAssetPaths("ui_duck_02.json")
  },
  {
    name: "duckCount3",
    url: ducksAssetPaths("ui_duck_03.json")
  },
  {
    name: "UI1",
    url: ducksAssetPaths("audio/DuckUIAppear01.wav")
  },
  {
    name: "UI2",
    url: ducksAssetPaths("audio/DuckUIAppear02.wav")
  },
  {
    name: "UI3",
    url: ducksAssetPaths("audio/DuckUIAppear03.wav")
  },
  {
    name: "inBush",
    url: ducksAssetPaths("audio/AppearInBush.mp3")
  },
  {
    name: "inPond",
    url: ducksAssetPaths("audio/AppearInPond.mp3")
  },
  {
    name: "crocGrowl",
    url: ducksAssetPaths("audio/CrocGrowl.mp3")
  },
  {
    name: "ducksTrackLoop",
    url: ducksAssetPaths("audio/DuckMusicTrackLoop.mp3")
  },
  {
    name: "duckQuack",
    url: ducksAssetPaths("audio/DuckQuack.mp3")
  },
  {
    name: "leaveHeads",
    url: ducksAssetPaths("audio/DucksHeadsLeaveAtStart.mp3")
  },
  {
    name: "showHeads",
    url: ducksAssetPaths("audio/DucksShowHeadsAtStart.mp3")
  },
  {
    name: "frogRibbet",
    url: ducksAssetPaths("audio/FrogRibbet.mp3")
  },
  {
    name: "ducksResultNegative",
    url: ducksAssetPaths("audio/NegativeResult.mp3")
  },
  {
    name: "ducksResultPositive",
    url: ducksAssetPaths("audio/PositiveResult.mp3")
  }
];

export const makeupAssets = [
  {
    name: "hearts",
    url: makeupAssetPaths("hearts.json")
  },
  {
    name: "lipstick",
    url: makeupAssetPaths("lipstick.json")
  },
  {
    name: "lorii",
    url: makeupAssetPaths("lorri.json")
  },
  {
    name: "mouthShapes",
    url: makeupAssetPaths("mouthShapes.json")
  },
  {
    name: "smudge01",
    url: makeupAssetPaths("smudge_01.json")
  },
  {
    name: "smudge02",
    url: makeupAssetPaths("smudge_02.json")
  },
  {
    name: "smudge03",
    url: makeupAssetPaths("smudge_03.json")
  },
  {
    name: "smudge04",
    url: makeupAssetPaths("smudge_04.json")
  },
  {
    name: "smudge05",
    url: makeupAssetPaths("smudge_05.json")
  },
  {
    name: "smudge06",
    url: makeupAssetPaths("smudge_06.json")
  },
  {
    name: "smudge07",
    url: makeupAssetPaths("smudge_07.json")
  },
  {
    name: "smudge08",
    url: makeupAssetPaths("smudge_08.json")
  },
  {
    name: "smudge09",
    url: makeupAssetPaths("smudge_09.json")
  },
  {
    name: "smudge10",
    url: makeupAssetPaths("smudge_10.json")
  },
  {
    name: "smudge11",
    url: makeupAssetPaths("smudge_11.json")
  },
  {
    name: "smudge12",
    url: makeupAssetPaths("smudge_12.json")
  },
  {
    name: "transitionMakeup",
    url: makeupAssetPaths("transition.json")
  },
  {
    name: "textureMakeup",
    url: makeupAssetPaths("texture.json")
  },
  {
    name: "bgMakeup",
    url: makeupAssetPaths("bg.json")
  },
  {
    name: "controllerMakeup",
    url: makeupAssetPaths("controller.json")
  },
  {
    name: "winSound",
    url: makeupAssetPaths("audio/HeartWinSound.mp3")
  },
  {
    name: "uhOh1",
    url: makeupAssetPaths("audio/UhOh01.wav")
  },
  {
    name: "uhOh2",
    url: makeupAssetPaths("audio/UhOh02.wav")
  },
  {
    name: "onSwish",
    url: makeupAssetPaths("audio/LippyOnSwish.mp3")
  },
  {
    name: "cuteLaugh1",
    url: makeupAssetPaths("audio/LipsOpenCuteLaugh_01.mp3")
  },
  {
    name: "cuteLaugh2",
    url: makeupAssetPaths("audio/LipsOpenCuteLaugh_02.mp3")
  },
  {
    name: "makeupTrackLoop",
    url: makeupAssetPaths("audio/MakeUpMusicTrackLoop.mp3")
  },
  {
    name: "makeupResultNegative",
    url: makeupAssetPaths("audio/NegativeResult.mp3")
  }
];
