let listeners = [];
let isWindowListenersActive = false;
let isRequestFrameAnimationCalled = false;

export const onScroll = (cb) => {
  listeners.push(cb);
  updateOnListenersChanged();

  return () => {
    listeners = listeners.filter(fn => fn !== cb);
    updateOnListenersChanged();
  };
};

function updateOnListenersChanged() {
  switch (true) {
    case !isWindowListenersActive && listeners.length > 0:
      isWindowListenersActive = true;
      window.addEventListener('scroll', scheduleUpdate, false);
      break;
    case isWindowListenersActive && listeners.length === 0:
      isWindowListenersActive = false;
      window.removeEventListener('scroll', scheduleUpdate, false);
      break;
  }
}

function notifyListeners() {
  listeners.forEach(fn => fn());
}

function scheduleUpdate() {
  if (isRequestFrameAnimationCalled) {
    return;
  }
  isRequestFrameAnimationCalled = true;
  requestAnimationFrame(() => {
    isRequestFrameAnimationCalled = false;
    notifyListeners();
  });
}
