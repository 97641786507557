import React from "react";
import Loading from "../Story/Loading";

const NotFound = () => (
  <div className="not-found">
    <div className="error">
      <Loading />
      <p>Page not found</p>
    </div>
  </div>
);

export default NotFound;
