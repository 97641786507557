import React, { Component } from 'react';

import './Loading.scss'

class Loading extends Component {
  render() {
    return (
      <div className="loading">
        <div className="pac">
        <div className="pac-inner"></div>
        
      </div>
      </div>
    );
  }
}

export default Loading;