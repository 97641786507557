import React, { Component } from "react";

let owidth,
  oheight,
  twidth,
  theight,
  fx1,
  fy1,
  fx2,
  fy2,
  width,
  height,
  top,
  left;

class ImageFocus extends Component {
  constructor() {
    super();

    this.state = {
      height: null,
      width: null,
      left: null,
      top: null,
      updated: false,
    };
  }
  componentDidMount() {
    // const img = new Image();
    // img.src = this.props.src;
  }

  handleImageLoaded = () => {
    const image = this.refs[this.props.imageId];
    const parent = this.refs[`${this.props.imageId}-wrap`];
    this.imageFocus(image, parent);
    window.onresize = () => {
      this.imageFocus(image, parent);
    };
  };

  imageFocus = (image, parent) => {
    owidth = image.getBoundingClientRect().width;
    oheight = image.getBoundingClientRect().height;
    twidth = parent.getBoundingClientRect().width;
    theight = parent.getBoundingClientRect().height;
    fx1 = Number(this.props.focusLeft);
    fy1 = Number(this.props.focusTop);
    fx2 = Number(this.props.focusRight);
    fy2 = Number(this.props.focusBottom);
    if (owidth / oheight > twidth / theight) {
      // This means the parent's ratio is not wider than the image
      // So crop the sides of the image
      var fwidth = (fx2 - fx1) * owidth;
      if (fwidth / oheight > twidth / theight) {
        height = (oheight * twidth) / fwidth;
        width = (owidth * twidth) / fwidth;
        left = -fx1 * width;
        top = (theight - height) / 2;
      } else {
        height = theight;
        width = (theight * owidth) / oheight;
        left = twidth / 2 - ((fx1 + fx2) * width) / 2;
        // if left > 0, it will leave blank on the left, so set it to 0;
        left = left > 0 ? 0 : left;
        // if width - left < twidth, it will leave blank on the right, so set left = width - twidth
        left = twidth - left - width > 0 ? twidth - width : left;
        top = 0;
      }
    } else {
      // This means the parent's ratio is wider than the image
      // So crop the top and bottom of the image
      var fheight = (fy2 - fy1) * oheight;
      if (fheight / owidth > theight / twidth) {
        width = (owidth * theight) / fheight;
        height = (oheight * theight) / fheight;
        top = -fy1 * height;
        left = (twidth - width) / 2;
      } else {
        width = twidth;
        height = (twidth * oheight) / owidth;
        top = theight / 2 - ((fy1 + fy2) * height) / 2;
        // if top > 0, it will leave blank on the top, so set it to 0;
        top = top > 0 ? 0 : top;
        // if height - top < theight, it will leave blank on the bottom, so set top = height - theight
        top = theight - top - height > 0 ? theight - height : top;
        left = 0;
      }
    }
    this.setState({
      height: height,
      width: width,
      left: left,
      top: top,
      updated: true,
    });
  };

  getStyles = () => {
    if (this.state.updated) {
      return {
        position: "relative",
        height: this.state.height,
        width: this.state.width,
        left: this.state.left,
        top: this.state.top,
        visibility: "visible",
      };
    }
  };
  render() {
    return (
      <div
        id="imgMainScene"
        className="img-wrap"
        ref={`${this.props.imageId}-wrap`}
      >
        <img
          id="focus-img"
          ref={this.props.imageId}
          src={this.props.src}
          style={this.getStyles()}
          alt={this.props.alt}
          onLoad={this.handleImageLoaded}
          data-transition={this.props.transition}
        />
      </div>
    );
  }
}

export default ImageFocus;
