import './styles.scss';

export function createSpinner() {
  let node = document.createElement('div');
  node.classList.add('frame-viewer-spinner');

  return {
    getNode,
    show,
    hide
  };

  function getNode() {
    return node;
  }

  function show() {
    node.classList.add('isVisible');
  }

  function hide() {
    node.classList.remove('isVisible');
  }
}
