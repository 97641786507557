import React from "react";
import { Link, withRouter } from "react-router-dom";
import Div100vh from "react-div-100vh";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import Facebook from "../App/Facebook.jsx";
import Twitter from "../App/Twitter.jsx";

import ImageFocus from "../ImageFocus/ImageFocus";
import FootballThreeContainer from "../Three/FootballThreeContainer";
import PuzzleThreeContainer from "../Three/PuzzleThreeContainer";
import BoxLevelUp from "./BoxLevelUp";
import BoxTarget from "./BoxTarget";

import { audioPaths } from "../../utils/mediaManager.js";

let overlayScene = null;
let imgMainScene = null;
let sceneImage = null;
var indexDrop = 1;

class Scene extends React.Component {
  constructor() {
    super();
    this.state = {
      transition: null,
      message: null,
      options: null,
      shotCount: 1,
      newProps: null,
    };
  }

  componentDidMount() {
    this.setState({
      message: this.props.text,
      options: this.props.options,
    });
    var boxLVP = document.getElementById("boxLVP");

    if (boxLVP && boxLVP.childNodes) {
      for (let i = 0; i < boxLVP.childNodes.length; i++) {
        if (i === 0) {
          var boxTarget = boxLVP.childNodes[0];
          var offsetLeftTarget = boxTarget.offsetLeft;
          var widthBoxTarget = boxTarget.offsetWidth;
        }
        if (boxLVP.childNodes[i].classList[0] === "box-level-up--item") {
          boxLVP.childNodes[i].addEventListener("touchmove", function (e) {
            // grab the location of touch
            var touchLocation = e.targetTouches[0];

            // assign box new coordinates based on the touch.
            var widthBox = boxLVP.childNodes[i].offsetWidth;
            var heightBox = boxLVP.childNodes[i].offsetHeight;
            boxLVP.childNodes[i].style.position = "absolute";
            boxLVP.childNodes[i].style.left =
              touchLocation.pageX - widthBox / 2 + "px";
            boxLVP.childNodes[i].style.top =
              touchLocation.pageY - heightBox / 2 + "px";

            if (
              (touchLocation.pageX - widthBox / 2 >= offsetLeftTarget &&
                touchLocation.pageX - widthBox / 2 <=
                  offsetLeftTarget + widthBoxTarget) ||
              (touchLocation.pageX - widthBox / 2 + widthBoxTarget >=
                offsetLeftTarget &&
                touchLocation.pageX - widthBox / 2 + widthBoxTarget <=
                  offsetLeftTarget + widthBoxTarget)
            ) {
              document.getElementById(
                "imgMainScene"
              ).childNodes[0].style.transform = "scale(1.2)";
            } else {
              document.getElementById(
                "imgMainScene"
              ).childNodes[0].style.transform = "scale(1)";
            }
            e.preventDefault();
          });
          boxLVP.childNodes[i].addEventListener("touchend", function (e) {
            var touchLocation = e.changedTouches[0];
            var widthBox = boxLVP.childNodes[i].offsetWidth;
            // var index = 1;

            if (
              (touchLocation.pageX - widthBox / 2 >= offsetLeftTarget &&
                touchLocation.pageX - widthBox / 2 <=
                  offsetLeftTarget + widthBoxTarget) ||
              (touchLocation.pageX - widthBox / 2 + widthBoxTarget >=
                offsetLeftTarget &&
                touchLocation.pageX - widthBox / 2 + widthBoxTarget <=
                  offsetLeftTarget + widthBoxTarget)
            ) {
              boxLVP.childNodes[i].style.display = "none";
              document.getElementById(
                "imgMainScene"
              ).childNodes[0].style.transform = "scale(1)";
            } else {
              if (i === 1) {
                boxLVP.childNodes[i].style.top = "20%";
                boxLVP.childNodes[i].style.left = "auto";
                boxLVP.childNodes[i].style.right = "70%";
              }
              if (i === 2) {
                boxLVP.childNodes[i].style.top = "20%";
                boxLVP.childNodes[i].style.left = "70%";
              }
              if (i === 3) {
                boxLVP.childNodes[i].style.top = "40%";
                boxLVP.childNodes[i].style.left = "auto";
                boxLVP.childNodes[i].style.right = "70%";
              }
              if (i === 4) {
                boxLVP.childNodes[i].style.top = "20%";
                boxLVP.childNodes[i].style.left = "70%";
              }
            }
          });
        }
      }
      this.audioController(this.props.sceneName);
    }

    if (this.props.three === "puzzle") {
      document.body.classList.add("no-bounce");
    } else {
      document.body.classList.remove("no-bounce");
    }

    overlayScene = document.getElementById("overlayScene");
    imgMainScene = document.getElementById("imgMainScene");
    if (imgMainScene) {
      sceneImage = imgMainScene.firstChild;
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("no-bounce");
  }

  componentDidUpdate(prevProps) {
    if (document.getElementsByClassName("buttons")[0]) {
      var btns = document.getElementsByClassName("buttons")[0].children;
      for (let i = 0; i < btns.length; i++) {
        btns[i].onclick = () => {
          new Audio(audioPaths("gameplan/ButtonClick.mp3")).play();
        };
      }
    }

    overlayScene = document.getElementById("overlayScene");
    imgMainScene = document.getElementById("imgMainScene");
    if (imgMainScene) {
      sceneImage = imgMainScene.firstChild;
    }

    if (
      this.props.text !== prevProps.text ||
      this.props.options !== prevProps.options
    ) {
      this.setState({
        message: this.props.text,
        options: this.props.options,
      });
    }
    if (this.props.sceneName !== prevProps.sceneName) {
      this.audioController(this.props.sceneName);
    }

    if (this.props.options !== prevProps.options) {
      this.props.options.map((option, i) => {
        if (option.transition) {
          if (this.state.transition !== option.transition) {
            this.setState({
              transition: option.transition,
            });
          }
        }
      });
    }
  }

  audioController = (sceneName) => {
    if (
      sceneName === "1" ||
      sceneName === "2" ||
      sceneName === "2-1" ||
      sceneName === "2-2" ||
      sceneName === "9" ||
      sceneName === "10" ||
      sceneName === "11" ||
      sceneName === "11-1" ||
      sceneName === "11-2"
    ) {
      // If office ambience isn't already playing
      // Play office ambience
      if (this.props.OfficeAmbience && this.props.OfficeAmbience.paused) {
        this.props.startLoop(this.props.OfficeAmbience);
      }
    } else if (sceneName === "12") {
      if (this.props.OfficeAmbience && !this.props.OfficeAmbience.paused) {
        this.props.OfficeAmbience.pause();
      }
    } else if (sceneName === "13") {
      if (this.props.PartyTimeAmbience && this.props.PartyTimeAmbience.paused) {
        this.props.startLoop(this.props.PartyTimeAmbience);
      }
    } else if (sceneName === "14" || sceneName === "14-1") {
      if (
        this.props.PartyTimeAmbience &&
        !this.props.PartyTimeAmbience.paused
      ) {
        this.props.PartyTimeAmbience.pause();
      }
      if (
        this.props.GovernmentAmbience &&
        this.props.GovernmentAmbience.paused
      ) {
        this.props.startLoop(this.props.GovernmentAmbience);
      }
    } else if (sceneName === "14-3") {
      if (
        this.props.GovernmentAmbience &&
        !this.props.GovernmentAmbience.paused
      ) {
        this.props.GovernmentAmbience.pause();
      }
    }
  };

  onClick = () => {
    if (overlayScene) {
      overlayScene.style.transform = "translateY(70px)";
      overlayScene.style.opacity = 0;
      setTimeout(() => {
        overlayScene.style.transform = "translateY(0)";
        overlayScene.style.opacity = 1;
      }, 2000);
    }

    this.props.transitionTo(this.state.transition);
  };

  onClick2 = () => {
    this.props.transitionTo(this.state.transition + " noAnimate");
  };

  sceneTransition = (img) => {
    img.classList.remove("large");
    img.classList.remove("zoom-in");
    img.classList.add("zoom-out");
    setTimeout(() => {
      img.classList.add("large");
      img.classList.remove("zoom-out");
    }, 550);
    setTimeout(() => {
      img.classList.add("zoom-in");
    }, 600);
  };

  onClick3 = (path) => {
    console.log(path, document.querySelectorAll("audio"), this.props.options);
    if (document.querySelectorAll("audio") && this.props.options) {
      // const audio = document.querySelectorAll("audio");
      // const link = this.props.options[0].link;
      // const img = document.getElementById("imgMainScene");
      const img = document.getElementById("focus-img");
      const overlayScene = document.getElementById("overlayScene");

      if (overlayScene) {
        overlayScene.style.transform = "translateY(70px)";
        overlayScene.style.opacity = 0;
        setTimeout(() => {
          overlayScene.style.transform = "translateY(0)";
          overlayScene.style.opacity = 1;
          this.props.history.push(path);
        }, 550);
      }

      if (img) {
        this.sceneTransition(img);
      }
    }
  };

  dropItem = (id) => {
    var itemBox = document.getElementById("box" + id);
    itemBox.style.display = "none";
    if (indexDrop > 3) indexDrop = 1;
    this.props.transitionTo("levelUp" + indexDrop);

    if (indexDrop === 3) {
      const newOptions = [{ ...this.state.options[0], label: "Continue" }];
      this.setState({
        message: "Great! Now Mike has the skills to build his game.",
        options: newOptions,
      });
    }
    indexDrop++;

    // var overlayScene = document.getElementById("overlayScene");
    // var newItem = document.createElement("p");
    // var textnode = document.createTextNode(itemBox.innerText);
    // newItem.appendChild(textnode);
    // newItem.style.margin = 0;
    // overlayScene.insertBefore(newItem, overlayScene.childNodes[0]);
  };

  updateMessage = (message) => {
    let messageText = "";
    let newOptions = "";
    if (message === "success") {
      messageText = "Well Done!";
      newOptions = [
        { label: "Try the puzzle?", link: "4", className: "secondary" },
        { label: "Continue", link: "5" },
      ];
    } else if (message === "fail") {
      messageText = "Sorry, No Luck!";
      newOptions = [
        { label: "Try Again?", link: "3-1", className: "secondary" },
        { label: "Continue", link: "5" },
      ];
    } else if (message === "puzzleSuccess") {
      messageText = "Well Done!";
      newOptions = [{ label: "Continue", link: "5" }];
    }
    this.setState({
      message: messageText,
      options: newOptions,
    });
  };

  shotCounter = () => {
    this.setState({ shotCount: this.state.shotCount + 1 });
  };

  render() {
    return (
      <Div100vh className="scene">
        {this.props.three && this.props.three === "football" && (
          <FootballThreeContainer
            updateMessage={this.updateMessage}
            shotCounter={this.shotCounter}
          />
        )}
        {this.props.three && this.props.three === "puzzle" && (
          <PuzzleThreeContainer updateMessage={this.updateMessage} />
        )}
        {!this.props.three && (
          <ImageFocus
            src={this.props.src}
            imageId={this.props.imageId}
            focusLeft={this.props.focusLeft}
            focusTop={this.props.focusTop}
            focusRight={this.props.focusRight}
            focusBottom={this.props.focusBottom}
            alt={this.props.alt}
            transition={this.props.noTransition}
          />
        )}

        {/* {!this.props.three && this.state.newProps && (
          <ImageFocus
            src={this.state.newProps.src}
            imageId={this.state.newProps.imageId}
            focusLeft={this.state.newProps.focusLeft}
            focusTop={this.state.newProps.focusTop}
            focusRight={this.state.newProps.focusRight}
            focusBottom={this.state.newProps.focusBottom}
            alt={this.state.newProps.alt}
            transition={this.state.newProps.noTransition}
          />
        )} */}
        {/* <div className="top-controls">
          {this.props.back &&
            this.props.back !== null &&
            this.props.back !== "1" && (
              <Link to={`/gameplan/game/scene/${this.props.back}`}>back</Link>
            )}
          {this.props.back === "1" && <Link to={`/gameplan/game`}>back</Link>}
        </div> */}
        <div id="overlayScene" className={`overlay ${this.props.three}`}>
          {this.props.three &&
            this.props.three === "football" &&
            this.state.shotCount < 4 && (
              <div className="counter">Shot {this.state.shotCount} of 3</div>
            )}
          <div className="message">{this.state.message}</div>
          {this.state.options && (
            <div className="buttons">
              {this.state.options.map((option, i) => {
                if (option.transition) {
                  if (option.link === "9") {
                    return (
                      <div
                        key={i}
                        to={`/gameplan/game/scene/10`}
                        onClick={this.onClick2}
                        className="transition-btn"
                      >
                        {option.label}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={i}
                        to={`/gameplan/game/scene/${option.link}`}
                        onClick={this.onClick}
                        className="transition-btn"
                      >
                        {option.label}
                      </div>
                    );
                  }
                } else if (option.link === "article-link") {
                  return (
                    <Link key={i} to={`/gameplan#the-article`}>
                      {option.label}
                    </Link>
                  );
                } else if (option.link === "share") {
                  return (
                    <div className="sharing">
                      <div className="label">{option.label}</div>
                      <div className="shares">
                        <FacebookShareButton
                          url="https://www.gamechange.nz/gameplan/game"
                          quote="Can you make it as a video game startup?"
                        >
                          <Facebook />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url="https://www.gamechange.nz/gameplan/game"
                          title="Can you make it as a video game startup? Game Change: Play three interactive stories exploring NZ's gaming industry @radionz"
                        >
                          <Twitter />
                        </TwitterShareButton>
                      </div>
                    </div>
                  );
                }
                return (
                  // <Link
                  //   key={i}
                  //   to={`/gameplan/game/scene/${option.link}`}
                  //   onClick={this.onClick3}
                  //   className={option.className}
                  // >
                  //   {option.label}
                  // </Link>
                  <div
                    key={i}
                    onClick={() =>
                      this.onClick3(`/gameplan/game/scene/${option.link}`)
                    }
                    className={`transition-btn ${option.className}`}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {this.props.boxes && (
          <div className="box-level-up" id="boxLVP">
            {this.props.boxes && <BoxTarget />}
            {this.props.boxes &&
              this.props.boxes.map((item, index) => {
                return (
                  <BoxLevelUp
                    boxItem={item}
                    key={item.id}
                    handleDrop={(id) => this.dropItem(id)}
                  />
                );
              })}
          </div>
        )}
      </Div100vh>
    );
  }
}

export default withRouter(Scene);
