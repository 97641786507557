import React, { Component } from "react";

import { HumphreySound1 } from "../../utils/fileManager";
import QuoteVideo from "../../components/Article/QuoteVideo";

import { getDelay } from "../../utils/utils";

import Replay from "../Video/Replay";

const Timings = [
  {
    start: 0.08,
    stop: 0.3,
    delay: 0.1,
    word: "The",
  },
  {
    start: 0.39,
    stop: 0.81,
    delay: 0.1,
    word: "easiest",
  },
  {
    start: 0.91,
    stop: 1.12,
    delay: 0.1,
    word: "way",
  },
  {
    start: 1.38,
    stop: 1.53,
    delay: 0.3,
    word: "for",
  },
  {
    start: 1.54,
    stop: 1.59,
    delay: 0,
    word: "a",
  },
  {
    start: 1.69,
    stop: 1.93,
    delay: 0.1,
    word: "wound",
  },
  {
    start: 1.98,
    stop: 2.06,
    delay: 0.1,
    word: "to",
  },
  {
    start: 2.22,
    stop: 2.44,
    delay: 0.2,
    word: "heal",
  },
  {
    start: 2.84,
    stop: 3.0,
    delay: 0.4,
    word: "is",
  },
  {
    start: 3.17,
    stop: 3.4,
    delay: 0.2,
    word: "to",
  },
  {
    start: 3.8,
    stop: 4.01,
    delay: 0.4,
    word: "pull",
  },
  {
    start: 4.02,
    stop: 4.18,
    delay: 0,
    word: "the",
  },
  {
    start: 4.4,
    stop: 4.73,
    delay: 0.2,
    word: "tissue",
  },
  {
    start: 4.91,
    stop: 5.19,
    delay: 0.2,
    word: "beside",
  },
  {
    start: 5.2,
    stop: 5.26,
    delay: 0,
    word: "it",
  },
  {
    start: 5.55,
    stop: 5.82,
    delay: 0.3,
    word: "closer",
  },
  {
    start: 5.89,
    stop: 6.32,
    delay: 0.1,
    word: "together,",
  },
  {
    start: 6.94,
    stop: 7.02,
    delay: 0.6,
    word: "and",
  },
  {
    start: 7.05,
    stop: 7.16,
    delay: 0,
    word: "then",
  },
  {
    start: 7.22,
    stop: 7.38,
    delay: 0.1,
    word: "just",
  },
  {
    start: 7.7,
    stop: 8.28,
    delay: 0.3,
    word: "regenerate",
  },
  {
    start: 8.35,
    stop: 8.42,
    delay: 0.1,
    word: "a",
  },

  {
    start: 8.63,
    stop: 9.03,
    delay: 0.2,
    word: "smaller",
  },
  {
    start: 10.11,
    stop: 10.81,
    delay: 1.1,
    word: "cell - ",
  },
  {
    start: 11.19,
    stop: 11.25,
    delay: 0.4,
    word: "a",
  },
  {
    start: 11.43,
    stop: 11.69,
    delay: 0.2,
    word: "smaller",
  },
  {
    start: 11.73,
    stop: 11.89,
    delay: 0,
    word: "piece",
  },
  {
    start: 11.9,
    stop: 11.96,
    delay: 0,
    word: "of",
  },
  {
    start: 12.02,
    stop: 12.25,
    delay: 0.1,
    word: "skin,",
  },
  {
    start: 12.39,
    stop: 12.87,
    delay: 0.1,
    word: "basically - ",
  },
  {
    start: 13.64,
    stop: 13.74,
    delay: 0.8,
    word: "and",
  },
  {
    start: 13.75,
    stop: 13.86,
    delay: 0,
    word: "when",
  },
  {
    start: 13.9,
    stop: 13.96,
    delay: 0,
    word: "you",
  },

  {
    start: 14.01,
    stop: 14.1,
    delay: 0,
    word: "get",
  },
  {
    start: 14.25,
    stop: 14.5,
    delay: 0.2,
    word: "damage",
  },
  {
    start: 14.54,
    stop: 14.6,
    delay: 0,
    word: "on",
  },
  {
    start: 14.74,
    stop: 14.76,
    delay: 0.1,
    word: "the",
  },
  {
    start: 14.84,
    stop: 15.02,
    delay: 0.1,
    word: "palms",
  },
  {
    start: 15.13,
    stop: 15.21,
    delay: 0.1,
    word: "of",
  },
  {
    start: 15.32,
    stop: 15.4,
    delay: 0.1,
    word: "your",
  },
  {
    start: 15.5,
    stop: 15.81,
    delay: 0.1,
    word: "hands,",
  },
  {
    start: 15.92,
    stop: 16.0,
    delay: 0.1,
    word: "or",
  },
  {
    start: 16.04,
    stop: 16.36,
    delay: 0,
    word: "between",
  },
  {
    start: 16.42,
    stop: 16.52,
    delay: 0.1,
    word: "your",
  },
  {
    start: 16.65,
    stop: 17.04,
    delay: 0.1,
    word: "fingers,",
  },
  {
    start: 17.74,
    stop: 17.83,
    delay: 0.7,
    word: "it",
  },
  {
    start: 17.91,
    stop: 18.45,
    delay: 0.1,
    word: "just",
  },
  {
    start: 18.24,
    stop: 18.45,
    delay: -0.2,
    word: "pulls",
  },
  {
    start: 18.52,
    stop: 18.71,
    delay: 0.1,
    word: "them",
  },
  {
    start: 19.54,
    stop: 20.12,
    delay: 0.8,
    word: "slowly,",
  },
  {
    start: 20.28,
    stop: 20.63,
    delay: 0.2,
    word: "slowly,",
  },
  {
    start: 20.66,
    stop: 21.02,
    delay: 0.1,
    word: "slowly",
  },
  {
    start: 21.26,
    stop: 21.46,
    delay: 0.2,
    word: "pulls",
  },
  {
    start: 21.54,
    stop: 21.64,
    delay: 0.1,
    word: "them",
  },
  {
    start: 21.71,
    stop: 22.1,
    delay: 0.1,
    word: "together,",
  },
  {
    start: 22.18,
    stop: 22.31,
    delay: 0.6,
    word: "like",
  },
  {
    start: 23.07,
    stop: 23.28,
    delay: 0.8,
    word: "getting",
  },
  {
    start: 23.4,
    stop: 23.57,
    delay: 0.4,
    word: "sort of",
  },
  {
    start: 23.62,
    stop: 23.75,
    delay: 0,
    word: "your",
  },
  {
    start: 23.81,
    stop: 24.01,
    delay: 0,
    word: "hands",
  },
  {
    start: 24.16,
    stop: 24.48,
    delay: 0.1,
    word: "dipped",
  },
  {
    start: 24.5,
    stop: 24.6,
    delay: 0,
    word: "in",
  },
  {
    start: 24.65,
    stop: 25.01,
    delay: 0.05,
    word: "glue,",
  },
  {
    start: 25.24,
    stop: 25.28,
    delay: 0.2,
    word: "and",
  },
  {
    start: 25.84,
    stop: 26.0,
    delay: 0.56,
    word: "then",
  },
  {
    start: 26.45,
    stop: 26.8,
    delay: 0.45,
    word: "casually",
  },
  {
    start: 26.95,
    stop: 27.24,
    delay: 0.15,
    word: "creating",
  },
  {
    start: 27.25,
    stop: 27.36,
    delay: 0.01,
    word: "a",
  },
  {
    start: 27.39,
    stop: 27.68,
    delay: 0.03,
    word: "fist,",
  },
  {
    start: 27.81,
    stop: 27.88,
    delay: 0.1,
    word: "and",
  },
  {
    start: 27.95,
    stop: 28.0,
    delay: 0.07,
    word: "it",
  },
  {
    start: 28.1,
    stop: 28.2,
    delay: 0.1,
    word: "just",
  },
  {
    start: 28.34,
    stop: 28.56,
    delay: 0.14,
    word: "ends",
  },
  {
    start: 28.6,
    stop: 28.68,
    delay: 0,
    word: "up",
  },
  {
    start: 28.94,
    stop: 29.37,
    delay: 0.3,
    word: "sealed",
  },
  {
    start: 29.42,
    stop: 29.56,
    delay: 0.1,
    word: "in",
  },
  {
    start: 29.68,
    stop: 29.83,
    delay: 0.1,
    word: "like",
  },
  {
    start: 29.95,
    stop: 30.17,
    delay: 0.1,
    word: "that.",
  },
  {
    start: 30.81,
    stop: 30.93,
    delay: 0.6,
    word: "So,",
  },
  {
    start: 31.0,
    stop: 31.1,
    delay: 0.1,
    word: "all",
  },
  {
    start: 31.14,
    stop: 31.2,
    delay: 0,
    word: "my",
  },
  {
    start: 31.28,
    stop: 31.71,
    delay: 0.1,
    word: "bones",
  },
  {
    start: 31.79,
    stop: 31.92,
    delay: 0.1,
    word: "are",
  },
  {
    start: 31.93,
    stop: 31.98,
    delay: 0,
    word: "in",
  },
  {
    start: 32.15,
    stop: 32.28,
    delay: 0.2,
    word: "there,",
  },
  {
    start: 33.13,
    stop: 33.21,
    delay: 0.9,
    word: "they've",
  },
  {
    start: 33.3,
    stop: 33.4,
    delay: 0.1,
    word: "just",
  },
  {
    start: 33.49,
    stop: 33.63,
    delay: 0.1,
    word: "been",
  },
  {
    start: 33.9,
    stop: 34.25,
    delay: 0.3,
    word: "sealed",
  },
  {
    start: 34.42,
    stop: 34.65,
    delay: 0.2,
    word: "over",
  },
  {
    start: 34.69,
    stop: 34.87,
    delay: 0,
    word: "with",
  },
  {
    start: 34.96,
    stop: 35.07,
    delay: 0.1,
    word: "scar",
  },
  {
    start: 35.34,
    stop: 35.62,
    delay: 0.3,
    word: "tissue",
  },
  {
    start: 35.96,
    stop: 36.55,
    delay: 0.3,
    word: "gradually",
  },
  {
    start: 36.64,
    stop: 36.72,
    delay: 0.1,
    word: "over",
  },
  {
    start: 36.74,
    stop: 36.86,
    delay: 0,
    word: "the",
  },
  {
    start: 36.96,
    stop: 38.0,
    delay: 0.1,
    word: "years.",
  },
];

let delayItems = [];
let interval = null;
let video = null;

class HumphreyAudioOne extends Component {
  constructor() {
    super();
    this.state = {
      currentTime: 0,
      duration: 0,
      progress: 0,
      muteState: false,
    };
  }
  componentDidMount() {
    video = document.getElementById(this.props.audioId);
    this.getVideoData();
    for (var i = 1; i < Timings.length; i++) {
      delayItems.push(getDelay(Timings[i - 1], Timings[i]));
    }
    this.setState({ muteState: this.props.muteState });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.muteState !== prevState.muteState) {
      console.log(nextProps, prevState);
      return { muteState: nextProps.muteState };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.muteState !== this.props.muteState) {
      //Perform some operation here
      this.setState({ muteState: this.props.muteState });
      console.log(this.props.muteState);
      video.muted = this.props.muteState;
    }
  }

  componentWillUnmount() {
    clearInterval(interval);
    video.removeEventListener("loadedmetadata", this.getDuration);
    video.pause();
  }

  getDuration = () => {
    this.setState({
      duration: parseFloat(video.duration.toFixed(1)),
    });
    this.updateTime();
  };

  updateTime = () => {
    interval = setInterval(() => {
      if (this.state.currentTime !== parseFloat(video.currentTime.toFixed(1))) {
        this.setState({
          currentTime: parseFloat(video.currentTime.toFixed(1)),
          progress: video.currentTime / video.duration,
        });
        // const timeline = this.timeline.getGSAP();
        // console.log(timeline._timeline);
      }
    }, 50);
  };

  getVideoData = () => {
    if (video) {
      video.volume = 0.7;
      video.addEventListener("loadedmetadata", this.getDuration);
      return video.duration;
    }
    return null;
  };

  replay = () => {
    if (video) {
      video.currentTime = 0;
      if (video.paused) {
        video.play();
      }
    }
  };

  render() {
    return (
      <div className="quotes">
        <audio src={HumphreySound1()} id={this.props.audioId} controls={true} />
        <QuoteVideo
          timings={Timings}
          video={document.getElementById(this.props.audioId)}
          progress={this.state.progress}
          duration={this.state.duration}
          startColor="#B6CAE1"
          endColor="#4179BD"
        />
        <div className="replay-btn" onClick={this.replay}>
          <Replay />
          Play
        </div>
      </div>
    );
  }
}

export default HumphreyAudioOne;
