import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  TimelineMax,
  TweenMax,
  CSSPlugin,
  ScrollToPlugin,
  Expo,
} from "gsap/all";

import StartArrow from "./StartArrow.jsx";

// eslint-disable-next-line
const cssGSAP = CSSPlugin;
// eslint-disable-next-line
const scrollGSAP = ScrollToPlugin;

let gpOthers, gfOthers, goOthers;
let others = [];
let landing = null;

class Character extends Component {
  constructor(props) {
    super(props);

    this.character = null;
    this.bg = null;
    this.face = null;
    this.img = null;
    this.shadow = null;
    this.content = null;
    this.title = null;
    this.subtitle = null;
    this.start = null;
    this.screen = null;
    this.mask = null;

    this.characterIn = null;
    this.characterOut = null;
    this.characterClick = null;
  }

  componentDidMount() {
    TweenMax.set(this.bg, { scale: 0 });
    TweenMax.set(this.title, { y: 20 });
    TweenMax.set(this.subtitle, { y: 50 });
    TweenMax.set(this.start, { x: -30 });
    TweenMax.set(this.mask, { transform: "rotateY(90deg)" });

    const gp = document.getElementById("gamePlan");
    const gf = document.getElementById("gameFace");
    const go = document.getElementById("gameOn");
    gpOthers = [gf, go];
    gfOthers = [gp, go];
    goOthers = [gp, gf];

    landing = document.getElementById("landing");
  }

  activateHover = () => {
    this.props.playMouseOverAudio();
    if (this.props.story === "gameFace") {
      others = gfOthers;
    } else if (this.props.story === "gamePlan") {
      others = gpOthers;
    } else if (this.props.story === "gameOn") {
      others = goOthers;
    }
    others[0].classList.add("dull");
    others[1].classList.add("dull");
    landing.classList.add("dull");

    this.characterIn = new TimelineMax({ paused: false })
      .to(this.bg, 0.7, {
        scale: 1,
        ease: Expo.easeOut,
      })
      .to(
        this.shadow,
        1,
        {
          opacity: 0.6,
          // scale: 1.1,
          ease: Expo.easeOut,
        },
        "-=0.5"
      )
      .to(
        this.img,
        1,
        {
          // scale: 1.1,
          ease: Expo.easeOut,
        },
        "-=1"
      )
      .to(
        this.title,
        1,
        {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
        },
        "-=1"
      )
      .to(
        this.subtitle,
        1,
        {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
        },
        "-=1"
      )
      .to(
        this.start,
        1.6,
        {
          opacity: 1,
          x: 0,
          ease: Expo.easeOut,
        },
        "-=1"
      );
  };
  deactivateHover = () => {
    this.props.playMouseOutAudio();
    if (this.props.story === "gameFace") {
      others = gfOthers;
    } else if (this.props.story === "gamePlan") {
      others = gpOthers;
    } else if (this.props.story === "gameOn") {
      others = goOthers;
    }

    others[0].classList.remove("dull");
    others[1].classList.remove("dull");
    landing.classList.remove("dull");
    this.characterOut = new TimelineMax({ paused: false })
      .to(this.bg, 0.4, {
        scale: 0,
        ease: Expo.easeIn,
      })
      .to(
        this.shadow,
        0.4,
        {
          opacity: 0,
          scale: 1,
          ease: Expo.easeIn,
        },
        "-=0.4"
      )
      .to(
        this.img,
        0.4,
        {
          scale: 1,
          ease: Expo.easeIn,
        },
        "-=0.4"
      )
      .to(
        this.title,
        1,
        {
          opacity: 0,
          y: 20,
          ease: Expo.easeIn,
        },
        "-=1"
      )
      .to(
        this.subtitle,
        1,
        {
          opacity: 0,
          y: 50,
          ease: Expo.easeIn,
        },
        "-=1"
      )
      .to(
        this.start,
        1,
        {
          opacity: 0,
          x: -30,
          ease: Expo.easeIn,
        },
        "-=1"
      )
      .set(this.title, { opacity: 0 })
      .set(this.subtitle, { opacity: 0 })
      .set(this.start, { opacity: 0 })
      .delay(0.4);
  };

  activateClick = () => {
    this.props.playSelectAudio();
    const history = this.props.history;
    const link = this.props.link;

    others[0].classList.add("move-out");
    others[1].classList.add("move-out");
    landing.classList.add("move-out");

    this.characterClick = new TimelineMax({
      paused: false,
      onComplete: function () {
        history.push(link);
      },
    })
      .to(this.screen, 1.3, {
        width: "100%",
        ease: Expo.easeOut,
      })
      .to(
        this.face,
        1,
        {
          scale: 0.6,
          ease: Expo.easeIn,
        },
        "-=2"
      )
      .set(this.bg, { opacity: 0 })
      .set(this.face, { opacity: 0 })
      .set(this.content, { opacity: 0 })
      .set(this.character, { zIndex: 20 })
      .to(this.screen, 0.7, {
        transform: "rotateY(270deg) scale(2)",
        ease: Expo.easeIn,
      })
      .set(this.mask, { opacity: 1 })
      .to(this.mask, 0.7, {
        transform: "rotateY(0deg) scale(8)",
        ease: Expo.easeOut,
      });
  };

  render() {
    return (
      <div
        className={`character ${this.props.story}`}
        id={this.props.id}
        ref={(ref) => {
          this.character = ref;
        }}
        onMouseEnter={this.activateHover}
        onMouseLeave={this.deactivateHover}
        onMouseDown={this.activateClick}
      >
        <div
          className={`bg ${this.props.story}`}
          ref={(ref) => {
            this.bg = ref;
          }}
        ></div>
        <div
          className="face"
          ref={(ref) => {
            this.face = ref;
          }}
        >
          <img
            className="main"
            alt=""
            src={this.props.face}
            ref={(ref) => {
              this.img = ref;
            }}
          />
          <img
            className="shadow"
            alt=""
            src={this.props.face}
            ref={(ref) => {
              this.shadow = ref;
            }}
          />
        </div>

        <div
          className="content"
          ref={(ref) => {
            this.content = ref;
          }}
        >
          <div
            className="title"
            ref={(ref) => {
              this.title = ref;
            }}
          >
            {this.props.title}
          </div>
          <div
            className="subtitle"
            ref={(ref) => {
              this.subtitle = ref;
            }}
          >
            <div className="tagline-line">{this.props.tagline}</div>
          </div>
          <div className="number">{this.props.number}</div>
          <div
            className="start"
            ref={(ref) => {
              this.start = ref;
            }}
          >
            <StartArrow />
          </div>
        </div>
        <div
          className={`screen ${this.props.story}`}
          ref={(ref) => {
            this.screen = ref;
          }}
        ></div>
        <div
          className={`mask`}
          ref={(ref) => {
            this.mask = ref;
          }}
        ></div>
      </div>
    );
  }
}

export default withRouter(Character);
