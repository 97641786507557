import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";

import ScrollToTop from "./ScrollToTop";
import NavBar from "../Nav/NavBar";
import AudioWrapper from "./AudioWrapper";

import store from "../../state/store";
import GamePlan from "../GamePlan/GamePlan";
import GamePlanArticle from "../GamePlan/GamePlanArticle";

import Landing from "../Landing/Landing";
import CanYouMakeIt from "../CanYouMakeIt/CanYouMakeIt";
import DayInTheLife from "../DayInTheLife/DayInTheLife";
import GameFaceArticle from "../GameFace/GameFaceArticle";
import GameOn from "../GameOn/GameOn";
import GameFace from "../GameFace/GameFace";
import NotFound from "./NotFound";
import About from "../About/About";
import VideoPage from "../Video/VideoPage";

import GameOnCanvas from "../GameOn/GameOnCanvas";

import "./Base.scss";
import "./Mixins.scss";
import "./Variables.scss";

// import HTML5Backend from "react-dnd-html5-backend";
// import { DndProvider } from "react-dnd";
import MultiBackend, { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/cjs/HTML5toTouch";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      section: "gameChange",
    };
  }

  componentDidMount() {
    ReactGA.initialize("UA-154605251-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  updateNav = (section) => {
    if (this.state.section !== section) {
      this.setState({
        section: section,
      });
    }
  };
  render() {
    return (
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <div className={`app ${this.state.section}`} id="app">
          <Provider store={store}>
            <Router>
              <ScrollToTop>
                <NavBar
                  section={this.state.section}
                  hide={this.state.navHide}
                  menuToggle={this.menuToggle}
                />
                <Switch>
                  <Route
                    path="/"
                    exact
                    component={() => <Landing updateNav={this.updateNav} />}
                  />
                  <Route
                    path="/gameplan"
                    exact
                    component={() => <GamePlan updateNav={this.updateNav} />}
                  />
                  <Route path="/gameplan/article" component={GamePlanArticle} />
                  <Route
                    path="/gameplan/game"
                    component={() => (
                      <AudioWrapper>
                        <CanYouMakeIt updateNav={this.updateNav} />
                      </AudioWrapper>
                    )}
                  />
                  <Route
                    path="/gameface"
                    exact
                    component={() => <GameFace updateNav={this.updateNav} />}
                  />
                  <Route
                    path="/gameface/game"
                    component={() => (
                      <DayInTheLife
                        updateNav={this.updateNav}
                        key={Date.now()}
                      />
                    )}
                  />

                  <Route path="/gameface/article" component={GameFaceArticle} />
                  <Route
                    path="/gameonlegacy"
                    exact
                    component={() => <GameOn updateNav={this.updateNav} />}
                  />
                  <Route path="/gameon" exact component={GameOnCanvas} />

                  <Route path="/videos" component={VideoPage} />
                  <Route path="/about" component={About} />

                  <Route component={NotFound} />
                </Switch>
              </ScrollToTop>
            </Router>
          </Provider>
        </div>
      </DndProvider>
    );
  }
}

export default App;
