const reducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case "INITIALIZED":
      return { ...state, initializing: false };
    case "REQUEST_POSTS":
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case "RECEIVE_POSTS":
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        posts: action.posts,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
};

const createInitialState = () => ({
  initializing: true,
  isFetching: false,
  didInvalidate: false,
  posts: []
});

export default reducer;
