import React, { Component } from "react";
import { Timeline, Tween } from "react-gsap";
import { TimelineMax, Power1 } from "gsap/all";

import GameTease from "./GameTease";

import "./Hero.scss";

const ScrollMagic = window.ScrollMagic;

class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };

    this.controller = new ScrollMagic.Controller();

    this.titleWrap = null;
    this.face = null;
    this.intro = null;
    this.articlePrompt = null;
    this.heroTween = null;
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (this.articlePrompt) {
        if (document.documentElement.scrollTop > 0) {
          this.articlePrompt.classList.add("hide");
          this.setState({ scrolled: true });
        } else {
          this.articlePrompt.classList.remove("hide");
          this.setState({ scrolled: false });
        }
      }
    });

    this.heroTween = new TimelineMax({ paused: false })
      .fromTo(
        this.titleWrap,
        1,
        { transform: "translateY(0)", opacity: 1 },
        { transform: "translateY(20px)", opacity: 0, ease: Power1.easeOut }
      )
      .fromTo(
        this.face,
        1,
        { scale: 1 },
        { scale: 1.3, ease: Power1.easeOut },
        "-=1"
      )
      .fromTo(
        this.face,
        1,
        { opacity: 1, y: 0 },
        { opacity: 0, y: "20vh", ease: Power1.easeOut },
        "-=1"
      );

    new ScrollMagic.Scene({
      triggerElement: `#game-face-article`,
      triggerHook: 0.8,
      duration: "100%",
    })
      // .addIndicators()
      .setTween(this.heroTween)
      .addTo(this.controller);
  }

  render() {
    return (
      <React.Fragment>
        <div className="hero" id="hero">
          <div className="face">
            <video
              src={this.props.video}
              ref={(ref) => (this.face = ref)}
              autoPlay
              playsInline
              muted
              loop
            />
          </div>
          <div
            className="hero-title-wrap"
            ref={(ref) => (this.titleWrap = ref)}
          >
            <div className="hero-head-text">
              <Timeline paused={false} duration="" delay="1">
                <Tween
                  from={{ opacity: 0, x: "-100" }}
                  to={{ opacity: 1, x: "0" }}
                  duration={2}
                  ease="Expo.easeOut"
                  position="0"
                >
                  <div className="hero-title">{this.props.title}</div>
                </Tween>
              </Timeline>
              <div className="hero-subtitle">
                <Timeline paused={false} duration="" delay="1.3">
                  <Tween
                    from={{ opacity: 0, x: "-100" }}
                    to={{ opacity: 1, x: "0" }}
                    duration={2}
                    ease="Expo.easeOut"
                    position="0"
                  >
                    <div className="tagline-line">{this.props.lineOne}</div>
                  </Tween>
                  <Tween
                    from={{ opacity: 0, x: "-100" }}
                    to={{ opacity: 1, x: "0" }}
                    duration={2}
                    ease="Expo.easeOut"
                    delay={0.2}
                    position="0"
                  >
                    <div className="tagline-line">{this.props.lineTwo}</div>
                  </Tween>
                  <Tween
                    from={{ opacity: 0, x: "-100" }}
                    to={{ opacity: 1, x: "0" }}
                    duration={2}
                    ease="Expo.easeOut"
                    delay={0.4}
                    position="0"
                  >
                    <div className="tagline-line">{this.props.lineThree}</div>
                  </Tween>
                </Timeline>
              </div>
            </div>
            {/* <div className="hero-intro" ref={ref => (this.intro = ref)}>
              {this.props.intro}
            </div> */}
          </div>

          <div
            className={`scroll-prompt${this.props.hideScroll ? " hide" : ""}`}
          >
            <div className="label">scroll</div>
          </div>
          {this.props.story !== "gameOn" && (
            <div
              className="article-scroll-prompt"
              ref={(ref) => (this.articlePrompt = ref)}
            >
              SCROLL TO READ THE ARTICLE
            </div>
          )}
        </div>
        {this.props.story !== "gameOn" && (
          <GameTease
            story={this.props.story}
            video={this.props.teaseVideo}
            title={this.props.teaseTitle}
            text={this.props.teaseText}
            link={this.props.teaseLink}
            scrolled={this.state.scrolled}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Hero;
