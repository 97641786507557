import React, { Component } from "react";
import { connect } from "react-redux";
import { TimelineMax, Expo } from "gsap/all";
import { Loader } from "resource-loader";
import ReactGA from "react-ga";

import { fetchPosts } from "../../state/actions";
import { getArticleData } from "../../state/selectors";
import { GO_ARTICLE_PAGEID } from "../../api/api";
import Loading from "../Story/Loading";
import Hero from "../Story/Hero";
import ImageColumns from "../Article/ImageColumns";
import ImageFull from "../Article/ImageFull";
import NextStory from "../Article/NextStory";
import SlideGroup from "./SlideGroup";
import Mute from "../Video/Mute";

import { faceSliderHumphreyReal } from "../../utils/fileManager";
import { imagePaths, audioPaths } from "../../utils/mediaManager";
import { disableScroll, enableScroll } from "../../utils/scrollUtils";

import "../GameOn/GameOn.scss";

import HumphreyAudioOne from "./HumphreyAudioOne";
import HumphreyAudioTwo from "./HumphreyAudioTwo";
import HumphreyAudioFour from "./HumphreyAudioFour";

import {
  initialFrame,
  pad,
  initial,
  rotate,
  hands,
  sway1,
  zoomIn,
  zoomOut,
  sway2,
  humphreyDissolve,
  controllerIntro,
  controllerRotateStart,
  controllerRotateFinish,
  controllerExplode,
  controllerToHumphrey,
  superHeroHands
} from "../../utils/gameOnSequences";

const ScrollMagic = window.ScrollMagic;

const audioOneId = "humphrey1";
const audioTwoId = "humphrey2";
const audioFourId = "humphrey4";

const accessory = imagePaths("gameon", "accessory.jpg");
const character = imagePaths("gameon", "character.jpg");
const coffee = imagePaths("gameon", "coffee.jpg");
const dog = imagePaths("gameon", "dog.jpg");
const driving = imagePaths("gameon", "driving.jpg");
const friends = imagePaths("gameon", "friends.jpg");
const playing = imagePaths("gameon", "playing.jpg");
const screen = imagePaths("gameon", "screen.jpg");

let slideTween = null;
// let quoteTween = null;
let sequenceWrap = null;

let audioList = new Array();
let loopTrack = null;

let audioOne = null;
let audioTwo = null;
let audioFour = null;

const sequenceCounts = {
  initial: {
    start: 1,
    count: 41
  },
  rotate: {
    start: 42,
    count: 55
  },
  hands: {
    start: 97,
    count: 59
  },
  sway1: {
    start: 156,
    count: 43
  },
  zoomIn: {
    start: 199,
    count: 82
  },
  zoomOut: {
    start: 281,
    count: 17
  },
  sway2: {
    start: 298,
    count: 54
  },
  humphreyDissolve: {
    start: 352,
    count: 85
  },
  controllerIntro: {
    start: 437,
    count: 59
  },
  controllerRotateStart: {
    start: 496,
    count: 65
  },
  controllerRotateFinish: {
    start: 561,
    count: 12
  },
  controllerExplode: {
    start: 573,
    count: 48
  },
  controllerToHumphrey: {
    start: 621,
    count: 113
  },
  superHeroHands: {
    start: 734,
    count: 60
  }
};

class GameOn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      humphreySlidesLoaded: false,
      controllerSlidesLoaded: false,
      humphreyQuotesLoaded: false,
      controllerQuotesLoaded: false,
      quotesLoaded: false,
      loadProgress: 0,
      framesLoaded: false,
      sequenceImg: initialFrame(),
      audioEnabled: false,
      playPrompt: false,
      muteState: false,
      hideScroll: true
    };

    this.controller = new ScrollMagic.Controller();
    this.slides = { humphrey: [], controller: [] };
    this.quotes = { humphrey: [], controller: [] };
    this.slideTween = null;
    this.loadBar = null;
    this.loadWrap = null;
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.props.fetchData();
    // this.props.updateNav("gameOn");
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    disableScroll();

    loopTrack = new Audio(audioPaths("HomePageMusicBed.mp3"));
    this.controlPlayState(loopTrack);
    loopTrack.addEventListener(
      "ended",
      function() {
        console.log("looped");
        this.currentTime = 0;
        this.play();
      },
      false
    );
    audioList.push(loopTrack);

    sequenceWrap = document.getElementById("sequence-wrap");

    if (this.state.framesLoaded === false) {
      var loader = new Loader();
      loader
        .add(initial())
        .add(rotate())
        .add(hands())
        .add(sway1())
        .add(zoomIn())
        .add(zoomOut())
        .add(sway2())
        .add(humphreyDissolve())
        .add(controllerIntro())
        .add(controllerRotateStart())
        .add(controllerRotateFinish())
        .add(controllerExplode())
        .add(controllerToHumphrey())
        .add(superHeroHands())
        .load((loader, resources) => {
          this.setState({
            resources
          });
        });

      loader.onProgress.add((loader, resource) => {
        // console.log("one asset loaded", loader.progress, resource.name);
        this.setState({ loadProgress: Math.round(loader.progress) });
        if (sequenceWrap) {
          if (!document.getElementById(resource.name)) {
            const img = document.createElement("img");
            const group = resource.name.substring(0, resource.name.length - 3);
            img.src = resource.url;
            img.id = resource.name;
            img.classList.add(group);
            sequenceWrap.append(img);
          }
        }

        if (this.loadBar) {
          this.loadBar.style.width = loader.progress + "%";
        }
      });
      loader.onComplete.add(() => {
        if (this.loadWrap) {
          this.loadWrap.style.opacity = 0;
        }
        this.setState({ framesLoaded: true });
        if (this.state.audioEnabled === true) {
          this.setState({ hideScroll: false });
          enableScroll();
        }
      });
    }

    audioOne = document.getElementById(audioOneId);
    audioTwo = document.getElementById(audioTwoId);
    audioFour = document.getElementById(audioFourId);
  }

  componentWillUnmount() {
    enableScroll();
    if (audioOne) {
      audioOne.pause();
      audioOne.volume = 0;
    }
    if (audioTwo) {
      audioTwo.pause();
      audioTwo.volume = 0;
    }
    if (audioFour) {
      audioFour.pause();
      audioFour.volume = 0;
    }
    audioList.map(audio => {
      audio.muted = true;
      audio.pause();
    });
  }

  componentDidUpdate() {
    if (
      this.slides.humphrey.length &&
      this.slides.humphrey[this.slides.humphrey.length - 1] &&
      !this.state.humphreySlidesLoaded
    ) {
      this.setState({ humphreySlidesLoaded: true });
      for (var i = 0; i < this.slides.humphrey.length; i++) {
        this.slideAnimation(this.slides.humphrey[i], i);
      }
    }
    if (
      this.slides.controller.length &&
      this.slides.controller[this.slides.controller.length - 1] &&
      !this.state.controllerSlidesLoaded
    ) {
      this.setState({ controllerSlidesLoaded: true });
      for (var i = 0; i < this.slides.controller.length; i++) {
        this.slideAnimation(this.slides.controller[i], i);
      }
    }

    if (
      this.quotes.humphrey.length &&
      this.quotes.humphrey[this.quotes.humphrey.length - 1] &&
      !this.state.humphreyQuotesLoaded
    ) {
      this.setState({ humphreyQuotesLoaded: true });

      for (var i = 0; i < this.quotes.humphrey.length; i++) {
        this.quoteAnimation(this.quotes.humphrey[i], i);
      }
    }
    if (
      this.quotes.controller.length &&
      this.quotes.controller[this.quotes.controller.length - 1] &&
      !this.state.controllerQuotesLoaded
    ) {
      this.setState({ controllerQuotesLoaded: true });

      for (var i = 0; i < this.quotes.controller.length; i++) {
        this.quoteAnimation(this.quotes.controller[i], i);
      }
    }
    audioOne = document.getElementById(audioOneId);
    audioTwo = document.getElementById(audioTwoId);
    audioFour = document.getElementById(audioFourId);
  }

  slideAnimation = (slide, i) => {
    if (typeof slide !== "undefined") {
      const group = slide.attributes.getNamedItem("data-group").value;
      const triggerAction = slide.attributes.getNamedItem("data-trigger").value;
      let duration = "1200"; //"600";
      if (triggerAction === "superHeroHands") duration = "100%";
      slideTween = new TimelineMax({ paused: false }).fromTo(
        `#${slide.id}`,
        2,
        { autoAlpha: 0, transform: "scale(1.4)" },
        {
          autoAlpha: 1,
          transform: "scale(1)",
          ease: Expo.easeInOut
        }
      );
      const that = this;
      new ScrollMagic.Scene({
        triggerElement: `#slide-trigger-${i}-${group}`,
        triggerHook: 0.25,
        duration: duration
      })
        // .addIndicators()
        .setPin(`#slide-wrap-${i}-${group}`)
        .setTween(slideTween)
        .addTo(this.controller)
        .on("enter", function(event) {
          // console.log(triggerAction, " Scene entered.", event);
          // that.sequencePlay();
        })
        .on("progress", function(event) {
          // var progressvalue = Math.floor(100 * event.progress);
          if (this.state.framesLoaded !== false) {
            // that.scrollSequence(triggerAction, event.progress);
            that.scrollSequenceOpacity(triggerAction, event.progress);
          }
        });
    }
  };

  quoteAnimation = (quote, i) => {
    if (typeof quote !== "undefined") {
      const group = quote.attributes.getNamedItem("data-group").value;
      const quoteId = quote.attributes.getNamedItem("data-quote-id").value;

      // quoteTween = new TimelineMax({ paused: false }).fromTo(
      //   `#${quote.id}`,
      //   2,
      //   { autoAlpha: 0, transform: "scale(1.4)" },
      //   {
      //     autoAlpha: 1,
      //     transform: "scale(1)",
      //     ease: Expo.easeInOut
      //   }
      // );

      new ScrollMagic.Scene({
        triggerElement: `#quote-trigger-${i}-${group}`,
        triggerHook: 0.25,
        duration: "100%"
      })
        // .addIndicators()
        // .setPin(`#quote-wrap-${i}-${group}`)
        // .setTween(quoteTween)
        .addTo(this.controller)
        .on("enter", function(event) {
          // console.log("Quote Scene entered.", event, quoteId);
          if (quoteId === "humphrey1") {
            console.log(audioOne);
            if (audioOne) {
              var promise = audioOne.play();
              if (promise !== undefined) {
                promise
                  .then(_ => {
                    // Autoplay started!
                    console.log("autoplay", audioOne.muted);
                  })
                  .catch(error => {
                    audioOne.muted = true;
                    audioOne.play();
                    console.log("can't autoplay");
                  });
              }
            }
          } else if (quoteId === "humphrey2") {
            if (audioTwo) {
              var promise = audioTwo.play();
              if (promise !== undefined) {
                promise
                  .then(_ => {
                    // Autoplay started!
                    console.log("autoplay", audioTwo.muted);
                  })
                  .catch(error => {
                    audioTwo.muted = true;
                    audioTwo.play();
                  });
              }
            }
          } else if (quoteId === "humphrey4") {
            if (audioFour) {
              var promise = audioFour.play();
              if (promise !== undefined) {
                promise
                  .then(_ => {
                    // Autoplay started!
                    console.log("autoplay", audioFour.muted);
                  })
                  .catch(error => {
                    audioFour.muted = true;
                    audioFour.play();
                  });
              }
            }
          }
        })
        .on("leave", function(event) {
          // console.log("Quote Scene exited.", event);
          if (quoteId === "humphrey1") {
            if (audioOne) {
              audioOne.pause();
            }
          } else if (quoteId === "humphrey2") {
            if (audioTwo) {
              audioTwo.pause();
            }
          } else if (quoteId === "humphrey4") {
            if (audioFour) {
              audioFour.pause();
            }
          }
        });
    }
  };

  scrollSequenceOpacity = (triggerAction, progress) => {
    const sequenceData = sequenceCounts[triggerAction];
    // Remove 1 from the total count so it doesn't look for an end file that doesn't exist.
    const usableCount = sequenceData.count - 1;
    // convert progress into a whole value between 1 and total
    let progressItem = Math.round(progress * usableCount);
    const imageCount = sequenceData.start + progressItem;
    if (this.state.resources) {
      const count = pad(imageCount, 3);

      // Set last active image to zero opacity
      const lastImg = document.getElementById(this.state.sequenceImg);
      if (lastImg && this.state.sequenceImg !== `${triggerAction}${count}`) {
        lastImg.style.opacity = 0;
      }

      // Make the current image visible
      const currentImage = document.getElementById(`${triggerAction}${count}`);
      if (currentImage) {
        currentImage.style.opacity = 1;
      }

      // const newImg = this.state.resources[`${triggerAction}${count}`].url;
      this.setState({
        sequenceImg: `${triggerAction}${count}`
      });
      if (triggerAction === "humphreyDissolve" && progressItem > 30) {
        // Get bottom position.
      }
    }
  };

  scrollSequence = (triggerAction, progress) => {
    const sequenceData = sequenceCounts[triggerAction];
    // Remove 1 from the total count so it doesn't look for an end file that doesn't exist.
    const usableCount = sequenceData.count - 1;
    // convert progress into a whole value between 1 and total
    let progressItem = Math.round(progress * usableCount);
    const imageCount = sequenceData.start + progressItem;
    if (this.state.resources) {
      const count = pad(imageCount, 3);
      // console.log(this.state.resources, `${triggerAction}${count}`);
      const newImg = this.state.resources[`${triggerAction}${count}`].url;
      // update the img src with resource.url
      this.setState({
        sequenceImg: newImg
      });
    }
  };

  // sequenceSource = () => {
  //   let i = 1;
  //   // setInterval to run 12 times a second
  //   const swaySequence = setInterval(() => {
  //     // advance through resources one time per loop
  //     const count = pad(i, 3);
  //     const newImg = this.state.resources[`sway${count}`].url;
  //     console.log(i, newImg);
  //     // update the img src with resource.url
  //     this.setState({
  //       sequenceImg: newImg
  //     });
  //     if (i === 41) {
  //       clearInterval(swaySequence);
  //       this.setState({
  //         sway: "transitionCompleted"
  //       });
  //       return;
  //     }
  //     i++;
  //   }, 80);
  // };

  unmute = () => {
    this.controlPlayState(loopTrack);
  };

  unmuteAndStart = () => {
    this.controlPlayState(loopTrack);
    this.setState({ hideScroll: false });
    enableScroll();
  };

  muteToggle = () => {
    console.log("mute toggle");
    this.setState({ muteState: !this.state.muteState });
    // Now mute or unmute all audio on this page
    audioList.map(audio => {
      audio.muted = !this.state.muteState;
    });
  };

  controlPlayState = audio => {
    if (audio.paused) {
      var promise = audio.play();
      if (promise !== undefined) {
        promise
          .then(_ => {
            // Autoplay started!
            this.setState({ audioEnabled: true, playPrompt: false });
            console.log("autoplay");
          })
          .catch(error => {
            // Show a button on the video saying pres play to start
            this.setState({ playPrompt: true });
          });
      }
    } else {
      audio.pause();
      this.setState({ audioEnabled: false });
    }
  };

  render() {
    const { article } = this.props;
    let layouts = "";

    if (article) {
      layouts = article.acf.article_components;
    }

    let humphreyGroup = "";
    let articleGroup = "";
    let controllerGroup = "";
    let articleOutput = null;
    if (layouts) {
      // Loop through acf and display all layouts.
      layouts.map((layout, i) => {
        if (layout.acf_fc_layout === "slide_group") {
          const slides = Object.values(layout.slides);
          const groupName = layout.group_name;
          const slideOutput = slides.map((slide, s) => {
            const slideContent = [];
            const triggerAction = slide.triggerAction;
            if (slide.type === "text") {
              slide.text.map((par, p) => {
                slideContent.push(
                  <div className="paragraph" key={`paragraph${p}`}>
                    {par.paragraph}
                  </div>
                );
              });

              return (
                <React.Fragment key={`${s}-${groupName}`}>
                  <div
                    className="slide-trigger"
                    id={`slide-trigger-${s}-${groupName}`}
                  ></div>
                  <div
                    className="slide-wrap"
                    id={`slide-wrap-${s}-${groupName}`}
                  >
                    <div
                      className="slide"
                      key={s}
                      id={`slide-${s}-${groupName}`}
                      ref={ref => (this.slides[groupName][s] = ref)}
                      data-group={groupName}
                      data-trigger={triggerAction}
                    >
                      {slideContent}
                    </div>
                  </div>
                </React.Fragment>
              );
            } else if (slide.type === "quote") {
              let quoteId = null;
              if (slide.quote.quote_id === "humphrey1") {
                quoteId = slide.quote.quote_id;
                slideContent.push(
                  <HumphreyAudioOne
                    audioId={audioOneId}
                    key={`quote${s}-${groupName}`}
                    muteState={this.state.muteState}
                  />
                );
              } else if (slide.quote.quote_id === "humphrey2") {
                quoteId = slide.quote.quote_id;
                slideContent.push(
                  <HumphreyAudioTwo
                    audioId={audioTwoId}
                    key={`quote${s}-${groupName}`}
                    muteState={this.state.muteState}
                  />
                );
              } else if (slide.quote.quote_id === "humphrey4") {
                quoteId = slide.quote.quote_id;
                slideContent.push(
                  <HumphreyAudioFour
                    audioId={audioFourId}
                    key={`quote${s}-${groupName}`}
                    muteState={this.state.muteState}
                  />
                );
              }

              return (
                <React.Fragment key={`${s}-${groupName}`}>
                  <div
                    className="quote-trigger"
                    id={`quote-trigger-${s}-${groupName}`}
                  ></div>
                  <div
                    className="quote-wrap"
                    id={`quote-wrap-${s}-${groupName}`}
                  >
                    <div
                      className="quote-content"
                      id={`quote-${s}-${groupName}`}
                      ref={ref => (this.quotes[groupName][s] = ref)}
                      data-group={groupName}
                      data-quote-id={quoteId}
                    >
                      {slideContent}
                    </div>
                  </div>
                </React.Fragment>
              );
            }
            return null;
          });

          if (layout.group_name === "humphrey") {
            humphreyGroup = (
              <SlideGroup key={i} groupName="humphrey">
                {slideOutput}
              </SlideGroup>
            );
          } else if (layout.group_name === "controller") {
            controllerGroup = (
              <SlideGroup key={i} groupName="controller">
                {slideOutput}
              </SlideGroup>
            );
          }
        }

        if (layout.acf_fc_layout === "article") {
          articleGroup = layout.article_blocks.map((block, b) => {
            if (block.acf_fc_layout === "text_block") {
              let text = block.text_block;
              const blockClass = block.blockClass;

              return (
                <div
                  className={`block-container ${blockClass}`}
                  key={`block-${b}`}
                >
                  <div
                    key={i}
                    className="text-block"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </div>
              );
            } else if (block.acf_fc_layout === "image_block") {
              const imageGroup = block.image_group;
              if (imageGroup === "end") {
                return (
                  <div
                    className="block-container image-block"
                    key={`block-${b}`}
                  >
                    <ImageFull src={driving} size="wide" imgId="driving" />

                    <ImageColumns
                      leftCol={[
                        {
                          src: coffee,
                          size: "tall",
                          imgId: "coffee"
                        }
                      ]}
                      leftClasses=""
                      rightCol={[
                        {
                          src: friends,
                          size: "square",
                          imgId: "friends"
                        },
                        {
                          src: dog,
                          size: "tall",
                          imgId: "dog"
                        }
                      ]}
                      rightClasses=" align-end"
                    />
                    <ImageFull src={accessory} size="wide" imgId="accessory" />
                  </div>
                );
              } else if (imageGroup === "middle") {
                return (
                  <div
                    className="block-container image-block"
                    key={`block-${b}`}
                  >
                    <ImageFull src={playing} size="wide" imgId="playing" />
                    <ImageColumns
                      leftCol={[
                        { src: screen, size: "square", imgId: "screen" }
                      ]}
                      leftClasses=""
                      rightCol={[
                        {
                          src: character,
                          size: "tall",
                          imgId: "character"
                        }
                      ]}
                      rightClasses=" align-end"
                    />
                  </div>
                );
              }
            }
          });
        }

        return null;
      });
      articleOutput = (
        <div>
          {humphreyGroup}
          <div className="article-main">
            <div className="article-wrap">{articleGroup}</div>
          </div>
          {controllerGroup}
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="game-on-sequence story-wrap" id="game-on">
          <div className="mute-toggle" onClick={this.muteToggle}>
            <Mute mode={this.state.muteState ? "mute-off" : "mute-on"} />
          </div>

          <div className="hero-wrap game-on">
            <Hero
              story="gameOn"
              video={faceSliderHumphreyReal()}
              ref={ref => (this.hero = ref)}
              lineOne="The adaptive controller"
              lineTwo="changing the face of"
              lineThree="accessible gaming"
              hideScroll={this.state.hideScroll}
            />
            {this.state.playPrompt === true &&
              this.state.framesLoaded === false && (
                <div className="play-prompt" onClick={this.unmute}>
                  <div className="btn">Play Sounds (recommended)</div>
                </div>
              )}
            {this.state.playPrompt === true &&
              this.state.framesLoaded === true && (
                <div className="play-prompt" onClick={this.unmuteAndStart}>
                  <div className="btn">Click to continue</div>
                </div>
              )}
            <div
              className="load-wrap"
              ref={ref => {
                this.loadWrap = ref;
              }}
            >
              <div className="label">Loading {this.state.loadProgress}%</div>
              <div className="load-bar">
                <div
                  className="bar"
                  ref={ref => {
                    this.loadBar = ref;
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="main-wrap">
            <div
              className="sequence-wrap"
              id="sequence-wrap"
              ref={ref => (this.videoWrap = ref)}
            >
              {/* <img src={this.state.sequenceImg} alt="" /> */}
            </div>

            {articleOutput && articleOutput}
            {!articleOutput && (
              <div className="error">
                <Loading />
              </div>
            )}
          </div>
          <NextStory current="gameon" />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  article: getArticleData(state, GO_ARTICLE_PAGEID)
});

const mapDispatchToProps = dispatch => ({
  fetchData: () => {
    dispatch(fetchPosts());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GameOn);
