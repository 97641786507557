import React, { Component } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { easeOutBack, easeOutExpo } from "js-easing-functions";
import OrientationHelper from "./OrientationHelper";
import ListenerHolder from "./ListenerHolder";

export default class PuzzleThreeContainer extends Component {
  componentDidMount() {
    const skyColor = 0xb1e1ff; // light blue
    const groundColor = 0xb97a20; // brownish orange
    const lightIntensity = 1;

    this.objectCounter = 0;
    this.totalObjects = 6;
    this.objects = {};
    this.pieces = [];
    this.attachedPieces = [];
    this.isPieceAnimating = false;
    this.footballInner = null;
    this.house = null;
    this.mouseDrag = {
      lastX: null,
    };

    let attachThreshold;
    const attachThresholdLandscape = 1;
    const attachThresholdPortrait = 1;

    this.isDraggingBall = false;
    this.isDraggingPiece = false;
    this.draggedPiece = null;

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    this.camera.position.z = 5;

    this.orientation = new OrientationHelper();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.mount,
    });

    this.listeners = [];

    this.renderer.setSize(
      width * window.devicePixelRatio,
      height * window.devicePixelRatio,
      false
    );
    this.renderer.setClearColor(skyColor);

    const hemiLight = new THREE.HemisphereLight(
      skyColor,
      groundColor,
      lightIntensity
    );
    this.scene.add(hemiLight);

    var pointLight = new THREE.PointLight(0xffffff, 0.5);
    pointLight.position.set(10, 10, 10);
    this.scene.add(pointLight);

    const backdrop = new THREE.Mesh(
      new THREE.PlaneGeometry(100, 100),
      new THREE.MeshBasicMaterial({
        color: 0x000000,
        opacity: 0.4,
        transparent: true,
      })
    );
    backdrop.position.set(0, 0, -2);
    backdrop.rotation.set(0, 0, 0);
    this.scene.add(backdrop);

    const onResize = (_) => {
      const width = this.mount.clientWidth;
      const height = this.mount.clientHeight;
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        width * window.devicePixelRatio,
        height * window.devicePixelRatio,
        false
      );
      this.footballInner.onResize();
      this.pieces.forEach((piece) => {
        if (piece.onResize) piece.onResize();
      });
      if (width > height) attachThreshold = attachThresholdLandscape;
      else attachThreshold = attachThresholdPortrait;
    };

    const onMouseDrag = (event) => {
      onCursorDrag(event.clientX, event.clientY);
    };

    const onTouchDrag = (event) => {
      onCursorDrag(
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY
      );
    };

    const normalizedCursor2 = new THREE.Vector2();
    const normalizedCursor3 = new THREE.Vector3();
    this.cursorOnBallZ = new THREE.Vector3();

    const onCursorDrag = (clientX, clientY) => {
      if (this.isDraggingBall) {
        if (!this.mouseDrag.lastX) this.mouseDrag.lastX = clientX;

        const deltaX = clientX - this.mouseDrag.lastX;

        if (!this.isPieceAnimating) {
          this.footballInner.rotation.y += deltaX * 0.01;
          this.pieces.forEach((piece) => {
            if (piece.attachedPreview)
              piece.attachedPreview.rotation.copy(this.footballInner.rotation);
          });
        }

        this.mouseDrag.lastX = clientX;
      } else if (this.isDraggingPiece && !this.isPieceAnimating) {
        normalizedCursor2.set(
          (clientX / this.mount.clientWidth) * 2 - 1,
          (clientY / this.mount.clientHeight) * -2 + 1
        );
        setCursorOnBallZ();
        this.draggedPiece.position.x = this.cursorOnBallZ.x;
        this.draggedPiece.position.y = this.cursorOnBallZ.y;

        if (
          this.draggedPiece.quaternion.angleTo(this.footballInner.quaternion) <
            0.6 &&
          this.draggedPiece.position.distanceTo(this.footballInner.position) <
            attachThreshold
        ) {
          this.isPieceAnimating = true;
          this.draggedPiece.animation.attach.fromPosition.copy(
            this.draggedPiece.position
          );
          this.draggedPiece.animation.attach.fromRotation.copy(
            this.draggedPiece.quaternion
          );
          this.draggedPiece.animation.attach.isRunning = true;
          this.draggedPiece.attachedPreview.visible = false;
        }
      }
    };

    const setCursorOnBallZ = (_) => {
      normalizedCursor3.set(normalizedCursor2.x, normalizedCursor2.y, 0);
      normalizedCursor3.unproject(this.camera);
      normalizedCursor3.sub(this.camera.position).normalize();
      const distance =
        (this.footballInner.position.z - this.camera.position.z) /
        normalizedCursor3.z;
      this.cursorOnBallZ.copy(this.camera.position);
      this.cursorOnBallZ.add(normalizedCursor3.multiplyScalar(distance));
    };

    const onCursorDown = (clientX, clientY) => {
      normalizedCursor2.set(
        (clientX / this.mount.clientWidth) * 2 - 1,
        (clientY / this.mount.clientHeight) * -2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(normalizedCursor2, this.camera);
      const intersects = raycaster.intersectObjects(this.pieces, false);

      if (intersects.length === 0 || intersects[0].object.isAttached)
        this.isDraggingBall = true;
      else {
        setCursorOnBallZ();
        intersects[0].object.isBeingDragged = true;
        this.isDraggingPiece = true;
        this.draggedPiece = intersects[0].object;
        this.draggedPiece.attachedPreview.visible = true;
        this.draggedPiece.animation.grab.fromPosition.copy(
          this.draggedPiece.position
        );
        this.draggedPiece.animation.grab.fromScale.copy(
          this.draggedPiece.scale
        );
        this.draggedPiece.animation.grab.isRunning = true;
      }
    };

    const onCursorUp = () => {
      this.mouseDrag.lastX = null;
      this.isDraggingBall = false;
      this.isDraggingPiece = false;
      if (this.draggedPiece) {
        this.draggedPiece.attachedPreview.visible = false;
        if (
          !this.draggedPiece.isAttached &&
          !this.draggedPiece.animation.attach.isRunning
        ) {
          this.draggedPiece.animation.letGo.fromPosition.copy(
            this.draggedPiece.position
          );
          this.draggedPiece.animation.letGo.fromScale.copy(
            this.draggedPiece.scale
          );
          this.draggedPiece.animation.letGo.isRunning = true;
        }
        this.draggedPiece.animation.grab.isRunning = false;
        this.draggedPiece.animation.grab.elapsedTime = 0;
        this.draggedPiece = null;
      }
      this.pieces.forEach((piece) => {
        piece.isBeingDragged = false;
      });
    };

    const cursorCoordinates = new THREE.Vector3();
    const cursorCoordinatesDelta = new THREE.Vector3();
    let lastCursorCoordinates = null;
    const onHover = (event) => {
      cursorCoordinates.set(
        (event.clientX / this.mount.clientWidth) * 2 - 1,
        (event.clientY / this.mount.clientHeight) * -2 + 1,
        0
      );
      if (!lastCursorCoordinates)
        lastCursorCoordinates = cursorCoordinates.clone();
      cursorCoordinatesDelta.subVectors(
        lastCursorCoordinates,
        cursorCoordinates
      );
      lastCursorCoordinates.copy(cursorCoordinates);
      this.house.position.add(cursorCoordinatesDelta);
    };

    const onMouseDown = (event) => {
      onCursorDown(event.clientX, event.clientY);
      this.mount.addEventListener("mousemove", onMouseDrag);
    };

    const onMouseUp = (_) => {
      this.mount.removeEventListener("mousemove", onMouseDrag);
      onCursorUp();
    };

    const onTouchStart = (event) => {
      onCursorDown(
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY
      );
      this.mount.addEventListener("touchmove", onTouchDrag);
    };

    const onTouchEnd = (_) => {
      this.mount.removeEventListener("mousemove", onTouchDrag);
      onCursorUp();
    };

    const setUpEventListeners = (_) => {
      const mouseMoveListenerHolder = new ListenerHolder(
        this.mount,
        "mousedown",
        onMouseDown
      );
      this.listeners.push(mouseMoveListenerHolder);
      mouseMoveListenerHolder.target.addEventListener(
        mouseMoveListenerHolder.name,
        mouseMoveListenerHolder.callback
      );

      const mouseUpListenerHolder = new ListenerHolder(
        this.mount,
        "mouseup",
        onMouseUp
      );
      this.listeners.push(mouseUpListenerHolder);
      mouseUpListenerHolder.target.addEventListener(
        mouseUpListenerHolder.name,
        mouseUpListenerHolder.callback
      );

      const touchStartListenerHolder = new ListenerHolder(
        this.mount,
        "touchstart",
        onTouchStart
      );
      this.listeners.push(touchStartListenerHolder);
      touchStartListenerHolder.target.addEventListener(
        touchStartListenerHolder.name,
        touchStartListenerHolder.callback
      );

      const touchEndListenerHolder = new ListenerHolder(
        this.mount,
        "touchend",
        onTouchEnd
      );
      this.listeners.push(touchEndListenerHolder);
      touchEndListenerHolder.target.addEventListener(
        touchEndListenerHolder.name,
        touchEndListenerHolder.callback
      );
    };

    const loadGLTF = (path, isPiece, index) => {
      const loader = new GLTFLoader();
      const model = process.env.PUBLIC_URL + path;
      loader.load(model, (gltf) => {
        gltf.scene.children[3].material.side = THREE.DoubleSide;
        switch (isPiece) {
          case "piece":
            this.pieces[index] = gltf.scene.children[3];
            break;
          case "skeleton":
            this.footballInner = gltf.scene.children[3];
            break;
          case "house":
            this.house = gltf.scene.children[3];
            this.house.scale.x = 0.2;
            this.house.scale.y = 0.2;
            this.house.scale.z = 0.2;
            this.house.rotation.y = -Math.PI * 2 * 0.08;

            this.house.basePosition = new THREE.Vector3(-15, -8, -18);
            this.house.position.copy(this.house.basePosition);
            break;
          default:
            return null;
        }

        this.scene.add(gltf.scene.children[3]);
        this.objectCounter++;

        if (this.objectCounter === this.totalObjects) {
          initObjects();
          const resizeListenerHolder = new ListenerHolder(
            window,
            "resize",
            onResize
          );
          this.listeners.push(resizeListenerHolder);
          resizeListenerHolder.target.addEventListener(
            resizeListenerHolder.name,
            resizeListenerHolder.callback
          );

          onResize();
          document.addEventListener("mousemove", onHover);
          const mouseMoveListenerHolder = new ListenerHolder(
            document,
            "mousemove",
            onHover
          );
          this.listeners.push(mouseMoveListenerHolder);
          mouseMoveListenerHolder.target.addEventListener(
            mouseMoveListenerHolder.name,
            mouseMoveListenerHolder.callback
          );
          setUpEventListeners();
          this.start();
        }
      });
    };

    const initPiece = (
      piece,
      idlePositionLandscape,
      idlePositionPortrait,
      rotation
    ) => {
      piece.attachedPreview = piece.clone();
      piece.attachedPreview.material = new THREE.MeshBasicMaterial({
        color: 0xff0000,
        wireframe: true,
      });
      piece.attachedPreview.visible = false;
      piece.attachedPreview.scale.copy(this.footballInner.scale);

      this.scene.add(piece.attachedPreview);
      piece.rotation.copy(rotation);
      piece.isAttached = false;
      piece.isBeingDragged = false;
      piece.idlePositionLandscape = idlePositionLandscape.clone();
      piece.idlePositionPortrait = idlePositionPortrait.clone();
      piece.idlePosition = piece.idlePositionLandscape.clone();
      piece.idleScaleLandscape = new THREE.Vector3(0.2, 0.2, 0.2);
      piece.idleScalePortrait = new THREE.Vector3(0.2, 0.2, 0.2);
      piece.idleScale = piece.idleScaleLandscape.clone();
      piece.position.copy(piece.idlePosition);
      piece.scale.copy(piece.idleScale);
      piece.animation = {
        attach: {
          elapsedTime: 0,
          isRunning: false,
          fromRotation: new THREE.Quaternion(),
          fromPosition: new THREE.Vector3(),
        },
        grab: {
          elapsedTime: 0,
          isRunning: false,
          fromScale: new THREE.Vector3(),
          fromPosition: new THREE.Vector3(),
        },
        letGo: {
          elapsedTime: 0,
          isRunning: false,
          fromScale: new THREE.Vector3(),
          fromPosition: new THREE.Vector3(),
        },
      };
      piece.onResize = (_) => {
        if (this.mount.clientWidth > this.mount.clientHeight) {
          // Landscape
          piece.idlePosition.copy(piece.idlePositionLandscape);
          piece.idleScale.copy(piece.idleScaleLandscape);
        } else {
          // Portrait
          piece.idlePosition.copy(piece.idlePositionPortrait);
          piece.idleScale.copy(piece.idleScalePortrait);
        }

        piece.attachedPreview.position.copy(this.footballInner.position);
        piece.attachedPreview.scale.copy(this.footballInner.scale);
        if (
          !piece.isAttached &&
          !piece.isBeingDragged &&
          !piece.animation.attach.isRunning &&
          !piece.animation.grab.isRunning &&
          !piece.animation.letGo.isRunning
        ) {
          piece.position.copy(piece.idlePosition);
          piece.scale.copy(piece.idleScale);
        }
      };
    };

    const initObjects = (_) => {
      this.pieces[0].isAttached = true;
      this.pieces[0].animation = {
        attach: {
          elapsedTime: 0,
          isRunning: false,
        },
        grab: {
          elapsedTime: 0,
          isRunning: false,
        },
        letGo: {
          elapsedTime: 0,
          isRunning: false,
        },
      };

      initPiece(
        this.pieces[1],
        new THREE.Vector3(-3, 1, 0),
        new THREE.Vector3(-1, -0.3, 0),
        new THREE.Euler(0, -Math.PI, 0)
      );

      initPiece(
        this.pieces[2],
        new THREE.Vector3(-3, -1, 0),
        new THREE.Vector3(0, -0.3, 0),
        new THREE.Euler(0, -Math.PI / 2, 0)
      );

      initPiece(
        this.pieces[3],
        new THREE.Vector3(3, 0, 0),
        new THREE.Vector3(1, -0.3, 0),
        new THREE.Euler(0, Math.PI, 0)
      );

      this.attachedPieces.push(this.pieces[0]);

      this.footballInner.rotation.y = 1;
      this.footballInner.positionLandscape = new THREE.Vector3(0, 0, 0);
      this.footballInner.positionPortrait = new THREE.Vector3(0, 1.5, 0);
      this.footballInner.scalePortrait = new THREE.Vector3(0.5, 0.5, 0.5);
      this.footballInner.scaleLandscape = new THREE.Vector3(1, 1, 1);

      this.footballInner.onResize = (_) => {
        if (this.mount.clientWidth > this.mount.clientHeight) {
          // Landscape
          this.footballInner.position.copy(
            this.footballInner.positionLandscape
          );
          this.footballInner.scale.copy(this.footballInner.scaleLandscape);
        } else {
          // Portrait
          this.footballInner.position.copy(this.footballInner.positionPortrait);
          this.footballInner.scale.copy(this.footballInner.scalePortrait);
        }

        this.attachedPieces.forEach((piece) => {
          piece.position.copy(this.footballInner.position);
        });
        this.attachedPieces.forEach((piece) => {
          piece.scale.copy(this.footballInner.scale);
        });
      };

      this.attachedPieces.forEach((piece) => {
        piece.rotation.copy(this.footballInner.rotation);
      });

      this.pieces.forEach((piece) => {
        if (piece.attachedPreview) {
          piece.attachedPreview.rotation.copy(this.footballInner.rotation);
          piece.attachedPreview.scale.copy(this.footballInner.scale);
          // FIXME: portrait with orientation: do backdrops and house collide?
        }
      });
    };

    loadGLTF(
      "/assets/GamePlan/VP_GamePlan_FootballPuzzle_Inner_Delivery_V01_unminified.gltf",
      "skeleton"
    );
    loadGLTF(
      "/assets/GamePlan/VP_GamePlan_FootballPuzzle_PieceA_Delivery_V01_unminified.gltf",
      "piece",
      2
    );
    loadGLTF(
      "/assets/GamePlan/VP_GamePlan_FootballPuzzle_PieceB_Delivery_V01_unminified.gltf",
      "piece",
      1
    );
    loadGLTF(
      "/assets/GamePlan/VP_GamePlan_FootballPuzzle_PieceC_Delivery_V01_unminified.gltf",
      "piece",
      0
    );
    loadGLTF(
      "/assets/GamePlan/VP_GamePlan_FootballPuzzle_PieceD_Delivery_V01_unminified.gltf",
      "piece",
      3
    );
    loadGLTF(
      "/assets/GamePlan/VP_GamePlan_FootballScene_Delivery_Delivery_V012.gltf",
      "house"
    );
  }

  componentWillUnmount() {
    this.listeners.forEach((listenerHolder) => {
      listenerHolder.target.removeEventListener(
        listenerHolder.name,
        listenerHolder.callback
      );
    });
    this.stop();
  }
  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };
  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = (elapsedTime) => {
    if (!this.lastElapsedTime) this.lastElapsedTime = elapsedTime;

    if (this.orientation.isEnabled) {
      this.orientation.update();
      this.house.position.x += this.orientation.delta.y;
    }

    const tslf = elapsedTime - this.lastElapsedTime;
    this.lastElapsedTime = elapsedTime;

    this.pieces.forEach((piece) => {
      if (piece.animation.attach.isRunning) {
        piece.animation.attach.elapsedTime += tslf;
        const interpolator = Math.min(
          piece.animation.attach.elapsedTime / 500,
          1
        );
        THREE.Quaternion.slerp(
          piece.animation.attach.fromRotation,
          this.footballInner.quaternion,
          piece.quaternion,
          easeOutExpo(interpolator, 0, 1, 1)
        );
        piece.position.lerpVectors(
          piece.animation.attach.fromPosition,
          this.footballInner.position,
          easeOutExpo(interpolator, 0, 1, 1)
        );

        if (interpolator === 1) {
          piece.animation.attach.isRunning = false;
          piece.animation.attach.elapsedTime = 0;
          this.isPieceAnimating = false;
          piece.isBeingDragged = false;
          this.isDraggingPiece = false;
          this.draggedPiece = null;
          piece.isAttached = true;
          this.attachedPieces.push(piece);
          if (this.attachedPieces.length === this.pieces.length)
            this.props.updateMessage("puzzleSuccess");
          // SOUND: piece is attached
        }
      }
      if (piece.animation.grab.isRunning) {
        piece.animation.grab.elapsedTime += tslf;
        const interpolator = Math.min(
          piece.animation.grab.elapsedTime / 500,
          1
        );
        piece.scale.lerpVectors(
          piece.animation.grab.fromScale,
          this.footballInner.scale,
          easeOutBack(interpolator, 0, 1, 1)
        );
        piece.position.lerpVectors(
          piece.animation.grab.fromPosition,
          this.cursorOnBallZ,
          easeOutBack(interpolator, 0, 1, 1)
        );

        if (interpolator === 1) {
          piece.animation.grab.isRunning = false;
          piece.animation.grab.elapsedTime = 0;
          // SOUND: piece was grabbed
        }
      }
      if (piece.animation.letGo.isRunning) {
        piece.animation.letGo.elapsedTime += tslf;
        const interpolator = Math.min(
          piece.animation.letGo.elapsedTime / 500,
          1
        );
        piece.scale.lerpVectors(
          piece.animation.letGo.fromScale,
          piece.idleScale,
          easeOutExpo(interpolator, 0, 1, 1)
        );
        piece.position.lerpVectors(
          piece.animation.letGo.fromPosition,
          piece.idlePosition,
          easeOutExpo(interpolator, 0, 1, 1)
        );

        if (interpolator === 1) {
          piece.animation.letGo.isRunning = false;
          piece.animation.letGo.elapsedTime = 0;
          // SOUND: piece has snapped back to original position
        }
      }
    });

    this.attachedPieces.forEach((piece) => {
      piece.rotation.copy(this.footballInner.rotation);
    });

    this.renderer.render(this.scene, this.camera);
    this.frameId = window.requestAnimationFrame(this.animate);
  };
  render() {
    return (
      <canvas
        ref={(mount) => {
          this.mount = mount;
        }}
        style={{ width: "100%", height: "100%" }}
      ></canvas>
    );
  }
}
