import React from "react";
import { Link } from "react-router-dom";

import Mike from "../../assets/mike.png";
import Lorii from "../../assets/lorii.png";
import Humphrey from "../../assets/humphrey.png";

class NextStory extends React.Component {
  render() {
    const { current } = this.props;
    return (
      <div className="next-article item">
        {current && current !== "gameplan" && (
          <Link to="/gameplan" className="story-link">
            <div className="face">
              <img alt="" src={Mike} />
            </div>
            <h3>GAME PLAN</h3>
          </Link>
        )}
        {current && current !== "gameface" && (
          <Link to="/gameface" className="story-link">
            <div className="face">
              <img alt="" src={Lorii} />
            </div>
            <h3>GAME FACE</h3>
          </Link>
        )}
        {current && current !== "gameon" && (
          <Link to="/gameon" className="story-link">
            <div className="face">
              <img alt="" src={Humphrey} />
            </div>
            <h3>GAME ON</h3>
          </Link>
        )}
      </div>
    );
  }
}

export default NextStory;
