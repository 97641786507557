import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import { TimelineMax, Linear } from "gsap/all";

const ScrollMagic = window.ScrollMagic;

class ImageScroll extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();

    this.img = null;
    this.imgTween = null;
  }

  componentDidMount() {
    this.imgTween = new TimelineMax({ paused: false }).to(this.img, 0.3, {
      y: "-10%",
      scale: 1.2,
      ease: Linear.easeNone
    });
    new ScrollMagic.Scene({
      triggerElement: `#${this.props.imgId}`,
      triggerHook: 0.8,
      duration: "100%"
    })
      // .addIndicators()
      .setTween(this.imgTween)
      .addTo(this.controller);
  }

  render() {
    return (
      <div className={`img-wrap ${this.props.size}`} id={this.props.imgId}>
        <div
          className="container"
          ref={ref => {
            this.img = ref;
          }}
        >
          <LazyLoad
            offset={500}
            placeholder={<div className="placeholder"></div>}
          >
            <img src={this.props.src} alt="" />
          </LazyLoad>
        </div>
      </div>
    );
  }
}

export default ImageScroll;
