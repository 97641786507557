import React, { Component } from "react";
import TimelineCard from "./TimelineCard";
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class TwitchHistory extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        let settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        };
        return (
            <div className="twitch-block">
                <h2>A History of Twitch</h2>
                <div className="timeline-container">
                    <div className="wrapper-timeline-svg hide-cir">
                        <svg viewBox="0 0 4252 99" className="timeline-svg">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="image-copy" transform="translate(0.000000, -4.000000)">
                                    <g id="Group" transform="translate(37.912169, 42.611992)">
                                        <rect id="Rectangle" fill="#B5B5A0" x="0.087830823" y="9.38800792" width="4202"
                                            height="2" />
                                    </g>
                                    <g className="timeline-circle" data-step="2" transform="translate(1326, 42.948326)">
                                        <circle id="Oval" fill="#FB998E" fillRule="nonzero" cx="14" cy="10" r="10" />
                                        <text id="2032" fill="#000000" fontSize="16"
                                            fontWeight="normal">
                                            <tspan x="-30" y="45">March 2007</tspan>
                                        </text>
                                    </g>
                                    <g className="timeline-circle" data-step="1" transform="translate(1726, 43.212519)">
                                        <circle id="Oval" fill="#FB998E" fillRule="nonzero" cx="14" cy="10" r="10" />
                                        <text id="2034" fill="#000000" fontSize="16"
                                            fontWeight="normal">
                                            <tspan x="-20" y="45">July 2011</tspan>
                                        </text>
                                    </g>
                                    <g className="timeline-circle" data-step="0" transform="translate(2126, 43.005223)">
                                        <circle id="Oval" fill="#FB998E" fillRule="nonzero" cx="14" cy="10" r="10" />
                                        <text id="2037" fill="#000000" fontSize="16"
                                            fontWeight="normal">
                                            <tspan x="-35" y="45">October 2012</tspan>
                                        </text>
                                    </g>
                                    <g className="timeline-circle" data-step="1" transform="translate(2526, 42.994532)">
                                        <circle id="Oval" fill="#FB998E" fillRule="nonzero" cx="14" cy="10" r="10" />
                                        <text id="2039" fill="#000000" fontSize="16"
                                            fontWeight="normal">
                                            <tspan x="-35" y="45">August 2014</tspan>
                                        </text>
                                    </g>
                                    <g className="timeline-circle" data-step="2" transform="translate(2926, 42.781803)">
                                        <circle id="Oval" fill="#FB998E" fillRule="nonzero" cx="14" cy="10" r="10" />
                                        <text id="2045" fill="#000000" fontSize="16"
                                            fontWeight="normal">
                                            <tspan x="0" y="45">2015</tspan>
                                        </text>
                                    </g>
                                    <g className="timeline-circle" data-step="3" transform="translate(3326, 41.027786)">
                                        <circle id="Oval" fill="#FB998E" fillRule="nonzero" cx="14" cy="10.9722137" r="10" />
                                        <text id="2053" fill="#000000" fontSize="16"
                                            fontWeight="normal">
                                            <tspan x="0.291420646" y="47">Now</tspan>
                                        </text>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="wrapper-timeline">
                    <TimelineCard />
                    </div>
                </div>

                <div className="timeline-slide" id="timelineSlide">
                    <Slider ref={c => (this.slider = c)} {...settings}>

                        <div className="timeline-card">
                            <div className="timeline-card__inner">
                                <h4 className="timeline-card__header" id="timelineHeader">March 2007</h4>
                                <div className="timeline-card__content">
                                    <h4 className="timeline-card__date">March 2007</h4>
                                    <div className="timeline-card__description">
                                        Justin.tv was launched in March 2007 as a website that broadcast the life of one of the website’s founders, Justin Kan, around the clock
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-card">
                            <div className="timeline-card__inner">
                                <h4 className="timeline-card__header" id="timelineHeader">July 2011</h4>
                                <div className="timeline-card__content">
                                    <h4 className="timeline-card__date">July 2011</h4>
                                    <div className="timeline-card__description">
                                        In July 2011 - just one month after launch, Twitch.tv has 8 million unique viewers
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-card">
                            <div className="timeline-card__inner">
                                <h4 className="timeline-card__header" id="timelineHeader">October 2012</h4>
                                <div className="timeline-card__content">
                                    <h4 className="timeline-card__date">October 2012</h4>
                                    <div className="timeline-card__description">
                                        In October 2012, Twitch has over 20 million monthly viewers
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-card">
                            <div className="timeline-card__inner">
                                <h4 className="timeline-card__header" id="timelineHeader">August 2014</h4>
                                <div className="timeline-card__content">
                                    <h4 className="timeline-card__date">August 2014</h4>
                                    <div className="timeline-card__description">
                                        In August 2014, Twitch is acquired by Amazon for $970 million. At this point, Twitch has an average of over 55 million monthly viewers
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-card">
                            <div className="timeline-card__inner">
                                <h4 className="timeline-card__header" id="timelineHeader">2015</h4>
                                <div className="timeline-card__content">
                                    <h4 className="timeline-card__date">2015</h4>
                                    <div className="timeline-card__description">
                                        In 2015, Twitch had grown to 100 million viewers per month and over total 1.5 million total streaming accounts.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-card">
                            <div className="timeline-card__inner">
                                <h4 className="timeline-card__header" id="timelineHeader">Now</h4>
                                <div className="timeline-card__content">
                                    <h4 className="timeline-card__date">Now</h4>
                                    <div className="timeline-card__description">
                                        Now More than one million viewers are watching Twitch at any given moment and over three million unique broadcasters go live every month. To put this in context, the biggest TV shows in the world attract around 18 million viewers.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>

                    <div className="timeline-arrow">
                        <div>
                            <button className="timeline-arrow__btn timeline-arrow__btn--prev" onClick={this.previous}/>
                            <button className="timeline-arrow__btn timeline-arrow__btn--next" onClick={this.next}/>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default TwitchHistory;
