import * as windowListener from './window-scroll-listener';
import { createFrameViewer } from './frame-viewer';

export function create(options) {
  const {
    node,
    images,
    slideAnimationOptions = [],
    quoteAnimationOptions = [],
    withMobileFrameImages = false
  } = options;

  if (withMobileFrameImages) {
    node.setAttribute('data-with-mobile-frame-images', '');
  }

  const scrollbar = node.querySelector('[data-scrollbar]');
  const frameViewer = createFrameViewer(node.querySelector('[data-image-container]'), images);

  let activeFrameIndex = NaN;
  const unsubscribe = windowListener.onScroll(update);

  update();

  return {
    destroy
  };

  function destroy() {
    unsubscribe();
    frameViewer.destroy();
  }

  function update() {
    const scrollRatio = calcRelativeScrollRatio(node);

    updateScrollbarWidth(scrollbar, scrollRatio);
    updateScrollbarParentNodeVisibility(scrollbar);

    const newFrameIndex = calcFrameIndexBasedOnScrollRatio(scrollRatio, images.length);
    if (newFrameIndex === activeFrameIndex) {
      return;
    }
    activeFrameIndex = newFrameIndex;

    frameViewer.showByIndex(activeFrameIndex);

    updateSlides(slideAnimationOptions, activeFrameIndex);
    updateQuotes(quoteAnimationOptions, activeFrameIndex);
  }
}

function calcRelativeScrollRatio(node) {
  const rect = node.getBoundingClientRect();
  const viewportHeight = document.documentElement.clientHeight;

  if (rect.top > 0) {
    return 0;
  }

  if (rect.height <= viewportHeight) {
    if (rect.top >= 0) {
      return 0;
    }
    return 1;
  }

  const ratio = Math.abs(rect.top) / (rect.height - viewportHeight);
  return Math.min(1, ratio);
}

function calcFrameIndexBasedOnScrollRatio(scrollRatio, numberOfFrames) {
  return Math.floor(scrollRatio * numberOfFrames);
}

function updateScrollbarWidth(scrollbar, scrollRatio) {
  if (scrollbar === null) {
    return;
  }
  scrollbar.style.width = `${scrollRatio * 100}%`;
}

function updateScrollbarParentNodeVisibility(scrollbar) {
  if (scrollbar === null) {
    return;
  }

  const parent = scrollbar.offsetParent; // XXX copy/pasted from legacy code. Why not parentNode?
  if (parent.getBoundingClientRect().top <= 4) {
    parent.classList.add('show');
  } else {
    parent.classList.remove('show');
  }
}

function updateSlides(slideAnimationOptions, frameIndex) {
  slideAnimationOptions.forEach(({ tween, start, end }) => {
    if (typeof tween === 'undefined') {
      return;
    }
    tween.progress(calcAnimationRatio(frameIndex, start, end));
  });
}

function updateQuotes(quoteAnimationOptions, frameIndex) {
  quoteAnimationOptions.forEach(({ audio, tween, start, end }) => {
    if (typeof audio === 'undefined' && typeof tween === 'undefined') {
      return;
    }

    const animationRatio = calcAnimationRatio(frameIndex, start, end);

    if (typeof tween !== 'undefined') {
      tween.progress(animationRatio);
    }

    if (typeof audio === 'undefined') {
      return;
    }

    switch (true) {
      case animationRatio === 0:
        audio.pause();
        break;
      default:
        if (audio.paused) {
          audio.play();
        }
    }
  });
}

function calcAnimationRatio(frameIndex, animationStartFrameIndex, animationEndFrameIndex) {
  if (frameIndex < animationStartFrameIndex || frameIndex >= animationEndFrameIndex) {
    return 0;
  }
  return (frameIndex - animationStartFrameIndex) / (animationEndFrameIndex - animationStartFrameIndex);
}
