import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import Mute from "../Video/Mute";
import Video from "../Video/Video";
import JointFundLogo from "../Landing/JointFundLogo";

import { videoPaths, audioPaths } from "../../utils/mediaManager";

import "./About.scss";

const credits = [
  {
    role: "Commisioned by",
    name: "RNZ: Tim Burnell and Kay Ellmers",
  },
  {
    role: "Created and Produced by",
    name: "Vanishing Point Studio",
  },
  {
    role: "Creative director and lead reporter",
    name: "Charles Anderson",
  },
  {
    role: "Interactive designer and lead developer",
    name: "Allan Walker",
  },
  {
    role: "3D modeling and animation",
    name: "Jacob Barrow and Myles Montgomery of Shadow Space",
  },
  {
    role: "2D interactive animation and illustration",
    name: "Shannon Jahnel Lanktree of Watermark",
  },
  {
    role: "2D illustration supervisor",
    name: "David Way and Watermark",
  },
  {
    role: "Video production and editing",
    name: "Josh Morrice",
  },
  {
    role: "Producer and additional reporting",
    name: "Lee Henaghan",
  },
  {
    role: "Research and testing",
    name: "Hannah Spyksma",
  },
  {
    role: "Interactive audio design",
    name: "Dylan Galletly",
  },
  {
    role: "Interactive development",
    name: "Arpo Deer",
  },
  {
    role: "WebGL development",
    name: "Tim Konieczny",
  },
  {
    role: "Ashen stills and gameplay footage",
    name: "Courtesy of A44",
  },
];

let nav = null;
let audioList = new Array();
let loopTrack = null;

class About extends Component {
  constructor() {
    super();
    this.state = {
      audioEnabled: false,
      playPrompt: false,
      muteState: false,
    };
  }

  exitPage = () => {
    const page = document.getElementById("about-page");
    page.classList.add("exit");
    setTimeout(() => {
      this.props.history.push("/");
    }, 600);
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    nav = document.getElementById("nav-bar");
    if (nav) {
      nav.classList.add("bg-black");
    }

    loopTrack = new Audio(audioPaths("HomePageMusicBed.mp3"));
    loopTrack.volume = 0.4;
    this.controlPlayState(loopTrack);
    loopTrack.addEventListener(
      "ended",
      function () {
        console.log("looped");
        this.currentTime = 0;
        this.play();
      },
      false
    );
    audioList.push(loopTrack);
  }
  componentWillUnmount() {
    if (nav) {
      nav.classList.remove("bg-black");
    }

    audioList.map((audio) => {
      audio.muted = true;
      audio.pause();
    });
  }

  unmute = () => {
    this.controlPlayState(loopTrack);
  };

  muteToggle = () => {
    console.log("mute toggle");
    this.setState({ muteState: !this.state.muteState });
    // Now mute or unmute all audio on this page
    audioList.map((audio) => {
      audio.muted = !this.state.muteState;
    });
  };

  controlPlayState = (audio) => {
    if (audio.paused) {
      var promise = audio.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            this.setState({ audioEnabled: true, playPrompt: false });
            console.log("autoplay");
          })
          .catch((error) => {
            // Show a button on the video saying pres play to start
            this.setState({ playPrompt: true });
          });
      }
    } else {
      audio.pause();
      this.setState({ audioEnabled: false });
    }
  };

  render() {
    return (
      <div className="about-page" id="about-page">
        <div className="mute-toggle" onClick={this.muteToggle}>
          <Mute mode={this.state.muteState ? "mute-off" : "mute-on"} />
        </div>
        <h3 className="about-page-title">About</h3>
        <div className="inner-center">
          <h1>
            Game Change is an interactive exploration of New Zealand’s
            burgeoning video game industry.
          </h1>
          <div className="content">
            <div className="about-text">
              <p>
                The project, made possible by the RNZ/NZ On Air Innovation Fund,
                delves into three stories about an industry that is worth $200m
                a year to the country. However, it is an industry that many do
                not know about, let alone understand.
              </p>

              <p>
                Game Change's creators wanted the public to get a feel and
                appreciation for what interactive media can do. As such, this
                project uses the innovative design, language and mechanics of
                video games but applies them to traditional journalistic
                storytelling.
              </p>

              <p>
                The stories let the audience have a say in their outcomes -
                whether it be following the journey of one of the country’s
                up-and-coming video game designers, or helping a young single
                mum balance her family life with being a professional ‘streamer’
                that broadcasts her game play to the world. The project also
                features the story of Humphrey Hanley, who was born with a rare
                condition that means he no longer has any hands. By 3D scanning
                Humphrey and turning him into a video game character, the story
                touches on how games can open up entire worlds and communities,
                especially for those that are living with disabilities.
              </p>

              <p>
                Game Change brings together designers, developers, animators,
                illustrators, videographers, 3D modelers and journalists to
                create a unique interactive project.
              </p>
            </div>

            <div className="bts-wrap">
              <h2>Behind the Scenes</h2>
              <Video
                videoSrc={videoPaths("BTS.mp4")}
                title={"Behind the Scenes"}
                youTube="https://www.youtube.com/embed/vJ73_hKnO2c"
              />
            </div>

            <h2 className="credits-label">Credits</h2>
            <div className="credits">
              {credits &&
                credits.map((credit) => {
                  return (
                    <div className="credit">
                      <div className="role">{credit.role}</div>
                      <div className="name">{credit.name}</div>
                    </div>
                  );
                })}
            </div>
            <div className="accred">
              Made possible by the RNZ/NZ On Air Innovation Fund.
            </div>
            <JointFundLogo />
          </div>
        </div>
        <div className="modal-video" id="modalVideo"></div>
      </div>
    );
  }
}

export default withRouter(About);
