import React, { Component } from "react";
import { TimelineMax, Expo } from "gsap/all";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";

import Hero from "../Story/Hero";
import ImageColumns from "../Article/ImageColumns";
import ImageFull from "../Article/ImageFull";
import NextStory from "../Article/NextStory";
import Mute from "../Video/Mute";

import * as canvasSequence from "../../lib/canvas-sequence";

import { faceSliderHumphreyReal } from "../../utils/fileManager";
import { imagePaths, audioPaths } from "../../utils/mediaManager";
import { disableScroll, enableScroll } from "../../utils/scrollUtils";

import HumphreyAudioOne from "./HumphreyAudioOne";
import HumphreyAudioTwo from "./HumphreyAudioTwo";
import HumphreyAudioFour from "./HumphreyAudioFour";

import {
  canvasFrames,
  canvasMobileFrames,
  getFirstSequenceImageUrls,
  getSecondSequenceImageUrls,
} from "../../utils/gameOnSequences";

import "./GameOnCanvas.scss";

const accessory = imagePaths("gameon", "accessory.jpg");
const character = imagePaths("gameon", "character.jpg");
const coffee = imagePaths("gameon", "coffee.jpg");
const dog = imagePaths("gameon", "dog.jpg");
const driving = imagePaths("gameon", "driving.jpg");
const friends = imagePaths("gameon", "friends.jpg");
const playing = imagePaths("gameon", "playing.jpg");
const screen = imagePaths("gameon", "screen.jpg");

const audioOneId = "humphrey1";
const audioTwoId = "humphrey2";
const audioFourId = "humphrey4";

let app = null;
let partOneAnimations = null;
let partTwoAnimations = null;
let partOneQuotes = null;
// let partTwoQuotes = null;
let frames = null;
let partOneEnd = 436;
let partTwoStart = 437;
let partTwoEnd = 793;
let imgWidth = 1271;
let imgHeight = 720;

let audioList = [];
let loopTrack = null;

let audioOne = null;
let audioTwo = null;
let audioFour = null;

let nav = null;

class GameOnCanvas extends Component {
  constructor() {
    super();
    this.state = {
      loadProgress: 0.0,
      audioEnabled: false,
      playPrompt: false,
      muteState: false,
      hideScroll: true,
      framesLoaded: false,
    };

    this.loadBar = null;
    this.loadWrap = null;
    this.processing = null;
    this.article = null;
    this.partOne = null;
    this.partTwo = null;

    this.canvasSequences = [];
  }
  componentDidMount() {
    console.log("game on mounted");
    ReactGA.pageview(window.location.pathname + window.location.search);

    app = document.getElementById("app");
    if (app) {
      app.classList.add("game-on-page");
    }

    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    disableScroll();

    nav = document.getElementById("nav-bar");
    this.onScroll();

    // new SmoothScroll(".gameOnCanvas", {
    //   duration: 2000,
    //   timingFunction: "cubic-bezier(0.23, 1, 0.32, 1)"
    // });

    loopTrack = new Audio(audioPaths("HomePageMusicBed.mp3"));
    this.controlPlayState(loopTrack);
    loopTrack.addEventListener(
      "ended",
      function () {
        console.log("looped");
        this.currentTime = 0;
        this.play();
      },
      false
    );
    audioList.push(loopTrack);

    audioOne = document.getElementById(audioOneId);
    audioTwo = document.getElementById(audioTwoId);
    audioFour = document.getElementById(audioFourId);

    if (audioOne) audioOne.volume = 0.3;
    if (audioTwo) audioTwo.volume = 0.3;
    if (audioFour) audioFour.volume = 0.3;

    if (window.innerWidth > 700) {
      frames = canvasFrames();
    } else {
      frames = canvasMobileFrames();
      partOneEnd = 436; // Confirm this
      partTwoStart = 437; // Confirm this
      partTwoEnd = 782;
      imgWidth = 1008;
      imgHeight = 1600;
    }

    const slideAnimations = {};

    const slides = document.querySelectorAll(".slide");
    slides.forEach((slide) => {
      const slideId = slide.id;
      const slideTween = new TimelineMax({ paused: true })
        .set(`#${slideId}`, {
          autoAlpha: 0,
          y: 40,
          transform: "scale(1.4)",
          ease: Expo.easeInOut,
        })
        .to(`#${slideId}`, 0.5, {
          autoAlpha: 1,
          transform: "scale(1)",
          ease: Expo.easeInOut,
        })
        .to(
          `#${slideId}`,
          2,
          {
            y: -40,
            ease: Expo.easeInOut,
          },
          "-=0.5"
        )
        .to(
          `#${slideId}`,
          0.5,
          { autoAlpha: 0, ease: Expo.easeInOut },
          "-=0.5"
        );

      slideAnimations[slideId] = slideTween;
    });

    partOneAnimations = [
      {
        start: 1,
        end: 41,
        tween: slideAnimations["initial"],
      },
      {
        start: 42, //42
        end: 96, //96
        tween: slideAnimations["rotate"],
      },
      {
        start: 97,
        end: 155,
        tween: slideAnimations["hands"],
      },
      {
        start: 199,
        end: 280,
        tween: slideAnimations["zoomIn"],
      },
      {
        start: 281,
        end: 297,
        tween: slideAnimations["zoomOut"],
      },
      {
        start: 352,
        end: 436,
        tween: slideAnimations["humphreyDissolve"],
      },
    ];

    partTwoAnimations = [
      {
        start: 1,
        end: 50,
        tween: slideAnimations["controllerIntro"],
        name: "controllerIntro",
      },
      {
        start: 51,
        end: 65,
        tween: slideAnimations["controllerRotateStart"],
        name: "controllerRotateStart",
      },
      {
        start: 66,
        end: 77,
        tween: slideAnimations["controllerRotateFinish"],
        name: "controllerRotateFinish",
      },
      {
        start: 78,
        end: 125,
        tween: slideAnimations["controllerExplode"],
        name: "controllerExplode",
      },
      {
        start: 126,
        end: 238,
        tween: slideAnimations["controllerToHumphrey"],
        name: "controllerToHumphrey",
      },
      {
        start: 239,
        end: 298,
        tween: slideAnimations["superHeroHands"],
        name: "superHeroHands",
      },
    ];

    const quoteAnimations = {};

    const quotes = document.querySelectorAll(".quote");
    quotes.forEach((quote) => {
      const quoteId = quote.id;

      const quoteTween = new TimelineMax({ paused: true })
        .set(`#${quoteId}`, {
          autoAlpha: 0,
          ease: Expo.easeInOut,
        })
        .to(`#${quoteId}`, 0.3, {
          autoAlpha: 1,
          ease: Expo.easeInOut,
        })
        .to(`#${quoteId}`, 2, {
          autoAlpha: 1,
          y: -200,
          ease: Expo.easeInOut,
        })
        .to(`#${quoteId}`, 0.3, {
          autoAlpha: 0,
          ease: Expo.easeInOut,
        });

      let audio = null;
      if (quoteId === "sway1") {
        audio = audioOne;
      } else if (quoteId === "sway2") {
        audio = audioTwo;
      } else if (quoteId === "almost-impossible") {
        audio = audioFour;
      }

      quoteAnimations[quoteId] = {
        audio,
        quoteTween,
      };
    });

    partOneQuotes = [
      {
        start: 156,
        end: 198,
        audio: quoteAnimations["sway1"].audio,
        tween: quoteAnimations["sway1"].quoteTween,
        name: "sway1",
      },
      {
        start: 298,
        end: 351,
        audio: quoteAnimations["sway2"].audio,
        tween: quoteAnimations["sway2"].quoteTween,
        name: "sway2",
      },
    ];

    const shouldUseMobileFrames = window.innerWidth <= 700;
    this.canvasSequences = [
      canvasSequence.create({
        node: this.partOne,
        images: getFirstSequenceImageUrls(shouldUseMobileFrames),
        slideAnimationOptions: partOneAnimations,
        quoteAnimationOptions: partOneQuotes,
        withMobileFrameImages: shouldUseMobileFrames,
      }),
      canvasSequence.create({
        node: this.partTwo,
        images: getSecondSequenceImageUrls(shouldUseMobileFrames),
        slideAnimationOptions: partTwoAnimations,
        withMobileFrameImages: shouldUseMobileFrames,
      }),
    ];

    // Pretend loading bar
    let duration = 6;
    if (isMobile) {
      duration = 10;
    }

    setInterval(() => {
      if (duration > 0) {
        const progress = ((6 - duration) / 6) * 100;
        this.setState({ loadProgress: Math.round(progress) });

        if (this.loadBar) {
          this.loadBar.style.width = progress + "%";
        }
        duration -= 0.1;
      } else {
        if (this.loadWrap) {
          this.loadWrap.style.opacity = 0;
          this.setState({ framesLoaded: true });
          if (this.state.audioEnabled === true) {
            this.setState({ hideScroll: false });
            enableScroll();
          }
        }
      }
    }, 100);
  }

  componentWillUnmount() {
    if (app) {
      app.classList.remove("game-on-page");
    }
    enableScroll();
    if (audioOne) {
      audioOne.pause();
      audioOne.volume = 0;
    }
    if (audioTwo) {
      audioTwo.pause();
      audioTwo.volume = 0;
    }
    if (audioFour) {
      audioFour.pause();
      audioFour.volume = 0;
    }
    audioList.map((audio) => {
      audio.muted = true;
      audio.pause();
    });

    if (nav) {
      nav.classList.remove("dark");
      nav.classList.remove("bg-black");
    }

    this.canvasSequences.forEach((obj) => obj.destroy());
  }

  onScroll = () => {
    window.addEventListener("scroll", this.scrollFunction);
  };

  scrollFunction = () => {
    let scrollPosition = window.pageYOffset;
    if (this.article) {
      const articlePosition = this.article.offsetTop;
      const articleHeight = this.article.getBoundingClientRect().height;
      const endArticlePosition = Math.floor(articlePosition + articleHeight);

      const partOnePosition = this.partOne.offsetTop;
      const partOneHeight = this.partOne.getBoundingClientRect().height;
      const endPartOnePosition = Math.floor(partOnePosition + partOneHeight);

      const navHeight = nav.getBoundingClientRect().height;
      if (nav) {
        if (
          scrollPosition >= partOnePosition &&
          scrollPosition <= endPartOnePosition - navHeight
        ) {
          nav.classList.add("bg-black");
        }

        if (
          scrollPosition >= articlePosition - navHeight / 2 &&
          scrollPosition <= endArticlePosition - navHeight
        ) {
          nav.classList.add("dark");
          nav.classList.remove("light");
          nav.classList.remove("bg-black");
        } else if (scrollPosition > endArticlePosition) {
          nav.classList.add("bg-black");
          nav.classList.add("light");
          nav.classList.remove("dark");
        } else {
          nav.classList.add("light");
          nav.classList.remove("dark");
        }
      }
    }
  };

  unmute = () => {
    this.controlPlayState(loopTrack);
  };

  unmuteAndStart = () => {
    // const windowHeight = window.innerHeight;
    // window.scrollTo(0, windowHeight);

    this.controlPlayState(loopTrack);
    this.setState({ hideScroll: false });
    enableScroll();
  };

  muteToggle = () => {
    console.log("mute toggle");
    this.setState({ muteState: !this.state.muteState });
    // Now mute or unmute all audio on this page
    audioList.map((audio) => {
      audio.muted = !this.state.muteState;
    });
  };

  controlPlayState = (audio) => {
    if (audio.paused) {
      var promise = audio.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            this.setState({ audioEnabled: true, playPrompt: false });
            console.log("autoplay");
          })
          .catch((error) => {
            // Show a button on the video saying pres play to start
            this.setState({ playPrompt: true });
          });
      }
    } else {
      audio.pause();
      this.setState({ audioEnabled: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="gameOnCanvas" style={{ width: "100%" }}>
          <div className="mute-toggle" onClick={this.muteToggle}>
            <Mute mode={this.state.muteState ? "mute-off" : "mute-on"} />
          </div>

          <div className="hero-wrap game-on">
            <Hero
              story="gameOn"
              video={faceSliderHumphreyReal()}
              ref={(ref) => (this.hero = ref)}
              title="Game On"
              lineOne="The adaptive controller"
              lineTwo="changing the face of"
              lineThree="accessible gaming"
              hideScroll={this.state.hideScroll}
            />
            {this.state.playPrompt === true &&
              this.state.framesLoaded === false && (
                <div className="play-prompt" onClick={this.unmute}>
                  <div className="btn">Play Sounds (recommended)</div>
                </div>
              )}
            {this.state.playPrompt === true &&
              this.state.framesLoaded === true && (
                <div className="play-prompt" onClick={this.unmuteAndStart}>
                  <div className="btn">Click to continue</div>
                </div>
              )}
            <div
              className="load-wrap"
              ref={(ref) => {
                this.loadWrap = ref;
              }}
            >
              <div className="label">Loading {this.state.loadProgress}%</div>
              <div className="load-bar">
                <div
                  className="bar"
                  ref={(ref) => {
                    this.loadBar = ref;
                  }}
                ></div>
              </div>
              <div className="wifi-msg">
                This article is best experienced on Desktop or on a Wifi
                connection
              </div>
            </div>
          </div>
          <div
            className="part-one-wrap"
            style={{ minHeight: "1500vh" }}
            ref={(ref) => {
              this.partOne = ref;
            }}
          >
            <div data-scrollbar className="scroll-progress">
              <div className="bar" id="scroll-progress-one"></div>
            </div>
            <div id="canvas-wrap-one" className="canvas-wrap">
              <div data-image-container className="image-container"></div>
              <div id="canvas-shadow-one" className="canvas-shadow"></div>
            </div>
            <div className="slides">
              <div id="initial" className="slide"></div>
              <div id="rotate" className="slide">
                <p>This is Humphrey Hanley.</p>
              </div>
              <div id="hands" className="slide">
                <p>He was born with hands.</p>
                <p className="small">He was born with fingernails.</p>
                <p className="small">
                  But it was quickly apparent that there was a problem.
                </p>
              </div>
              <div id="zoomIn" className="slide">
                <p>
                  He was also born with a rare condition, which means his skin
                  does not attach like most people's.
                </p>
              </div>
              <div id="sway1" className="quote">
                {/* "The easiest way for a wound to heal is to pull..." */}
                <HumphreyAudioOne
                  audioId={audioOneId}
                  muteState={this.state.muteState}
                />
              </div>
              <div id="zoomOut" className="slide">
                <p>
                  The condition, called epidermolysis bullosa, means over the
                  years when his hands would rub and blister, they would scar
                  over.
                </p>
              </div>
              <div id="sway2" className="quote">
                {/* "Life back then was obviously very different..." */}
                <HumphreyAudioTwo
                  audioId={audioTwoId}
                  muteState={this.state.muteState}
                />
              </div>
              <div id="humphreyDissolve" className="slide">
                <p>
                  Now, save for a thumb, he has no fingers. Online, he goes by
                  the name "No Hands, No Excuses".
                </p>
              </div>
            </div>
          </div>

          <div
            className="article-main"
            ref={(ref) => {
              this.article = ref;
            }}
          >
            <div className="article-wrap">
              <div className="block-container intro">
                <div className="text-block">
                  <p>
                    It happened so slowly that he didn’t notice. Ten years ago,
                    Humphrey could hold a pool cue. Now, he has adapted every
                    part of his life to his reality.
                  </p>
                </div>
              </div>
              <div className="block-container image-block">
                <ImageFull src={playing} size="wide" imgId="playing" />
                <ImageColumns
                  leftCol={[{ src: screen, size: "square", imgId: "screen" }]}
                  leftClasses=""
                  rightCol={[
                    {
                      src: character,
                      size: "tall",
                      imgId: "character",
                    },
                  ]}
                  rightClasses=" align-end"
                />
              </div>
              <div className="block-container">
                <div className="text-block">
                  <p>
                    He goes to the same coffee shop every day because he doesn’t
                    need to keep telling them that he needs his coffee lukewarm,
                    or that he won’t be able to pick it up. He has cable ties on
                    all his cameras to allow him to easily zoom his lenses in
                    and out. He has a friend who 3D prints him accessories to
                    allow him to use his computer more comfortably. He wears
                    double tennis wristbands, over where his hands should be, to
                    give him some extra padding.
                  </p>
                  <p>
                    “It’s one of the joys of growing up Kiwi, we all learn how
                    to adapt,” Humphrey says.
                  </p>
                  <p>
                    But one of the more difficult parts of growing up Kiwi was
                    dealing with others. Because any sort of friction could
                    cause him damage, even walking around the playground with
                    other children was almost impossible.
                  </p>
                  <p>
                    He had some friends his own age, but more often than not, he
                    preferred to stay away from those situations.
                  </p>
                  <p>
                    Starting from when he was very young, Humphrey also
                    remembers computers being an escape. In the 1980s, he first
                    played his grandfather's computer. Then a local Lions club
                    fundraised to get him an Amiga 500 - one of the first
                    successful home computers.
                  </p>
                  <p>
                    Having such a machine meant that there was something that
                    Humphrey could do on his own. Then, when the internet came
                    along it allowed him to be part of communities and not be
                    judged on his handshake, “or lack thereof”.
                  </p>
                  <p>
                    “It’s almost impossible to quantify how important it was to
                    find a place like that. We are all searching for a place we
                    can belong.”
                  </p>
                  <p>
                    Playing video games was the first time that he felt like he
                    was picked for a team.
                  </p>
                </div>
              </div>
              <div className="block-container image-block">
                <ImageFull src={driving} size="wide" imgId="driving" />

                <ImageColumns
                  leftCol={[
                    {
                      src: coffee,
                      size: "tall",
                      imgId: "coffee",
                    },
                  ]}
                  leftClasses=""
                  rightCol={[
                    {
                      src: friends,
                      size: "square",
                      imgId: "friends",
                    },
                    {
                      src: dog,
                      size: "tall",
                      imgId: "dog",
                    },
                  ]}
                  rightClasses=" align-end"
                />
                <ImageFull src={accessory} size="wide" imgId="accessory" />
              </div>
              <div className="block-container">
                <div className="text-block community">
                  Humphrey had been seeing this community grow around him -
                  people with disabilities who were playing games. All of them
                  were trying to make do with the equipment that was available.
                  There wasn't much that was made with them in mind. That
                  demographic of gamers had always been on the periphery to the
                  huge mass market. Then something strange happened.
                </div>
              </div>
              <div className="block-container">
                <div className="text-block">
                  <p>
                    At the 2019 SuperBowl, which offers up the most expensive
                    advertising space in the world, Microsoft launched a 1
                    minute promotion. But, instead of it highlighting a new game
                    title on its Xbox, they let the world know about its new
                    “adaptive controller”.
                  </p>
                  <p>
                    The advert featured case studies of children who were all
                    now able to play video games a little easier than before
                    thanks to this piece of hardware. The controller gave
                    players the ability to augment it, depending on the needs of
                    its user.
                  </p>
                  <p>The tagline was: “When everybody plays, we all win.”</p>
                  <p>Cher tweeted about it. Rapper T-Pain tweeted about it.</p>
                  <p>
                    “People you never hear talk about video games, you never
                    hear them talk about disability – having both was
                    incredible,” says Humphrey.
                  </p>
                </div>
              </div>
              <div className="block-container youtube-video">
                <div className="text-block">
                  <div className="video-wrap">
                    <iframe
                      src="https://www.youtube.com/embed/haerMj8I6Ns"
                      frameBorder="0"
                      allow="accelerometer; encrypted-media; gyroscope;"
                      // playsInline
                      allowFullScreen
                      title="https://www.youtube.com/embed/haerMj8I6Ns"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="part-two-wrap"
            style={{ minHeight: "1500vh" }}
            ref={(ref) => {
              this.partTwo = ref;
            }}
          >
            <div data-scrollbar className="scroll-progress">
              <div className="bar" id="scroll-progress-two"></div>
            </div>
            <div id="canvas-wrap-two" className="canvas-wrap">
              <div data-image-container className="image-container"></div>
              <div id="canvas-shadow-two" className="canvas-shadow"></div>
            </div>
            <div className="slides">
              <div id="controllerIntro" className="slide">
                <p>The controller looks simple enough.</p>
                <p className="small">
                  But it allows different equipment to be attached to it.
                </p>
              </div>

              <div id="controllerRotateStart" className="slide">
                <p>It could be a foot pedal, which is how Humphrey uses it.</p>
                <p className="small">
                  Or a joystick you can operate with your mouth.
                </p>
              </div>

              <div id="controllerRotateFinish" className="slide"></div>

              <div id="controllerExplode" className="slide">
                <p>Or even just larger buttons.</p>
                <p className="small">
                  With another function, it can allow another gamer to help
                  control players on screen.
                </p>
                <p className="small">
                  So, if a person has a very limited mobility, perhaps they can
                  do the running while a friend does the jumping.
                </p>
              </div>

              <div id="controllerToHumphrey" className="slide">
                <p>
                  The whole gaming industry has evolved with the assumption that
                  the people who are playing have the full function of all their
                  limbs.
                </p>
              </div>

              <div id="unknown" className="slide">
                <p>
                  But Humphrey says that means a key group of people have been
                  missed - ones who by no choice of their own are often limited
                  by their conditions.
                </p>
                <p className="small">
                  And Humphrey says, hopefully, they are teaching game
                  developers and production studios that they matter.
                </p>
              </div>

              <div id="almost-impossible" className="quote">
                <HumphreyAudioFour
                  audioId={audioFourId}
                  muteState={this.state.muteState}
                />
              </div>
              <div id="superHeroHands" className="slide">
                <p>
                  Humphrey says gaming creates a way for people to be a part of
                  the real world, be part of real communities and have real
                  friendships.
                </p>
                <p className="inline-quote">
                  "Having a virtual world is like being set free."
                </p>
              </div>
            </div>
          </div>
          <div style={{ minHeight: "100vh" }}></div>
          <NextStory current="gameon" />
        </div>
        {/* <div className="hitbox"></div> */}
      </React.Fragment>
    );
  }
}

export default GameOnCanvas;
