import React from "react";
import { useDrop } from "react-dnd";

// function collect(connect, monitor) {
//   if (monitor.isOver()) {
//     console.log("over target");
//     if (document.getElementById("imgMainScene"))
//       document.getElementById("imgMainScene").childNodes[0].style.transform =
//         "scale(1.2)";
//   } else {
//     if (document.getElementById("imgMainScene"))
//       document.getElementById("imgMainScene").childNodes[0].style.transform =
//         "scale(1)";
//   }
//   return {
//     connectDropTarget: connect.dropTarget(),
//     hovered: monitor.isOver(),
//     item: monitor.getItem(),
//   };
// }

// class BoxTarget extends React.Component {
//   render() {
//     const { connectDropTarget, hovered } = this.props;
//     const classEl = hovered ? " is-over" : "";
//     return connectDropTarget(<div className={`box-target${classEl}`}></div>);
//   }
// }
// export default DropTarget("box-level-up", {}, collect)(BoxTarget);

const BoxTarget = () => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "box",
    drop: () => ({ name: "Mike" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;

  if (isOver) {
    console.log("over target");
    if (document.getElementById("imgMainScene"))
      document.getElementById("imgMainScene").childNodes[0].style.transform =
        "scale(1.2)";
  } else {
    if (document.getElementById("imgMainScene"))
      document.getElementById("imgMainScene").childNodes[0].style.transform =
        "scale(1)";
  }

  const classEl = isActive ? " is-over" : "";
  return <div className={`box-target${classEl}`} ref={drop}></div>;
};

export default BoxTarget;
