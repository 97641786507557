export const getSceneImgValue = coord => {
  return coord / 1920;
};

export const getDelay = (a, b) => {
  const delay = b.start - a.stop;
  const item = {
    delay: parseFloat(delay.toFixed(1)),
    word: b.word
  };
  return item;
};

export const screenSize = () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const screen = {
    width,
    height
  };

  return screen;
};
