import { getSceneImgValue } from "../../utils/utils";

// import image1 from "../../assets/gameplan/stills/mike-intro-a.png";
// import image2 from "../../assets/gameplan/stills/mike-intro-b.png";
import image3 from "../../assets/gameplan/stills/mike-intro-c.png";
import image4 from "../../assets/gameplan/stills/mike-intro-d.png";
// import image5 from "../../assets/gameplan/stills/graduation.png";
// import image6 from "../../assets/gameplan/stills/conference-a.png";
import image7 from "../../assets/gameplan/stills/conference-b.png";
// import image8 from "../../assets/gameplan/stills/agileboard.png";
// import image9 from "../../assets/gameplan/stills/studio-party.png";
// import image11 from "../../assets/gameplan/stills/studio-production.png";
// import image14 from "../../assets/gameplan/stills/crunch.png";
// import image15 from "../../assets/gameplan/stills/parliament-a.png";
// import image16 from "../../assets/gameplan/stills/parliament-b.png";
// import image20 from "../../assets/gameplan/stills/cruise.png";
import image24 from "../../assets/gameplan/stills/level-up.png";
// import image25 from "../../assets/gameplan/wires/SoccerGame.png";
// import image26 from "../../assets/gameplan/stills/studio-home.png";

export const overlayContent = {
  scene1: {
    text:
      "Meet Mike. He is part of an industry that brought in $203 million in revenue to New Zealand in 2019. Mike makes video games - this video game is based on Mike's real life.",
    options: [{ label: "Next", link: "2" }],
    img: {
      alt: "Mike in his office",
      imageId: "scene1",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: null,
  },
  scene2: {
    text:
      "Mike is the lead game designer for A44, a game studio based near Wellington in New Zealand.",
    options: [{ label: "Next", link: "2-1" }],
    img: {
      alt: "Mike standing",
      imageId: "scene2",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "1",
  },
  scene2dash1: {
    text:
      "He has just released the company's first game to critical acclaim, contributing to the industry's 39% annual growth.",
    options: [{ label: "Next", link: "2-2" }],
    img: {
      defaultSrc: image3,
      alt: "",
      imageId: "scene3",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "2",
  },
  scene2dash2: {
    text:
      "It has been a long journey for Mike, who almost chose a different career path. Can you help him to release his studio's game and help forge the way for the industry in New Zealand?",
    options: [{ label: "Continue", link: "3", transition: "studioToHome" }],
    img: {
      defaultSrc: image4,
      alt: "",
      imageId: "scene4",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "2-1",
  },
  scene3: {
    text:
      "Mike was obsessed with soccer in his native Belgium. But he also loved games and spent his childhood playing and making them. Do you want to play soccer or explore your love for games?",
    options: [
      { label: "Soccer?", link: "3-1" },
      {
        label: "Puzzle?",
        link: "4",
        Text: "Game where you can shoot the ball into the net.",
      },
    ],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "2-2",
  },
  scene3dash1: {
    text: "Try shoot the ball in the net. Grab the ball and release to kick.",
    options: [{ label: "Skip game", link: "5" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "3",
  },
  scene4: {
    text: "Spin the ball and drag each piece to complete the puzzle.",
    options: [{ label: "Skip", link: "5" }],
    img: {
      src: "",
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "3",
  },
  scene5: {
    text:
      "Mike's soccer refereeing career was cut short by a nasty injury. But that pushed him further into pursuing video games. So he went to study.",
    options: [{ label: "Next", link: "6" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "4",
  },
  scene6: {
    text: "Drag the items to build super Mike.",
    options: [{ label: "Skip", link: "9", transition: "levelUp" }],
    img: {
      defaultSrc: image24,
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(418),
      focusTop: getSceneImgValue(182),
      focusRight: getSceneImgValue(1544),
      focusBottom: getSceneImgValue(1358),
    },
    back: "5",
    boxes: [
      // { id: 1, name: "Character Design" },
      { id: 1, name: "Programming" },
      { id: 2, name: "Game design" },
      { id: 3, name: "User interaction" },
    ],
  },

  scene9: {
    text:
      "You are now into production, where the game actually gets made. The challenge is making sure each department is working in sync and is able to fit their work together.",
    options: [{ label: "Next", link: "10" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "6",
  },

  scene10: {
    text:
      "It's now getting to the final countdown. This is known in the industry as the 'crunch' when often workers' wellbeing goes out the window in favour of getting the project done.",
    options: [{ label: "Next", link: "11" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "9",
  },
  scene11: {
    text:
      "Do you tell your workers to stay on until the game is finished or tell them to go home?",
    options: [
      { label: "Crunch", link: "11-1" },
      {
        label: "Cruise",
        link: "11-2",
        Text: "",
      },
    ],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "10",
  },
  scene11dash1: {
    text:
      "Crunch - Your workers kept on going but they got sloppy until you were forced to send them home. Worker morale also decreased...",
    options: [{ label: "You should've cruised", link: "11-2" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "11",
  },
  scene11dash2: {
    text:
      "Congratulations your workers cruised to a finish thanks to a welcome morale boost and enough sleep.",
    options: [{ label: "Next", link: "12" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "11-1",
  },

  scene12: {
    text:
      "It's now time to showcase your game to the world. The best way to do this is at international game conferences, where professional gamers test the goods.",
    options: [{ label: "Next", link: "12-1" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "11-2",
  },
  scene12dash1: {
    text: "You nervously present the gameplay footage of your game.",
    options: [{ label: "Next", link: "13", transition: "confToParty" }],
    img: {
      defaultSrc: image7,
      alt: "",
      imageId: "scene12dash1",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "12",
  },

  scene13: {
    text: "CONGRATULATIONS!! The game was a success... But what next?",
    options: [{ label: "Next", link: "14" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "12-1",
  },
  scene14: {
    text:
      "Even though the game development industry had export revenues of more than $140 million in 2018, the hope is to grow that to $1 billion by 2025.",
    options: [{ label: "Next", link: "14-1" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "13",
  },
  scene14dash1: {
    text:
      "But to do that the industry says it needs more government support, in much the same way that the film industry does.",
    options: [{ label: "Next", link: "14-3" }],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "14",
  },
  scene14dash3: {
    text:
      "Congratulations you made it as a game developer but only time will tell if the industry gets the government funding it says it needs.",
    options: [
      { label: "Share the game", link: "share" },
      { label: "Continue to the story", link: "article-link" },
    ],
    img: {
      alt: "",
      imageId: "",
      focusLeft: getSceneImgValue(310),
      focusTop: getSceneImgValue(260),
      focusRight: getSceneImgValue(1645),
      focusBottom: getSceneImgValue(935),
    },
    back: "14-1",
  },
};
