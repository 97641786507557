import React, { Component } from "react";
import {
  TimelineMax,
  TweenMax,
  CSSPlugin,
  ScrollToPlugin,
  Expo,
  Elastic,
} from "gsap/all";
import ReactGA from "react-ga";

import GamePlanArticle from "./GamePlanArticle";
import Hero from "../Story/Hero";
import GameTease from "../Story/GameTease";
import NextStory from "../Article/NextStory";
import Mute from "../Video/Mute";

import "../App/Story.scss";
import "./GamePlan.scss";

import { faceSliderMikeReal, gamePlanLoop } from "../../utils/fileManager";
import { audioPaths } from "../../utils/mediaManager";

const ScrollMagic = window.ScrollMagic;

let audioList = new Array();
let loopTrack = null;

let nav = null;

class GamePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioEnabled: false,
      playPrompt: false,
      muteState: false,
    };
    this.controller = new ScrollMagic.Controller();

    this.article = null;
    this.articleWrap = null;
    this.articleTween = null;
  }

  componentDidMount() {
    // this.props.updateNav("gamePlan");
    ReactGA.pageview(window.location.pathname + window.location.search);

    nav = document.getElementById("nav-bar");
    this.onScroll();

    this.articleTween = new TimelineMax({ paused: false }).fromTo(
      this.article,
      1,
      {
        padding: "0 5%",
        // y: "40vh"
      },
      {
        padding: "0",
        // y: 0,
        ease: Expo.easeInOut,
        delay: 0.3,
      }
    );

    new ScrollMagic.Scene({
      triggerElement: `#game-plan-article`,
      triggerHook: 0.5,
      duration: "50%",
    })
      // .addIndicators()
      .setTween(this.articleTween)
      .addTo(this.controller);

    loopTrack = new Audio(audioPaths("HomePageMusicBed.mp3"));
    loopTrack.volume = 0.4;
    this.controlPlayState(loopTrack);
    loopTrack.addEventListener(
      "ended",
      function () {
        console.log("looped");
        this.currentTime = 0;
        this.play();
      },
      false
    );
    audioList.push(loopTrack);
  }

  componentWillUnmount() {
    audioList.map((audio) => {
      audio.muted = true;
      audio.pause();
    });

    if (nav) {
      nav.classList.remove("dark");
    }
  }

  unmute = () => {
    this.controlPlayState(loopTrack);
  };

  muteToggle = () => {
    console.log("mute toggle");
    this.setState({ muteState: !this.state.muteState });
    // Now mute or unmute all audio on this page
    audioList.map((audio) => {
      audio.muted = !this.state.muteState;
    });
  };

  controlPlayState = (audio) => {
    if (audio.paused) {
      var promise = audio.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
            this.setState({ audioEnabled: true, playPrompt: false });
            console.log("autoplay");
          })
          .catch((error) => {
            // Show a button on the video saying pres play to start
            this.setState({ playPrompt: true });
          });
      }
    } else {
      audio.pause();
      this.setState({ audioEnabled: false });
    }
  };

  controlLoopTrack = () => {
    this.controlPlayState(loopTrack);
  };

  onScroll = () => {
    window.addEventListener("scroll", this.scrollFunction);
  };

  scrollFunction = () => {
    let scrollPosition = window.pageYOffset;
    if (this.articleWrap) {
      const articlePosition = this.articleWrap.offsetTop;
      const articleHeight = this.articleWrap.getBoundingClientRect().height;
      const endPosition = Math.floor(articlePosition + articleHeight);

      if (nav) {
        const navHeight = nav.getBoundingClientRect().height;
        if (
          scrollPosition >= articlePosition &&
          scrollPosition <= endPosition - navHeight
        ) {
          nav.classList.add("dark");
          nav.classList.remove("light");
        } else {
          nav.classList.add("light");
          nav.classList.remove("dark");
        }
      }
    }
  };

  render() {
    return (
      <div className="game-plan story-wrap">
        <div className="mute-toggle" onClick={this.muteToggle}>
          <Mute mode={this.state.muteState ? "mute-off" : "mute-on"} />
        </div>
        <div className="hero-wrap">
          <Hero
            story="gamePlan"
            video={faceSliderMikeReal()}
            title="Game Plan"
            lineOne="New Zealand's gaming"
            lineTwo="startup with eyes"
            lineThree="on the big time"
            ref={(ref) => {
              this.hero = ref;
            }}
            hideScroll="true"
            intro="In a small Lower Hutt office, housed inside a Soviet-inspired
            television studio, lies a New Zealand gaming company that is on
            the verge of the big time."
            teaseVideo={gamePlanLoop()}
            teaseTitle="The Game Plan"
            teaseText="Help Mike develop his game-creating career"
            teaseLink="/gameplan/game"
          />
        </div>

        <div
          className="article"
          ref={(ref) => {
            this.article = ref;
          }}
          id="the-article"
        >
          <div
            className="article-wrap"
            ref={(ref) => {
              this.articleWrap = ref;
            }}
          >
            <GamePlanArticle controlLoopTrack={this.controlLoopTrack} />
            <div className="game-prompt-spacer">
              <GameTease
                story="gamePlan"
                video={gamePlanLoop()}
                title="The Game Plan"
                text="Help Mike develop his game-creating career"
                link="/gameplan/game"
                mobile={true}
              />
            </div>
          </div>
          <NextStory current="gameplan" />
        </div>
      </div>
    );
  }
}

export default GamePlan;
