import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Loader } from "resource-loader";
import ReactGA from "react-ga";

import GamePlanGameIntro from "../GamePlan/GamePlanGameIntro";
import Scene from "./Scene";

import { audioPaths } from "../../utils/mediaManager.js";

import { overlayContent } from "./overlayContent.js";

import {
  studioToHome,
  levelUp,
  confToParty,
  pad,
  stillsOne,
  stillsTwo,
} from "../../utils/gamePlanSequences";

import "./CanYouMakeIt.scss";
import "../App/Base.scss";
import "../App/Mixins.scss";
import "../App/Variables.scss";

const newAudio = (url) => {
  return new Audio(audioPaths(url));
};

let gamePlanLoop = null;
let OfficeSpinIntoSuburb = null;
let PresentationSwooshIntoParty = null;
let OfficeAmbience = null;
let PartyTimeAmbience = null;
let GovernmentAmbience = null;

let studioTransition = null;
let levelTransition = null;
let confTransition = null;

class CanYouMakeIt extends React.Component {
  constructor() {
    super();
    this.state = {
      studioToHome: null,
      levelUp: null,
      confToParty: null,
      scene2dash2Img: overlayContent.scene2dash2.img.defaultSrc,
      scene6Img: overlayContent.scene6.img.defaultSrc,
      scene12dash1Img: overlayContent.scene12dash1.img.defaultSrc,
    };

    // Load all new audio into parent audioArray
    gamePlanLoop = newAudio("gameplan/GamePlanBackgroundMusicLoop.mp3");
    OfficeSpinIntoSuburb = newAudio("gameplan/OfficeSpinIntoSuburb.mp3");
    PresentationSwooshIntoParty = newAudio(
      "gameplan/PresentationSwooshIntoParty.mp3"
    );
    OfficeAmbience = newAudio("gameplan/OfficeAmbience.mp3");
    PartyTimeAmbience = newAudio("gameplan/PartyTimeAmbience.mp3");
    GovernmentAmbience = newAudio("gameplan/OutsideCityGovernmentAmbience.mp3");
  }

  componentWillMount() {
    // Redirect if refreshing or going directly to a path
    console.log(this.props.match);
    if (this.props.match.isExact === false) {
      // This will run on all subpaths of "/gameplan/game"
      if (!window["image1"]) {
        window["image1"] = { src: "" };
        window["image2"] = { src: "" };
        window["image3"] = { src: "" };
        window["image4"] = { src: "" };
        window["image5"] = { src: "" };
        window["image6"] = { src: "" };
        window["image7"] = { src: "" };
        window["image8"] = { src: "" };
        window["image9"] = { src: "" };
        window["image11"] = { src: "" };
        window["image14"] = { src: "" };
        window["image15"] = { src: "" };
        window["image16"] = { src: "" };
        window["image20"] = { src: "" };
        window["image24"] = { src: "" };
        window["image25"] = { src: "" };
        window["image26"] = { src: "" };
      }
    }
  }

  componentWillReceiveProps() {
    if (this.props.match.isExact === false) {
      // This will run on all subpaths of "/gameplan/game"
      if (!window["image1"]) {
        // If the preloader hasn't started yet.
        this.props.history.push("/gameplan/game");
      }
    }
  }

  componentDidMount() {
    // this.props.updateNav("gamePlan");
    ReactGA.pageview(window.location.pathname + window.location.search);

    // const nav = document.getElementById("nav-bar");
    // if (nav) {
    //   nav.classList.add("dark");
    // }
    var loader = new Loader();
    loader
      .add(stillsOne())
      .add(studioToHome())
      .add(stillsTwo())
      .add(levelUp())
      .add(confToParty())
      .load((loader, resources) => {
        // this.setState({
        //   resources,
        // });
        console.log(window);
      });
    loader.onProgress.add((loader, resource) => {
      // Disable the button if progress is less than 100
      // Else enable the button
      // can add a progress bar with this if required.
      console.log("one asset loaded", loader.progress, resource);
      const newImage = new Image();
      newImage.src = resource.url;
      window[resource.name] = newImage;

      if (resource.name === "image4") {
        this.setState({ scene2dash2Img: resource.url });
      } else if (resource.name === "image24") {
        this.setState({ scene6Img: resource.url });
      } else if (resource.name === "image7") {
        this.setState({ scene12dash1Img: resource.url });
      }
    });

    this.props.updateAudioLoops("gamePlanLoop", gamePlanLoop);
    this.controlPlayState(gamePlanLoop);

    this.props.updateAudioList("OfficeSpinIntoSuburb", OfficeSpinIntoSuburb);
    this.props.updateAudioList(
      "PresentationSwooshIntoParty",
      PresentationSwooshIntoParty
    );
    this.props.updateAudioList("gamePlanLoop", gamePlanLoop);
    this.props.updateAudioList("OfficeAmbience", OfficeAmbience);
    this.props.updateAudioList("PartyTimeAmbience", PartyTimeAmbience);
    this.props.updateAudioList("GovernmentAmbience", GovernmentAmbience);
  }

  componentWillUnmount() {
    this.props.removeLoopTrack("gamePlanLoop");
    this.props.removeLoopTrack("OfficeAmbience");
    this.props.removeLoopTrack("PartyTimeAmbience");
    this.props.removeLoopTrack("GovernmentAmbience");

    this.props.removeFromAudioList("gamePlanLoop");
    this.props.removeFromAudioList("OfficeAmbience");
    this.props.removeFromAudioList("PartyTimeAmbience");
    this.props.removeFromAudioList("GovernmentAmbience");
    this.props.removeFromAudioList("OfficeSpinIntoSuburb");
    this.props.removeFromAudioList("PresentationSwooshIntoParty");
  }

  // componentDidUpdate() {
  //   console.log(this.props.match.isExact);
  // }

  controlPlayState = (audio) => {
    this.props.controlPlayState(audio);
  };

  transitionTo = (transition) => {
    // console.log(transition, this.state);
    if (transition === "studioToHome") {
      if (this.state.studioToHome !== "active") {
        this.setState({
          studioToHome: "active",
        });
        // if (transition === "levelUp") {
        //   if (this.state.levelUp !== "active") {
        //     this.setState({
        //       levelUp: "active"
        //     });
        //   }
        // }

        //document.querySelectorAll("audio")[0].pause();
        // Pause office Ambience
        if (!OfficeAmbience.paused) {
          OfficeAmbience.pause();
        }

        this.controlPlayState(OfficeSpinIntoSuburb);

        let i = 0;
        // setInterval to run 25 times a second
        studioTransition = setInterval(() => {
          // advance through resources one time per loop
          const count = pad(i, 3);
          // const newImg = this.state.resources[`sth${count}`].url;
          const newImg = window[`sth${count}`].src;
          // console.log(i, newImg);
          // update the img src with resource.url
          this.setState({
            scene2dash2Img: newImg,
          });
          if (i === 49) {
            clearInterval(studioTransition);
            this.setState({
              studioToHome: "transitionCompleted",
            });
            this.props.history.push("/gameplan/game/scene/3");
            this.setState({
              scene2dash2Img: window["sth001"].src,
            });
            return;
          }
          i++;
        }, 40);
      }

      // Also disable the button after click.
    } else if (transition === "levelUp") {
      console.log("running levelUp");
      if (this.state.levelUp !== "active") {
        this.setState({
          levelUp: "active",
        });

        let i = 0;
        // setInterval to run 25 times a second
        levelTransition = setInterval(() => {
          // advance through resources one time per loop
          const count = pad(i, 3);
          const newImg = window[`sth${count}`].src;
          // console.log(i, newImg);
          // update the img src with resource.url
          this.setState({
            scene6Img: newImg,
          });
          if (i === 98) {
            clearInterval(levelTransition);
            this.setState({
              levelUp: "transitionCompleted",
            });
            this.props.history.push("/gameplan/game/scene/10");
            this.setState({
              scene6Img: window["lu001"].src,
            });
            return;
          }
          i++;
        }, 40);
      }
    } else if (transition === "levelUp noAnimate") {
      console.log("running levelUp noAnimate");
      if (this.state.levelUp !== "active") {
        this.setState({
          levelUp: "active",
        });

        // document.querySelectorAll("audio")[0].play();

        // setInterval to run 25 times a second
        levelTransition = setInterval(() => {
          clearInterval(levelTransition);
          this.setState({
            levelUp: "transitionCompleted",
          });
          this.props.history.push("/gameplan/game/scene/10");
          this.setState({
            scene6Img: window["lu001"].src,
          });
          return;
        }, 40);
      }
    } else if (transition === "levelUp1") {
      if (this.state.levelUp !== "active") {
        this.setState({
          levelUp: "active",
        });

        this.controlPlayState(PresentationSwooshIntoParty);

        console.log("running levelup 1");
        let i = 0;
        // setInterval to run 25 times a second
        levelTransition = setInterval(() => {
          // advance through resources one time per loop
          const count = pad(i, 3);
          const newImg = window[`lu${count}`].src;
          // update the img src with resource.url
          this.setState({
            scene6Img: newImg,
          });
          if (i === 40) {
            clearInterval(levelTransition);
            this.setState({
              levelUp: "transitionCompleted",
            });
            // this.setState({
            //   scene6Img: this.state.resources["lu001"].url
            // });
            return;
          }
          i++;
        }, 40);
      }
    } else if (transition === "levelUp2") {
      if (this.state.levelUp !== "active") {
        this.setState({
          levelUp: "active",
        });

        this.controlPlayState(PresentationSwooshIntoParty);

        console.log("running levelup 2");
        let i = 40;
        // setInterval to run 25 times a second
        levelTransition = setInterval(() => {
          // advance through resources one time per loop
          const count = pad(i, 3);
          const newImg = window[`lu${count}`].src;
          // update the img src with resource.url
          this.setState({
            scene6Img: newImg,
          });
          if (i === 57) {
            clearInterval(levelTransition);
            this.setState({
              levelUp: "transitionCompleted",
            });
            return;
          }
          i++;
        }, 40);
      }
    } else if (transition === "levelUp3") {
      if (this.state.levelUp !== "active") {
        this.setState({
          levelUp: "active",
        });

        this.controlPlayState(PresentationSwooshIntoParty);

        console.log("running levelup 3");

        let i = 57;
        // setInterval to run 25 times a second
        levelTransition = setInterval(() => {
          // advance through resources one time per loop
          const count = pad(i, 3);
          const newImg = window[`lu${count}`].src;
          // update the img src with resource.url
          this.setState({
            scene6Img: newImg,
          });
          if (i === 98) {
            clearInterval(levelTransition);
            this.setState({
              levelUp: "transitionCompleted",
            });
            return;
          }
          i++;
        }, 40);
      }
    } else if (transition === "confToParty") {
      console.log("running conf");
      if (this.state.confToParty !== "active") {
        this.setState({
          confToParty: "active",
        });

        this.controlPlayState(PresentationSwooshIntoParty);
        // Play PartyTime
        // document.querySelectorAll("audio")[1].play();

        let i = 0;
        // setInterval to run 25 times a second
        confTransition = setInterval(() => {
          // advance through resources one time per loop
          const count = pad(i, 3);
          const newImg = window[`ctp${count}`].src;
          console.log(i, newImg);
          // update the img src with resource.url
          this.setState({
            scene12dash1Img: newImg,
          });
          if (i === 49) {
            clearInterval(confTransition);
            this.setState({
              confToParty: "transitionCompleted",
            });
            this.props.history.push("/gameplan/game/scene/13");
            this.setState({
              scene12dash1Img: window["ctp001"].src,
            });
            return;
          }
          i++;
        }, 40);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/gameplan/game"
            exact
            component={() => <GamePlanGameIntro />}
          />
          <Route
            path="/gameplan/game/scene/1"
            exact
            render={() => (
              <Scene
                sceneName="1"
                src={window["image1"].src}
                {...overlayContent.scene1.img}
                options={overlayContent.scene1.options}
                text={overlayContent.scene1.text}
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
                // removeLoopTrack={this.props.removeLoopTrack}
                // removeFromAudioList={this.props.updateAudioList}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/2"
            exact
            render={() => (
              <Scene
                sceneName="2"
                src={window["image2"].src}
                {...overlayContent.scene2.img}
                options={overlayContent.scene2.options}
                text={overlayContent.scene2.text}
                back={overlayContent.scene2.back}
                location={"office"}
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/2-1"
            exact
            render={() => (
              <Scene
                sceneName="2-1"
                src={window["image3"].src}
                {...overlayContent.scene2dash1.img}
                options={overlayContent.scene2dash1.options}
                text={overlayContent.scene2dash1.text}
                back={overlayContent.scene2dash1.back}
                location={"office"}
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/2-2"
            exact
            render={() => (
              <Scene
                sceneName="2-2"
                src={this.state.scene2dash2Img}
                alt={overlayContent.scene2dash2.img.alt}
                imageId={overlayContent.scene2dash2.img.imageId}
                focusLeft={overlayContent.scene2dash2.img.focusLeft}
                focusTop={overlayContent.scene2dash2.img.focusTop}
                focusRight={overlayContent.scene2dash2.img.focusRight}
                focusBottom={overlayContent.scene2dash2.img.focusBottom}
                options={overlayContent.scene2dash2.options}
                text={overlayContent.scene2dash2.text}
                back={overlayContent.scene2dash2.back}
                transitionTo={this.transitionTo}
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />

          <Route
            path="/gameplan/game/scene/3"
            exact
            render={() => (
              <Scene
                sceneName="3"
                src={window["image26"].src}
                {...overlayContent.scene3.img}
                options={overlayContent.scene3.options}
                text={overlayContent.scene3.text}
                back={overlayContent.scene3.back}
                noTransition="true"
                OfficeAmbience={OfficeAmbience}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/3-1"
            exact
            render={() => (
              <Scene
                sceneName="3-1"
                src={window["image25"].src}
                options={overlayContent.scene3dash1.options}
                text={overlayContent.scene3dash1.text}
                back={overlayContent.scene3dash1.back}
                three="football"
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/4"
            exact
            render={() => (
              <Scene
                sceneName="4"
                options={overlayContent.scene4.options}
                text={overlayContent.scene4.text}
                back={overlayContent.scene4.back}
                three="puzzle"
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/5"
            exact
            render={() => (
              <Scene
                sceneName="5"
                src={window["image5"].src}
                {...overlayContent.scene5.img}
                options={overlayContent.scene5.options}
                text={overlayContent.scene5.text}
                back={overlayContent.scene5.back}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/6"
            exact
            render={() => (
              <Scene
                sceneName="6"
                src={this.state.scene6Img}
                alt={overlayContent.scene6.img.alt}
                imageId={overlayContent.scene6.img.imageId}
                focusLeft={overlayContent.scene6.img.focusLeft}
                focusTop={overlayContent.scene6.img.focusTop}
                focusRight={overlayContent.scene6.img.focusRight}
                focusBottom={overlayContent.scene6.img.focusBottom}
                options={overlayContent.scene6.options}
                text={overlayContent.scene6.text}
                back={overlayContent.scene6.back}
                transitionTo={this.transitionTo}
                boxes={overlayContent.scene6.boxes}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/9"
            exact
            render={() => (
              <Scene
                sceneName="9"
                src={window["image8"].src}
                {...overlayContent.scene9.img}
                options={overlayContent.scene9.options}
                text={overlayContent.scene9.text}
                back={overlayContent.scene9.back}
                noTransition="true"
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />

          <Route
            path="/gameplan/game/scene/10"
            exact
            render={() => (
              <Scene
                sceneName="10"
                src={window["image11"].src}
                {...overlayContent.scene10.img}
                options={overlayContent.scene10.options}
                text={overlayContent.scene10.text}
                back={overlayContent.scene10.back}
                noTransition="true"
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/11"
            exact
            render={() => (
              <Scene
                sceneName="11"
                src={window["image2"].src}
                {...overlayContent.scene11.img}
                options={overlayContent.scene11.options}
                text={overlayContent.scene11.text}
                back={overlayContent.scene11.back}
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/11-1"
            exact
            render={() => (
              <Scene
                sceneName="11-1"
                src={window["image14"].src}
                {...overlayContent.scene11dash1.img}
                options={overlayContent.scene11dash1.options}
                text={overlayContent.scene11dash1.text}
                back={overlayContent.scene11dash1.back}
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/11-2"
            exact
            render={() => (
              <Scene
                sceneName="11-2"
                src={window["image20"].src}
                {...overlayContent.scene11dash2.img}
                options={overlayContent.scene11dash2.options}
                text={overlayContent.scene11dash2.text}
                back={overlayContent.scene11dash2.back}
                OfficeAmbience={OfficeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/12"
            exact
            render={() => (
              <Scene
                sceneName="12"
                src={window["image6"].src}
                {...overlayContent.scene12.img}
                options={overlayContent.scene12.options}
                text={overlayContent.scene12.text}
                back={overlayContent.scene12.back}
                OfficeAmbience={OfficeAmbience}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/12-1"
            exact
            render={() => (
              <Scene
                sceneName="12-1"
                src={this.state.scene12dash1Img}
                alt={overlayContent.scene12dash1.img.alt}
                imageId={overlayContent.scene12dash1.img.imageId}
                focusLeft={overlayContent.scene12dash1.img.focusLeft}
                focusTop={overlayContent.scene12dash1.img.focusTop}
                focusRight={overlayContent.scene12dash1.img.focusRight}
                focusBottom={overlayContent.scene12dash1.img.focusBottom}
                options={overlayContent.scene12dash1.options}
                text={overlayContent.scene12dash1.text}
                back={overlayContent.scene12dash1.back}
                transitionTo={this.transitionTo}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/13"
            exact
            render={() => (
              <Scene
                sceneName="13"
                src={window["image9"].src}
                {...overlayContent.scene13.img}
                options={overlayContent.scene13.options}
                text={overlayContent.scene13.text}
                back={overlayContent.scene13.back}
                noTransition="true"
                PartyTimeAmbience={PartyTimeAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/14"
            exact
            render={() => (
              <Scene
                sceneName="14"
                src={window["image15"].src}
                {...overlayContent.scene14.img}
                options={overlayContent.scene14.options}
                text={overlayContent.scene14.text}
                back={overlayContent.scene14.back}
                PartyTimeAmbience={PartyTimeAmbience}
                GovernmentAmbience={GovernmentAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/14-1"
            exact
            render={() => (
              <Scene
                sceneName="14-1"
                src={window["image16"].src}
                {...overlayContent.scene14dash1.img}
                options={overlayContent.scene14dash1.options}
                text={overlayContent.scene14dash1.text}
                back={overlayContent.scene14dash1.back}
                GovernmentAmbience={GovernmentAmbience}
                startLoop={this.controlPlayState}
              />
            )}
          />
          <Route
            path="/gameplan/game/scene/14-3"
            exact
            render={() => (
              <Scene
                sceneName="14-3"
                src={window["image1"].src}
                {...overlayContent.scene14dash3.img}
                options={overlayContent.scene14dash3.options}
                text={overlayContent.scene14dash3.text}
                back={overlayContent.scene14dash3.back}
                GovernmentAmbience={GovernmentAmbience}
              />
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(CanYouMakeIt);
