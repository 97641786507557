export function createImageRenderer(container, spinner) {
  let prevImage = null;
  let isDestroyed = false;

  container.appendChild(spinner.getNode());

  return {
    show,
    destroy
  };

  function show(image) {
    if (isDestroyed) {
      return;
    }

    if (prevImage !== null) {
      container.removeChild(prevImage);
    }
    container.insertBefore(image, spinner.getNode());
    prevImage = image;
  }

  function destroy() {
    if (isDestroyed) {
      return;
    }
    isDestroyed = true;

    if (prevImage !== null) {
      container.removeChild(prevImage);
      prevImage = null;
    }

    container.removeChild(spinner.getNode());
  }
}
