import React, { Component } from "react";

import jointFundLogo from "../../assets/joint-fund-logo.png";

class JointFundLogo extends Component {
  render() {
    return (
      <div className="joint-fund-logo">
        <img src={jointFundLogo} alt="" />
      </div>
    );
  }
}

export default JointFundLogo;
