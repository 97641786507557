import React, { Component } from "react";

class Twitter extends Component {
  render() {
    return (
      <svg
        width="38px"
        height="31px"
        viewBox="0 0 38 31"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="exports"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(-893.000000, -342.000000)">
            <path
              d="M904.949219,373 C909.501302,373 913.533855,371.832532 917.046875,369.497596 C920.26302,367.411058 922.761719,364.604166 924.542969,361.076923 C926.274739,357.748397 927.140625,354.295673 927.140625,350.71875 C927.140625,350.271635 927.115886,349.948718 927.066406,349.75 C928.600261,348.607372 929.911458,347.241186 931,345.651442 C929.515625,346.297276 928.03125,346.719551 926.546875,346.918269 C928.229167,345.875 929.367188,344.434295 929.960938,342.596154 C928.427083,343.490385 926.769531,344.111378 924.988281,344.459135 C924.246094,343.713942 923.392578,343.117788 922.427734,342.670673 C921.462891,342.223558 920.411458,342 919.273438,342 C917.88802,342 916.601562,342.347757 915.414062,343.043269 C914.226562,343.738782 913.286458,344.695113 912.59375,345.91226 C911.901042,347.129407 911.554688,348.433493 911.554688,349.824519 C911.554688,350.420673 911.604167,351.016827 911.703125,351.612981 C908.536458,351.463942 905.555339,350.66907 902.759766,349.228365 C899.964192,347.787661 897.601562,345.850161 895.671875,343.415865 C894.929688,344.657853 894.558594,345.961939 894.558594,347.328125 C894.558594,348.694311 894.880208,349.948718 895.523438,351.091346 C896.166667,352.233974 897.007812,353.177885 898.046875,353.923077 C896.809895,353.873397 895.647136,353.525641 894.558594,352.879808 L894.558594,353.028846 C894.558594,354.866988 895.152344,356.506411 896.339844,357.947115 C897.527344,359.38782 899.011719,360.306891 900.792969,360.704327 C900.100261,360.853365 899.407552,360.927885 898.714844,360.927885 C898.269531,360.927885 897.79948,360.903045 897.304688,360.853365 C897.79948,362.39343 898.702474,363.672676 900.013672,364.691106 C901.32487,365.709536 902.846355,366.21875 904.578125,366.21875 C901.708333,368.454327 898.467448,369.572115 894.855469,369.572115 C894.162761,369.572115 893.54427,369.547276 893,369.497596 C896.61198,371.832532 900.595052,373 904.949219,373 Z"
              id="icon_twitter"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
}

export default Twitter;
