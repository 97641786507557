import * as imageCache from './image-cache';
import * as imageLoaderRegistry from './image-loader-registry';

const DIRECTION_FORWARD = 1;
const DIRECTION_BACKWARD = 2;

export function createBackgroundLoader(images) {
  let isDestroyed = false;
  let activeIndex = NaN;

  let loaders = [];

  let nextFrameIndexInForwardDirection = NaN;
  let nextFrameIndexInBackwardDirection = NaN;

  return {
    setActiveFrameIndex,
    destroy
  };

  function setActiveFrameIndex(frameIndex) {
    frameIndex = Math.max(0, Math.min(images.length - 1, frameIndex));
    if (isDestroyed || activeIndex === frameIndex) {
      return;
    }

    destroyLoaders();

    activeIndex = frameIndex;
    nextFrameIndexInForwardDirection = activeIndex + 1;
    nextFrameIndexInBackwardDirection = activeIndex - 1;

    loaders = [
      createLoader(DIRECTION_FORWARD),
      createLoader(DIRECTION_BACKWARD)
    ];
  }

  function destroy() {
    if (isDestroyed) {
      return;
    }
    isDestroyed = true;
    destroyLoaders();
  }

  function destroyLoaders() {
    loaders.forEach(loader => loader.destroy());
    loaders = [];
  }

  function createLoader(defaultDirection) {
    let isDestroyed = false;
    let state = null;

    switch (defaultDirection) {
      case DIRECTION_FORWARD:
        stateForwardDirection();
        break;
      case DIRECTION_BACKWARD:
        stateBackwardDirection();
        break;
    }

    return {
      destroy
    };

    function destroy() {
      if (isDestroyed) {
        return;
      }
      isDestroyed = true;

      if (state !== null) {
        state.destroy();
        state = null;
      }
    }

    function stateForwardDirection() {
      let isActive = true;

      state = { destroy };

      step();

      function destroy() {
        isActive = false;
      }

      function step() {
        if (nextFrameIndexInForwardDirection >= images.length) {
          switch (defaultDirection) {
            case DIRECTION_FORWARD:
              stateBackwardDirection();
              return;
            case DIRECTION_BACKWARD:
              return;
          }
        }

        const imageUrl = images[nextFrameIndexInForwardDirection];
        nextFrameIndexInForwardDirection++;

        if (imageCache.hasImage(imageUrl)) {
          step();
          return;
        }

        let unsubscribe;

        const onLoaderResult = () => {
          unsubscribe.forEach(fn => fn());

          if (!isActive) {
            return;
          }

          step();
        };

        const loader = imageLoaderRegistry.getLoader(imageUrl);

        unsubscribe = [
          loader.onLoad(onLoaderResult),
          loader.onError(onLoaderResult)
        ];
      }
    }

    function stateBackwardDirection() {
      let isActive = true;

      state = { destroy };

      step();

      function destroy() {
        isActive = false;
      }

      function step() {
        if (nextFrameIndexInBackwardDirection < 0) {
          switch (defaultDirection) {
            case DIRECTION_FORWARD:
              return;
            case DIRECTION_BACKWARD:
              stateForwardDirection();
              return;
          }
        }

        const imageUrl = images[nextFrameIndexInBackwardDirection];
        nextFrameIndexInBackwardDirection--;

        if (imageCache.hasImage(imageUrl)) {
          step();
          return;
        }

        let unsubscribe;

        const onLoaderResult = () => {
          unsubscribe.forEach(fn => fn());

          if (!isActive) {
            return;
          }

          step();
        };

        const loader = imageLoaderRegistry.getLoader(imageUrl);

        unsubscribe = [
          loader.onLoad(onLoaderResult),
          loader.onError(onLoaderResult)
        ];
      }
    }
  }
}
