import React, { Component } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import Facebook from "../App/Facebook.jsx";
import Twitter from "../App/Twitter.jsx";

class Share extends Component {
  constructor() {
    super();
    this.state = {
      widgets: false,
    };
  }

  btnsToggle = () => {
    console.log(this.state.widgets);
    this.setState({
      widgets: !this.state.widgets,
    });
  };
  render() {
    return (
      <div id="share-wrap">
        <div
          id="share"
          onClick={this.btnsToggle}
          className={this.state.widgets ? "open" : ""}
        >
          <svg
            width="34px"
            height="34px"
            viewBox="0 0 34 34"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="exports"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="share-icon"
                transform="translate(-345.000000, -644.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g
                  id="share_toggle"
                  transform="translate(345.000000, 644.000000)"
                >
                  <path
                    d="M11.9923998,18.2741997 L12.0399998,18.2167997 C12.7778675,17.4341078 12.7918728,16.2162533 12.0721998,15.4167997 L12.0483998,15.3999997 C11.4702213,14.7626042 10.5616778,14.542467 9.75587202,14.8445275 C8.95006625,15.146588 8.41010121,15.9097062 8.39337822,16.7701037 C8.37665524,17.6305012 8.88655921,18.414025 9.68001892,18.7471694 C10.4734786,19.0803137 11.38989,18.895647 11.9923998,18.2811997 L11.9923998,18.2741997 Z"
                    id="Path"
                  ></path>
                  <circle
                    id="Oval"
                    cx="20.9999996"
                    cy="11.5499998"
                    r="2.09999996"
                  ></circle>
                  <circle
                    id="Oval"
                    cx="20.9999996"
                    cy="22.0499996"
                    r="2.09999996"
                  ></circle>
                  <path d="M16.7999997,0 C7.52161607,0 0,7.52161607 0,16.7999997 C0,26.0783834 7.52161607,33.5999994 16.7999997,33.5999994 C26.0783834,33.5999994 33.5999994,26.0783834 33.5999994,16.7999997 C33.5899686,7.52577414 26.0742253,0.0100307865 16.7999997,0 Z M14.6999997,16.7999997 C14.6999321,17.3785259 14.579301,17.9506891 14.3457998,18.4799997 L17.3473997,19.9821997 C18.3891488,18.1469057 20.630993,17.3696168 22.5852427,18.1661425 C24.5394924,18.9626682 25.5992307,21.0856373 25.0612146,23.1262459 C24.5231984,25.1668546 22.5545154,26.491366 20.4615851,26.2208334 C18.3686548,25.9503008 16.8014632,24.1687415 16.7999997,22.0583996 L13.0073998,20.1599997 C11.2746695,21.4595474 8.83786017,21.2283399 7.3804543,19.6261082 C5.92304843,18.0238764 5.92304843,15.576123 7.3804543,13.9738913 C8.83786017,12.3716595 11.2746695,12.1404521 13.0073998,13.4399998 L16.7999997,11.5415998 C16.798571,9.42480024 18.3686939,7.63591041 20.4677969,7.3627643 C22.5669,7.08961819 24.5425431,8.41711469 25.0828869,10.4637878 C25.6232307,12.510461 24.5603264,14.6401668 22.599949,15.4387674 C20.6395716,16.237368 18.3910428,15.4566435 17.3473997,13.6149998 L14.3457998,15.1199997 C14.579301,15.6493103 14.6999321,16.2214735 14.6999997,16.7999997 Z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        {/* <div id="widgets" className={this.state.widgets ? "open" : ""}>
          <FacebookShareButton
            url={`https://gamechange.rnz.co.nz`}
            quote={`Game Change: Play three interactive stories exploring NZ's gaming industry`}
          >
            <Facebook />
          </FacebookShareButton>
          <TwitterShareButton
            url={`https://gamechange.rnz.co.nz/`}
            title={`Game Change: Play three interactive stories exploring NZ's gaming industry`}
          >
            <Twitter />
          </TwitterShareButton>
        </div> */}
        <div id="share-overlay" className={this.state.widgets ? "open" : ""}>
          <div className="modal">
            <div className="top">
              <h2>Share</h2>
              <div className="close" onClick={this.btnsToggle}>
                Close
              </div>
            </div>

            <div className="widgets">
              <FacebookShareButton
                url={`https://gamechange.rnz.co.nz`}
                quote={`Game Change: Play three interactive stories exploring NZ's gaming industry`}
              >
                <Facebook />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://gamechange.rnz.co.nz/`}
                title={`Game Change: Play three interactive stories exploring NZ's gaming industry`}
              >
                <Twitter />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Share;
